import React, {useEffect, useState} from "react";
import {Box, IconButton} from "@mui/material";
import {useParams} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars, faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import Typography from "@mui/material/Typography";
import theme from "../../../theme";
import ClinicFilterPopUp from "../../common/ClinicSelection/ClinicFilterPopUp";
import {dentistProfileData, getLocalStorageItem} from "../../../utils/helper";
import {getClinicRequest} from "../../../redux/actions/common/clinicSelection/clinicActions";
import {RootState} from '../../../redux/reducers/rootReducer';
import ChangeClinicDropDown from "../../common/ClinicSelection/ChangeClinicDropDown";
import AccessCodeQR from "../AccessCodeQR";
import {
    patientAppointmentDetailsRequest
} from "../../../redux/actions/patient/PatientAppointmentAction/PatientAppointmentAction";

const useStyle = makeStyles((theme: any) => ({
    appBar: {
        background: `${theme.palette.common.white} !important`,
        color: theme.palette.common.black,
        boxShadow: "none !important",
        padding: "7px 0",
        paddingRight: "0px !important",
        zIndex: 1
    },
    notification: {
        minWidth: "unset !important",
        height: "40px",
        width: "40px",
        margin: '0 0 0 22px',
    },
    arrowStyle: {
        height: ' 24px',
        width: ' 24px',
        background: theme.palette.secondary.main,
        color: theme.palette.common.white,
        position: 'absolute',
        bottom: -11,
        left: -13,
        zIndex: '2147483647 !important',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
    },
    headerTitle: {
        [theme.breakpoints.down(1200)]: {
            fontSize: '22px'
        },
        [theme.breakpoints.down(800)]: {
            fontSize: '20px'
        },
        [theme.breakpoints.down(714)]: {
            fontSize: '16px'
        }
    }
}));

const DentistHeaderNew = (props: any) => {
    const dispatch = useDispatch();
    const classes = useStyle();
    const takeFromUrl = useParams();
    const clinicData = useSelector((state: any) => state.Clinic?.data?.data);
    const [clinicPopup, setClinicPopup] = useState(clinicData?.length === 1? false:!getLocalStorageItem('clinic'));
    const sidebarWidth = useSelector((state: RootState) => state.sidebar?.width)
    const drawerWidth = 250;
    const staffAccountData = JSON.parse(getLocalStorageItem('dentistUserData') || '{}')

    useEffect(() => {
        if (!clinicData) {
            dispatch(getClinicRequest({
                staff_id: staffAccountData?.id,
            }));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(
            patientAppointmentDetailsRequest({
                appointment_id: takeFromUrl?.appointmentID,
            })
        );
    }, [dispatch,takeFromUrl]);


    // get instant notification count using pusher
    // useEffect(() => {
    //     if (localStorage.getItem('clinic')) {
    //         let channel = pusher.subscribe('staff_notification_channel')
    //         channel.bind(selectedClinic || '', (data: any) => {
    //             if (data?.notification_triggered) {
    //                 dispatch(getNotificationCountTypeRequestAction({
    //                     clinicID: selectedClinic
    //                 }))
    //             }
    //         })
    //     }
    //     return () => {
    //         // Unsubscribe from the channel
    //         pusher.unsubscribe('staff_notification_channel');
    //         // Disconnect from Pusher
    //         pusher.disconnect();
    //     };
    // }, [])



    // const gotoNotification = () => {
    //     history({pathname: `/dentist/notification`})
    // }

    return (
        <>
            {clinicPopup && <ClinicFilterPopUp open={clinicPopup} setOpen={setClinicPopup} />}
            {/* FUTURE USE: If you need mobile verification popUp */}
            {/* {openMobileVerification &&
                <MobileVerificationPopUp open={openMobileVerification} setOpen={setOpenMobileVerification}/>} */}
            {/* for Desktop */}
            <IconButton sx={{
                display: {sm: "none", md: "inline-flex"},
            }} className={classes.arrowStyle}
                        onClick={!(sidebarWidth === drawerWidth) ? props.handleDrawerOpen : props.handleDrawerClose}>
                {!(sidebarWidth === drawerWidth) ? <FontAwesomeIcon icon={faChevronRight} className={`f-14 f-w-900`}
                                                                    onClick={props.handleDrawerOpen}/> :
                    <FontAwesomeIcon icon={faChevronLeft} className={`f-14 f-w-900`}
                                     onClick={props.handleDrawerClose}/>}
            </IconButton>
            {/* for tablet */}
            {/* <IconButton sx={{
        display: { sm: "inline-flex", md: "none" },
      }} className={classes.arrowStyle} onClick={() => props.setMobileOpen(!props.mobileOpen)}>
        {!(props.mobileOpen) ? <FontAwesomeIcon icon={faChevronRight} className={`f-14 f-w-900`} /> :
          <FontAwesomeIcon icon={faChevronLeft} className={`f-14 f-w-900`} />}
      </IconButton> */}

            <Box
                sx={{
                    width: "100% !important",
                    background: `${theme.palette.common.white} !important`,
                    padding: "0px !important",
                }}
            >

                <AppBar position="static" className={classes.appBar}>
                    <Box
                        sx={{
                            display: "flex",
                            textAlign: "center",
                            color: "black",
                            justifyContent: "space-between",
                            flexDirection: "row",
                        }}
                    >
                        <Box className='d-flex'>
                            <Box sx={{
                                width: "64px", display: {
                                    md: 'none',
                                    sm: 'flex',
                                    xs: 'flex'
                                }
                            }}>
                                <IconButton
                                    disableFocusRipple
                                    disableRipple
                                    onClick={() => props.setMobileOpen(!props.mobileOpen)}
                                >
                                    <FontAwesomeIcon icon={faBars} className={`f-24 f-w-900`}/>
                                </IconButton>
                            </Box>
                            <Typography color={theme.palette.common.black50}
                                        fontSize={sidebarWidth === drawerWidth ? '22px' : '26px'}
                                        textAlign={'left'}
                                        lineHeight={'24px'}
                                        textTransform={'capitalize'}
                                        className={`fw-medium ${classes.headerTitle}`}
                                        display={'flex'} alignItems={'center'}>
                                {props?.headerText === 'Dashboard' ? `${dentistProfileData ? `${dentistProfileData?.first_name}'s Appointment Dashboard` : 'Appointment Dashboard'}` : props?.headerText}                            </Typography>
                        </Box>
                        <Box
                            mr={0}
                            marginRight={0}
                            sx={{marginRight: "0px !important"}}
                            display={"flex"}
                            alignItems={"center"}
                            justifySelf={"end"}
                        >
                            <Box className='pr-5'>{getLocalStorageItem("clinicInfo") &&
                                <AccessCodeQR/>}</Box>
                            <ChangeClinicDropDown/>
                            {/* <Button color={"primary"} className={`fw-regular f-w-400 f-14 ${classes.notification}`}
                                    sx={{position: 'relative'}}
                                    onClick={() => gotoNotification()}>
                                {notificationCount > 0 &&
                                    <span style={{
                                        position: 'absolute', top: '-10px', left: '20px',
                                        fontSize: '14px', borderRadius: '50%', width: '25px', zIndex: '1',
                                        backgroundColor: theme.palette.error.main, color: theme.palette.common.white,
                                        display: 'flex', justifyContent: 'center', alignItems: 'center'
                                    }}>
                    {notificationCount}
                  </span>
                                }
                                <Badge color="secondary">
                                    <FontAwesomeIcon icon={faBell} className={`f-22`}/>
                                </Badge>
                            </Button> */}
                        </Box>
                    </Box>
                </AppBar>
            </Box>
        </>
    );
};
export default DentistHeaderNew;
