import {Button, Typography} from '@mui/material';
import {Box} from '@mui/system';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useParams} from 'react-router-dom';
import {
    putOdontogramRequestAction,
    syncOdontogramPmsDataRequestAction
} from '../../../../../../redux/actions/dentist/clinicalExam/exam/examFindingsTypesAction';
import theme from '../../../../../../theme';
import {completeCheckout, viewExam} from '../../../../../../utils/constant/clinicalExamConstant';
import { getRiskFilterScoreRequestAction } from '../../../../../../redux/actions/dentist/clinicalExam/clinicalExamFilterAction';


const OdontogramSync = () => {
    const {palette} = theme
    const takeFromUrl = useParams();
    const dispatch = useDispatch()
    const isWellnessProfile = window.location.pathname?.includes(`/dentist/wellness-profile/`)
    const {appointmentsReducer: {appointmentDetails},} = useSelector((state: any) => state) as any;
    const cameOdontogramFromTreatment = useSelector((state: any) => state?.odontogram?.cameOdontogramFromTreatment) as any;

    const disabledField = useMemo(() => {
        return ([completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status) || isWellnessProfile)
    }, [appointmentDetails, isWellnessProfile]);

    const syncPms = () => {
        dispatch(
            putOdontogramRequestAction({
                appointment_id: takeFromUrl?.appointmentId,
                submit: false,
                treatment_planning: cameOdontogramFromTreatment,
                callback: () => dispatch(syncOdontogramPmsDataRequestAction({
                    appointment_id: takeFromUrl?.appointmentId,
                    patientId: takeFromUrl?.patientId,
                    callback: () => {
                        dispatch(getRiskFilterScoreRequestAction({
                            appointment_id:  takeFromUrl?.appointmentId,
                        }));
                    }
                }))
            })
        );        
    }
    return (
        <Box className='d-flex justify-content-between align-center w-100' pl={15} mt={1} minWidth={854}>
            <Box/>
            <Box className='d-flex ' py={1.5}>
                <Box className='d-flex'>
                    <Typography className='f-14 f-w-400 fw-regular'
                                sx={{color: theme.palette.v2.primary.main, textAlign: 'center'}}>
                        Chart the following findings in office PMS. Once completed, Hit SYNC in OraQ.
                        <br/>
                        <Typography className='f-14 f-w-400 fw-regular'
                                    sx={{color: theme.palette.common.black, textAlign: 'center'}}>
                            Findings: Missing, Watch, Crown, Implant, Decay, RCT, Filling</Typography>
                    </Typography>
                </Box>
            </Box>
            <Button
                disabled={disabledField}
                variant='outlined'
                className='f-14 f-w-400 fw-regular'
                sx={{
                    borderColor: palette.v2.primary.main,
                    color: palette.v2.primary.main,
                    marginRight: '10px'
                }}
                onClick={syncPms}
            >
                Sync
            </Button>
        </Box>
    );
};

export default OdontogramSync;
