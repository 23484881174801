import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Box, Typography} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import {makeStyles} from "@mui/styles";
import {
    getOdontogramOtherFindingRequestAction
} from "../../../../../../redux/actions/dentist/clinicalExam/exam/examFindingsTypesAction";
import {procedureColors} from "../../../../../../utils/procedureHelper";
import {RootState} from "../../../../../../redux/reducers/rootReducer";
import theme from "../../../../../../theme";
import {stringToArray, toothNumberPreferences} from "../../../../../../utils/helper";
import {getShortSurface} from "../../../../../../utils/constant/clinicalExamConstant";
import NoDataFound from "../../../../../common/NoDataFound";

const useStyles = makeStyles((theme: any) => ({
    toothBox: {
        width: '26px',
        height: '24px',
        borderRadius: '12px',
        fontSize: '12px',
    },
    chartingTr: {
        minHeight: '40px',
        height: '40px',
        borderBottom: `1px solid ${theme.palette.common.grey}`
    },
    chartingTd: {
        paddingTop: 0,
        paddingBottom: 0,
        border: "none !important",
        minHeight: '40px',
        color: theme.palette.common.black,
        textAlign: 'left',
    },
    th: {
        padding: "17px 16px",
        fontSize: "13px",
        lineHeight: "150%",
        color: theme.palette.common.black50,
        borderBottom: "none",
        overflowX: "auto",
        whiteSpace: "nowrap",
    },
    tr: {
        borderRadius: "10px !important",
        "& td:first-child": {
            borderRadius: "10px 0 0 10px !important",
        },
        "& td:last-child": {
            borderRadius: "0 10px 10px 0 !important",
        },
    },
    td: {
        borderBottom: "none",
        height: `40px !important`,
    },
}));


const OtherFindings = () => {
    const dispatch = useDispatch();
    const takeFromUrl = useParams()
    const {palette} = theme
    const classes = useStyles();
    const { examFindingReducer: {updatedOdontogramOtherFindingData} } = useSelector((state: RootState) => state);

    useEffect(() => {
        dispatch(getOdontogramOtherFindingRequestAction({
            patient_id: takeFromUrl?.patientId || '',
        }))
    }, [dispatch,takeFromUrl]);

    const columnData = [
        {heading: 'Title'},
        {heading: 'Tooth'},
        {heading: 'Surface'},
        {heading: 'Subtype'},
        {heading: 'Procedure Code'},
        {heading: 'Description'},
    ]
    return (
        <TableContainer component={Paper} sx={{overflowY: 'hidden !important'}}>
            <Table aria-label="simple table" sx={{background: palette.common.white}}>
                <TableHead>
                    <TableRow className={classes.chartingTr}>
                        {columnData?.map((column: any, index: number) =>
                            <TableCell classes={{root: classes.th}} key={index}
                                       className={`f-w-600 fw-medium ${classes.chartingTd}`} align="center"
                                       sx={{color: palette.common.black50}}>{column?.heading}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {updatedOdontogramOtherFindingData?.map((row: any, index: number) => (
                        <TableRow key={index} sx={{
                            '&:last-child td, &:last-child th': {border: 0},
                            borderBottom: `1px solid ${palette.common.grey}`
                        }}>
                            <TableCell classes={{root: classes.td}} className={classes.chartingTd}
                                       sx={{lineHeight: '3rem !important', textTransform: 'capitalize'}} component="th"
                                       scope="row">
                                <Typography className='f-14 fw-regular labelColor'>
                                    {row?.finding?.replace(/_/g, " ") || '-'}
                                </Typography>
                            </TableCell>
                            <TableCell classes={{root: classes.td}} className={classes.chartingTd} align="left">
                                <Box sx={{
                                    backgroundColor: procedureColors[row?.procedure_status] ? procedureColors[row?.procedure_status]?.bgColor : '',
                                    color: theme.palette.common.black50
                                }}
                                     className={classes.toothBox + ` d-flex-all-center fw-regular`}>
                                    {toothNumberPreferences(row?.tooth_number?.toString())}
                                </Box>
                            </TableCell>
                            <TableCell classes={{root: classes.td}} className={`fw-regula ${classes.chartingTd}`}  align="left">
                                <Box
                                    justifyContent={"start"}
                                    display={'flex'}>
                                    {(row?.tooth_surface && row?.tooth_surface?.length) ? (typeof row?.tooth_surface === 'string' ? stringToArray(row?.tooth_surface) : row?.tooth_surface)?.map((surface: string, ind: number) =>
                                        <Box className={`fw-regular`} textAlign={'left'}
                                             key={ind} sx={{color: theme.palette.common.black50}}>
                                            {getShortSurface(surface)}{stringToArray(row?.tooth_surface)?.length > ind + 1 ? `, ` : ''}
                                        </Box>) : '-'}
                                </Box>
                            </TableCell>
                            <TableCell classes={{root: classes.td}} className={`fw-regular ${classes.chartingTd}`}   align="center">
                                <Box className={'fw-regular'} sx={{  color: theme.palette.common.black50,  textTransform: 'capitalize'}}>
                                    {row?.subtype || '-'}
                                </Box>
                            </TableCell>
                            <TableCell classes={{root: classes.td}} className={`fw-regular ${classes.chartingTd}`} align="center">
                                <Box className={'fw-regular'} sx={{color: theme.palette.common.black50}}>
                                    {row?.procedure_code || '-'}
                                </Box>
                            </TableCell>
                            <TableCell classes={{root: classes.td}} className={`fw-regular ${classes.chartingTd}`} align="center">
                                <Box className={'fw-regular'} sx={{color: theme.palette.common.black50}}>
                                    {row?.description || '-'}
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {(updatedOdontogramOtherFindingData === undefined ||updatedOdontogramOtherFindingData === null || updatedOdontogramOtherFindingData?.length < 1) ?
                <Box sx={{backgroundColor: palette.common.white}} width={'100%'} py={2} className={'d-flex-all-center'}>
                    <NoDataFound errorMessage={"finding"}/>
                </Box>
                : null
            }
        </TableContainer>
    )
}


export default OtherFindings;