export const MEDICALCONDITIONS = 'Medical Conditions'
export const ALLERGIES = 'Allergies'
export const IMAGE = 'image'
export const XRAY = 'xray'
export const PERIODONTAL_RISK='periodontalRisk'
export const MISSING_TOOTH='missing_tooth'
export const RESTRO_DEFECT='restro_defect'
export const GROUP='group'
export const EXISTING_TREATMENT='existing_treatment'
export const TOOTH_NUMBER='tooth_number'
export const EXTENT='extent'
export const MULTI_SELECT='multi-select'
export const SELECT='select'
export const PROCEDURE_STATUS='procedure_status'