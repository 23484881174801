import React, {useCallback, useMemo} from 'react';
import Typography from "@mui/material/Typography";
import {Box, Divider} from "@mui/material";
import ExamGroup from "../../ExamGroup";
import ChartingForm from "../../Odontogram/ChartingForm";
import CollapsibleBoxWithoutBorder from "../../../../../common/UIComponents/CollapsibleBoxWithoutBorder";
import {examConstantInterface, examGroupsData} from "../../../../../../utils/constant/examConstant";
import OtherFindings from "../../Odontogram/OtherFindings";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../redux/reducers/rootReducer";

type Props = {
    getData: any
}
const OdontogramForm = React.memo((props: Props) => {
    const {getData} = props;
    const { appointmentsReducer: {appointmentDetails} } = useSelector((state: RootState) => state) as any

    const examGroupWiseFilteredDataForOdontogram = useCallback((key: string) => {
        return getData
            ?.filter((f: examConstantInterface) => f?.examGroup === key)
            ?.filter((ff: examConstantInterface) => ff?.displayOdontogramForm);
    }, [getData]);

    const hasFindingDataForOdontogram = useMemo(() => {
        return examGroupsData?.filter((res: any) => examGroupWiseFilteredDataForOdontogram(res?.key)?.length > 0);
    }, [examGroupWiseFilteredDataForOdontogram]);

    return (
        <Box>
            {hasFindingDataForOdontogram?.length > 0 ? (
                hasFindingDataForOdontogram?.map((res: examConstantInterface, i: number) => (
                    <ExamGroup key={i} meta={res} data={examGroupWiseFilteredDataForOdontogram(res?.key || '')}/>
                ))
            ) : (
                <Typography className="fw-regular d-flex-all-center f-18" mt="20px" mb={2}>
                    No findings found...
                </Typography>
            )}
            <Divider/>
            <CollapsibleBoxWithoutBorder title="Charted Findings">
                <ChartingForm/>
            </CollapsibleBoxWithoutBorder>
            <Divider/>
            {(appointmentDetails?.pms_appointment_no !== null || appointmentDetails?.patient?.pms_id !== null) && (
                    <CollapsibleBoxWithoutBorder title="Other Findings">
                          <OtherFindings />
                     </CollapsibleBoxWithoutBorder>
              )}
        </Box>
    )
})

export default OdontogramForm