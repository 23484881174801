import React from 'react';
import {AppointmentLayout} from '../components/common/layouts/AppointmentLayout';
import {MainLayout} from '../components/common/layouts/MainLayout';

const PatientWellnessProfile = React.lazy(() => import( '../pages/patient/WellnessProfile'))
const CreatePasswordPatient = React.lazy(() => import( '../pages/patient/Auth/CreatePassword'))
const SendOtpScreen = React.lazy(() => import( '../pages/patient/VerificationsWithoutAccount/EmailVerification/SendOtpScreen'))
const VerifyOtpScreen = React.lazy(() => import( '../pages/patient/VerificationsWithoutAccount/EmailVerification/VerifyOtpScreen'));
const AccessCodeScreen = React.lazy(() => import( '../pages/patient/VerificationsWithoutAccount/QrCodeVerification/AccessCodeScreen'));
const PatientRegister = React.lazy(() => import( '../pages/patient/VerificationsWithoutAccount/PatientRegister'));
const AccessHealthHistory = React.lazy(() => import( '../pages/patient/VerificationsWithoutAccount/AccessHealthHistoryWithoutAccount'));
const SubmitHealthHistoryPage
    = React.lazy(() => import( '../pages/patient/VerificationsWithoutAccount/AccessHealthHistoryWithoutAccount/SubmitHealthHistoryPage'));
const MobileAndDateScreen
    = React.lazy(() => import( '../pages/patient/VerificationsWithoutAccount/QrCodeVerification/MobileAndDateScreen'));
const PatientWellnessProfileWithoutAccount = React.lazy(() => import( '../pages/patient/WellnessProfileWithoutAccount'));
const SessionExpire = React.lazy(() => import( '../pages/patient/WellnessProfileWithoutAccount/SessionExpire'));
const PatientForgotUserName = React.lazy(() => import( '../pages/patient/Auth/ForgotUserName'));

const Home = React.lazy(() => import('../pages/Home'));
const Unsubscribe = React.lazy(() => (import('../pages/unsubscribeEmail/index')))

const PatientForgotPassword = React.lazy(() => import('../pages/patient/Auth/ForgotPassword'));
const PatientRecoverPassword = React.lazy(() => import('../pages/patient/Auth/RecoverPassword'));
const PatientLogin = React.lazy(() => import('../pages/patient/Auth/Login'));
const PatientChangePassword = React.lazy(() => import('../pages/patient/PatientProfile/ChangePassword'));
const Appointment = React.lazy(() => import('../pages/patient/Appointment/Appointment'));
const AppointmentDetails = React.lazy(() => import('../pages/patient/Appointment/AppointmentDetails'));
const DentistAppointment = React.lazy(() => import('../pages/dentist/Appointments'));
const PageNotFound = React.lazy(() => import('../components/common/PageNotFound'));

const RiskProfileSnapShotSummary = React.lazy(() => import('../pages/patient/PatientSnapShots/snapshotSummary'));
const ViewAllXrayOrImage = React.lazy(() =>
    import('../components/patient/PatientSnapshots/ViewAllXrayOrImage')
);
const DentistDashboard = React.lazy(() => import('../pages/nextgen/Dashboard/Dashboard'));

const PatientTermsConditionAndPrivacyPolicy = React.lazy(() =>
    import('../pages/patient/Auth/TermsConditionAndPrivacyPolicy/TermsConditionAndPrivacyPolicy')
);
const TermsConditionsStaticPage = React.lazy(() => import('../pages/TermsConditionsStaticPage'));
const PrivacyPolicyStaticPage = React.lazy(() => import('../pages/PrivacyPolicyStaticPage'));
const DentistProfile = React.lazy(() => import('../pages/dentist/DentistProfile'));
const Analytics = React.lazy(() => import('../pages/dentist/Analytics'));
const TermsOfUse = React.lazy(() => import('../pages/dentist/TermsOfUse'));
const PrivacyPolicy = React.lazy(() => import('../pages/dentist/PrivacyPolicy'));
const Notification = React.lazy(() => import('../pages/dentist/Notification'));
const Resources = React.lazy(() => import('../pages/dentist/Resources'));
const StaffLogin = React.lazy(() => import('../pages/dentist/Auth/Login'));
const StaffForgotPassword = React.lazy(() => import('../pages/dentist/Auth/ForgotPassword'));
const StaffTermsConditions = React.lazy(() => import('../pages/dentist/Auth/TermsConditions'));
const StaffPrivacyPolicy = React.lazy(() => import('../pages/dentist/Auth/PrivacyPolicy'));
const StaffCreatePassword = React.lazy(() => import('../pages/dentist/Auth/CreatePassword'));
const Disclaimer = React.lazy(() => import('../pages/dentist/Auth/Disclaimer'));
const StaffResetPassword = React.lazy(() => import('../pages/dentist/Auth/ResetPassword'));
const StaffChangePassword = React.lazy(() => import('../pages/dentist/Auth/ChangePassword'));
const PreIntake = React.lazy(() => import('../pages/dentist/PreIntake'));
const PatientPreIntake = React.lazy(() => import('../pages/patient/PreIntake'));
const ClinicalExam = React.lazy(() => import('../pages/dentist/ClinicalExam'));
const WellnessProfile = React.lazy(() => import('../pages/dentist/WellnessProfile'));
const LandingPage = React.lazy(() => import('../pages/landingPage'));
const PatientProfile = React.lazy(() => import('../pages/patient/PatientProfile/PatientProfile'));
const AnonymousPreIntake = React.lazy(() => import('../pages/anonymousPreIntake/Index'));
const SecondScreen = React.lazy(() => import('../pages/dentist/SecondScreen'));
const PatientCommunications = React.lazy(() => import('../pages/patient/Auth/Communications'));
const DentistCommunications = React.lazy(() => import('../pages/dentist/Auth/Communications'));
const PatientsList = React.lazy(() => import('../pages/dentist/PatientsList'));
const OutputScreen=React.lazy(()=>import('../pages/dentist/ClinicalExam/OutputScreen'))

export const paths = [
    {
        path: '/home',
        name: 'Home',
        exact: true,
        component: <Home/>,
        private: false
    },
    //Patient Routes
    {
        path: '/patient/login',
        name: 'Patient Login',
        exact: true,
        component: <PatientLogin/>,
        private: false
    },
    {
        path: '/patient/forgot-password',
        name: 'Patient Forgot password',
        exact: true,
        component: <PatientForgotPassword/>,
        private: false
    },
    {
        path: '/patient/forgot-username',
        name: 'Patient Forgot username',
        exact: true,
        component: <PatientForgotUserName/>,
        private: false
    },
    {
        path: '/patient/recover-password/:recovery_token',
        name: 'Patient Recover password',
        exact: true,
        component: <PatientRecoverPassword/>,
        private: false
    },
    {
        path: '/patient/change-password',
        name: 'Patient Change password',
        exact: true,
        component: <PatientChangePassword/>,
        private: true
    },
    {
        path: '/patient/update-profile',
        name: 'Patient Update Profile',
        exact: true,
        component: <PatientProfile/>,
        private: true
    },
    {
        path: '/patient/appointment/:status',
        name: 'Patient upcoming Appointment',
        exact: true,
        component: <Appointment/>,
        private: true
    },
    {
        path: '/patient/appointment/:status/:appointmentID',
        name: 'Patient Appointment Details',
        exact: true,
        component: <AppointmentDetails/>,
        private: true
    },
    {
        path: '/patient/wellness-profile',
        name: 'Patient RiskProfile',
        exact: true,
        component: <PatientWellnessProfile/>,
        private: true
    },
    {
        path: '/patient/appointment/snapshot/:appointmentID',
        name: 'Patient RiskProfile snapshot summary',
        exact: true,
        component: <RiskProfileSnapShotSummary/>,
        private: true
    },
    {
        path: '/patient/appointment/snapshot/view-all/:appointmentID/:imagetype',
        name: 'Patient RiskProfile snapshot summary',
        exact: true,
        component: <ViewAllXrayOrImage/>,
        private: true
    },
    {
        path: '/patient/create-password',
        name: 'Patient Create Password',
        exact: true,
        component: <CreatePasswordPatient/>,
        private: true
    },
    {
        path: '/patient/terms-and-condition',
        name: 'Patient Terms Condition and Privacy Policy',
        exact: true,
        component: <PatientTermsConditionAndPrivacyPolicy/>,
        private: true
    },
    // Other Routes
    {
        path: '*',
        name: 'Page Not Found',
        exact: true,
        component: <PageNotFound/>,
        private: false
    },
    {
        path: '/tNc',
        name: 'Terms & Condition',
        exact: true,
        component: <TermsConditionsStaticPage/>,
        private: false
    },
    {
        path: '/pNp',
        name: 'Privacy & Policy',
        exact: true,
        component: <PrivacyPolicyStaticPage/>,
        private: false
    },
    {
        path: '/unsubscribe/:token',
        name: 'unsubscribe mail',
        exact: true,
        component: <Unsubscribe/>,
        private: false
    },

    {
        path: 'dentist/dashboard',
        name: 'Dentist Dashboard',
        exact: true,
        component: <MainLayout name={'Dashboard'} component={<DentistDashboard/>}/>,
        private: true
    },
    {
        path: 'dentist/appointment',
        name: 'Dentist Pre Appointment',
        exact: true,
        component: <MainLayout name={'Appointments'} component={<DentistAppointment/>}/>,
        private: true
    },
    {
        path: 'dentist/profile',
        name: 'Profile',
        exact: true,
        component: <MainLayout name={'Profile'} component={<DentistProfile/>}/>,
        private: true
    },
    {
        path: 'dentist/analytics',
        name: 'Analytics',
        exact: true,
        component: <MainLayout name={'Analytics'} component={<Analytics/>}/>,
        private: true
    },
    {
        path: 'dentist/login',
        name: 'Dentist Login',
        exact: true,
        component: <StaffLogin/>,
        private: false
    },
    {
        path: 'dentist/forgot-password',
        name: 'Dentist Forgot password',
        exact: true,
        component: <StaffForgotPassword/>,
        private: false
    },
    {
        path: 'dentist/terms-conditions',
        name: 'Dentist Terms and conditions',
        exact: true,
        component: <StaffTermsConditions/>,
        private: true
    },
    {
        path: 'dentist/privacy-policy',
        name: 'Dentist Privacy Policy',
        exact: true,
        component: <StaffPrivacyPolicy/>,
        private: true
    },
    {
        path: 'dentist/create-password',
        name: 'Dentist Create Password',
        exact: true,
        component: <StaffCreatePassword/>,
        private: true
    },
    {
        path: 'dentist/disclaimer',
        name: 'Disclaimer',
        exact: true,
        component: <Disclaimer/>,
        private: true
    },
    {
        path: 'dentist/communications',
        name: 'Communications',
        exact: true,
        component: <DentistCommunications/>,
        private: true
    },
    {
        path: 'dentist/recover-password/:recovery_token',
        name: 'Dentist Reset Password',
        exact: true,
        component: <StaffResetPassword/>,
        private: false
    },
    {
        path: 'dentist/change-password',
        name: 'Dentist Change Password',
        exact: true,
        component: <MainLayout name={'Change Password'} component={<StaffChangePassword/>}/>,
        private: true
    },
    {
        path: 'dentist/appointment/health-history/:appointmentId/:patientId',
        name: 'Dentist Health History',
        exact: true,
        component: <AppointmentLayout name={'Health History'} component={<PreIntake/>}/>,
        private: true
    },
    {
        path: 'dentist/appointment/health-history/:patientId',
        name: 'Dentist Health History',
        exact: true,
        component: <AppointmentLayout name={'Health History'} component={<PreIntake/>}/>,
        private: true
    },
    {
        path: 'dentist/appointment/clinical-exam/:appointmentId/:patientId',
        name: 'Clinical Exam',
        exact: true,
        component: <AppointmentLayout name={'clinic-exam'} component={<ClinicalExam/>}/>,
        private: true
    },
    {
        path: 'dentist/termOfUse',
        name: 'Terms of use',
        exact: true,
        component: <MainLayout name={'Terms of Use'} component={<TermsOfUse/>}/>,
        private: true
    },
    {
        path: 'dentist/privacyPolicy',
        name: 'Privacy policy',
        exact: true,
        component: <MainLayout name={'Privacy Policy'} component={<PrivacyPolicy/>}/>,
        private: true
    },
    {
        path: 'dentist/notification',
        name: 'Notification',
        exact: true,
        component: <MainLayout name={'Notifications'} component={<Notification/>}/>,
        private: true
    },
    {
        path: 'dentist/resources',
        name: 'Resources',
        exact: true,
        component: <MainLayout name={'Resources'} component={<Resources/>}/>,
        private: true
    },
    {
        path: 'dentist/wellness-profile/:patientId',
        name: 'Wellness Profile',
        exact: true,
        component: <AppointmentLayout name={'wellness-profile'} component={<WellnessProfile/>}/>,
        private: true
    },
    {
        path: 'dentist',
        name: 'Dentist Landing Page',
        exact: true,
        component: <LandingPage/>,
        private: false
    },

    //patient
    {
        path: 'patient/health-history',
        name: 'Patient Health History',
        exact: true,
        component: <PatientPreIntake/>,
        private: true
    },
    {
        path: 'patient',
        name: 'Patient Landing Page',
        exact: true,
        component: <LandingPage/>,
        private: false
    },
    {
        path: 'anonymous-health-history/:clinicID',
        name: 'Anonymous Health History',
        exact: true,
        component: <AnonymousPreIntake/>,
        private: false
    },
    {
        path: 'dentist/second-screen/:appointmentId/:patientId',
        name: 'Second Screen',
        exact: true,
        component: <SecondScreen/>,
        private: true
    },
    {
        path: '/patient/communications',
        name: 'Patient Communications',
        exact: true,
        component: <PatientCommunications/>,
        private: true
    },
    {
        path: '/patient/send-otp/:clinicId/:patientId',
        name: 'Patient Send OTP',
        exact: true,
        component: <SendOtpScreen/>,
        private: false
    },
    {
        path: '/patient/verify-otp/:clinicId/:patientId',
        name: 'Patient Verify OTP',
        exact: true,
        component: <VerifyOtpScreen/>,
        private: false
    },
    {
        path: '/patient/verify-mobile/:clinicId',
        name: 'Patient Verify Mobile',
        exact: true,
        component: <MobileAndDateScreen/>,
        private: false
    },
    {
        path: '/patient/verify-access-code/:clinicId',
        name: 'Patient Verify Access Code',
        exact: true,
        component: <AccessCodeScreen/>,
        private: false
    },
    {
        path: '/patient/access-health-history/:clinicId/:patientId',
        name: 'Patient Verify Access Code',
        exact: true,
        component: <AccessHealthHistory/>,
        private: false
    },
    {
        path: '/patient/submit-health-history/:clinicId/:patientId',
        name: 'Submitted Health History',
        exact: true,
        component: <SubmitHealthHistoryPage/>,
        private: false
    },
    {
        path: '/patient/patient-register/:clinicId/:patientId',
        name: 'Patient Register',
        exact: true,
        component: <PatientRegister/>,
        private: false
    },
    {
        path: '/patient/verify-mobile/wellness/:clinicId',
        name: 'Patient Verify Mobile',
        exact: true,
        component: <MobileAndDateScreen/>,
        private: false
    },
    {
        path: '/patient/verify-otp/wellness/:clinicId/:patientId',
        name: 'Patient Verify OTP',
        exact: true,
        component: <VerifyOtpScreen/>,
        private: false
    },
    {
        path: '/patient/verify-access-code/wellness/:clinicId',
        name: 'Patient Verify Access Code',
        exact: true,
        component: <AccessCodeScreen/>,
        private: false
    },
    {
        path: '/patient/without-account/wellness-profile/:clinicId/:patientId',
        name: 'Patient RiskProfile',
        exact: true,
        component: <PatientWellnessProfileWithoutAccount/>,
        private: false
    },
    {
        path: 'dentist/patients-list',
        name: 'Patients',
        exact: true,
        component: <MainLayout name={'Patients'} component={<PatientsList/>}/>,
        private: false
    },
    {
        path: '/patient/session-expired',
        name: 'Session Expired',
        exact: true,
        component: <SessionExpire/>,
        private: false
    },
    {
        path: 'dentist/output-screen/:appointmentId/:patientId',
        name: 'Output Screen',
        exact: true,
        component: <OutputScreen/>,
        private: true
    },
];
