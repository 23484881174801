import React, { useState, useEffect, useCallback, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  InputAdornment,
  Popper,
  Paper,
  Box,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { debounce } from "lodash";
import theme from "../../../../theme";

const useStyles = makeStyles((theme: any) => ({
  scheduleAppointment: {
    backgroundColor: theme.palette.common.white,
    height: "40px",
    color: `${theme.palette.common.black50}`,
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      height: "40px",
      borderRadius: "8px",
      color: `${theme.palette.text.secondary}`,
    },
  },
  listbox: {
    padding: "8px 0",
    "& .MuiAutocomplete-option": {
      padding: "12px 16px",
      transition: "background-color 0.2s ease",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  loadingIndicator: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 0",
  },
}));

interface AsyncAutocompleteProps {
  label?: string;
  fetchOptions: (query?: string, page?: number) => Promise<any>;
  debounceTime?: number;
  placeholder?: string;
  onSelect?: (selectedValue: any) => void;
}

const AsyncAutocomplete: React.FC<AsyncAutocompleteProps> = ({
  fetchOptions,
  debounceTime = 500,
  placeholder = "Type to search...",
  onSelect,
}) => {
  const classes = useStyles();
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [open, setOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const listboxRef = useRef<HTMLUListElement | null>(null);
  
  // Media queries for responsive design
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:601px) and (max-width:960px)');
  
  // Determine width based on screen size
  const getComponentWidth = () => {
    if (isSmallScreen) return '10rem';
    if (isTablet) return '20rem';
    return '40rem';
  };

  const debouncedFetchResults = useCallback(
    debounce(async (query: string) => {
      if (!query) return;
      if (query.length < 3) return;
      setLoading(true);
      setPage(1); // Reset page when query changes
      try {
        const results = await fetchOptions(query, 1);
        if (results?.data?.detail) {
          setOptions(results.data.detail);
          setHasMore(results.data.meta?.has_more); // Assuming 10 is the page size
          setOpen(true);
        } else {
          setOptions([]);
          setHasMore(false);
          setOpen(false);
        }
      } catch (error) {
        console.error("Error fetching autocomplete data:", error);
      }
      setLoading(false);
    }, debounceTime),
    [fetchOptions, debounceTime]
  );

  // Function to load more results
  const loadMore = useCallback(async () => {
    if (!inputValue || inputValue.length < 3 || loading || loadingMore || !hasMore) return;
    
    setLoadingMore(true);
    try {
      const nextPage = page + 1;
      const results = await fetchOptions(inputValue, nextPage);
      
      if (results?.data?.detail && results.data.detail.length > 0) {
        setOptions(prevOptions => [...prevOptions, ...results.data.detail]);
        setPage(nextPage);
        setHasMore(results.data.hasMore || results.data.detail.length >= 10); // Assuming 10 is the page size
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error loading more results:", error);
    }
    setLoadingMore(false);
  }, [fetchOptions, inputValue, page, loading, loadingMore, hasMore]);

  // Scroll event handler for infinite scrolling
  const handleScroll = useCallback(() => {
    if (!listboxRef.current || !hasMore || loadingMore) return;
    
    const listbox = listboxRef.current;
    if (listbox.scrollHeight - listbox.scrollTop <= listbox.clientHeight + 100) {
      // When user scrolls near bottom, load more
      loadMore();
    }
  }, [loadMore, hasMore, loadingMore]);

  // Attach scroll listener to listbox when it's available
  useEffect(() => {
    const listbox = listboxRef.current;
    
    if (listbox) {
      listbox.addEventListener('scroll', handleScroll);
      return () => {
        listbox.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll, open]);

  // Fetch results when input value changes
  useEffect(() => {
    debouncedFetchResults(inputValue);
  }, [inputValue, debouncedFetchResults]);

  const componentWidth = getComponentWidth();
  const popperWidth = isSmallScreen ? '100%' : componentWidth;

  return (
    <Autocomplete
      freeSolo
      open={open}
      options={options}
      getOptionLabel={(option) => option.patient_name || ""}
      loading={loading}
      loadingText={
        <div className="fw-regular f-14" style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <span>Loading...</span>
        </div>
      }
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={(event, value, reason) => {
        if (reason === "reset") return;
        setInputValue(value);
        setOpen(true);
      }}
      onChange={(event, newValue) => {
        if (newValue) {
          onSelect?.(newValue);
          setOpen(false);
        }
      }}
      ListboxProps={{
        className: classes.listbox,
        ref: listboxRef,
        style: { maxHeight: '400px', overflow: 'auto' }
      }}
      renderOption={(props, option: any) => (
        <li
          {...props}
          key={option.id}
          style={{
            padding: "12px 16px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <span className="fw-regular f-14">{`${option.patient_name} - DOB: ${new Date(option.date_of_birth).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}`}</span>
          </div>
        </li>
      )}
      PaperComponent={({ children }) => (
        <Paper
          elevation={3}
          style={{
            marginTop: "8px",
            borderRadius: "8px",
            backgroundColor: "#ffffff",
          }}
        >
          {React.Children.toArray(children).concat(
            loadingMore && (
              <Box className={classes.loadingIndicator}>
                <CircularProgress size={20} />
              </Box>
            ),
            !loadingMore && hasMore && options.length > 0 && (
              <Box className={classes.loadingIndicator} style={{ height: "10px" }} />
            )
          )}
        </Paper>
      )}
      PopperComponent={(props) => (
        <Popper
          {...props}
          style={{
            ...props.style,
            width: isSmallScreen ? '100%' : 'auto',
            minWidth: popperWidth,
            maxWidth: isSmallScreen ? '100vw' : 'none',
          }}
        />
      )}
      renderInput={(params) => (
        <TextField
          className={`${classes.scheduleAppointment} fw-regular f-14`}
          {...params}
          style={{ whiteSpace: "nowrap", color: theme.palette.text.secondary }}
          variant="outlined"
          placeholder={placeholder}
          size="small"
          fullWidth
          InputProps={{
            ...params.InputProps,
            classes: {
              root: "cssOutlinedInput fw-regular f-w-400 f-14 labelColor",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{
            width: componentWidth,
            "& .MuiOutlinedInput-root": {
              color: `${theme.palette.common.black50} !important`,
              fontFamily: "FavoritPro-Regular !important",
              background: "white !important",
              "& fieldset": {
                borderColor: theme.palette.text.darkGrey,
              },
              "&:hover fieldset": {
                borderColor: theme.palette.common.black50,
              },
              "&.Mui-focused fieldset": {
                border: `2px solid ${theme.palette.v2.primary.main} !important`,
              },
            },
          }}
        />
      )}
    />
  );
};

export default AsyncAutocomplete;