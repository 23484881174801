import React, { useMemo } from "react";
import { Box, Typography, useTheme } from "@mui/material";

interface StatusIndicatorProps {
  completed: boolean;
  data?: any;
  className?: {
    primary?: string;
    secondary?: string;
    color?: string;
  };
}

const StatusIndicator: React.FC<StatusIndicatorProps> = React.memo(
  ({ completed, className, data }) => {
    const theme: any = useTheme();

    const computeColor = useMemo(() => {
      const status = data.pre_intake_status.toLowerCase();
      const isHealthHistoryCompleted = status === "health history completed";
      const { is_invitation_sent } = data;

      const colorMap = {
        "not-invited-not-completed":
          !is_invitation_sent && !isHealthHistoryCompleted
            ? theme.palette?.text.purple
            : null,

        completed: isHealthHistoryCompleted ? theme.palette?.text.green : null,

        "invited-pending":
          is_invitation_sent && !isHealthHistoryCompleted
            ? theme.palette?.text.amber
            : null,
      };

      return (
        Object.values(colorMap).find((color) => color !== null) ||
        theme?.palette?.text?.amber
      );
    }, [data]);

    function formatDateTime(isoString: string) {
      const date = new Date(isoString);

      // Get month name
      const month = date.toLocaleString("en-US", { month: "long" });

      // Get day (numeric)
      const day = date.getDate();

      // Get hour and minute
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");

      // Convert to AM/PM format
      const ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // Convert hour '0' to '12'

      // Format the final string
      return `${month} ${day} at ${hours}:${minutes} ${ampm}`;
    }

    return (
      <Box pt={0.75} gap={0.5} display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" gap={1}>
          <Typography
            className={className?.primary ?? "fw-regular f-12"}
            variant="body2"
            sx={{ color: computeColor }}
          >
            {formatDateTime(data?.appointmentDateTime)}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" gap={1}>
          <Box
            sx={(theme: any) => ({
              width: 8,
              height: 8,
              borderRadius: "50%",
              bgcolor: computeColor,
            })}
          />
          <Typography
            className={className?.secondary ?? "fw-medium f-10"}
            variant="body2"
            sx={{ color: computeColor }}
          >
            {data?.pre_intake_status}
          </Typography>
        </Box>
      </Box>
    );
  }
);

export default StatusIndicator;
