import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import API from '../../../utils/api';
import {
    ApiEndpointVersion,
    checkAPIParams,
    errorToaster,
    newDateFormate,
} from '../../../utils/helper';
import {photo} from '../../../utils/constant/clinicalExamConstant';
import {
    appointmentDetailsRequestFailureAction,
    appointmentDetailsRequestSuccessAction,
    changeLoginUserPermissionRequestFailureAction,
    changeLoginUserPermissionRequestSuccessAction,
    dashboardMemberCacheDataClearRequest,
    dashboardMemberRequestFailure,
    dashboardMemberRequestSuccess,
    dentistNewAppointmentRequestFailure,
    dentistNewAppointmentRequestSuccess,
} from '../../actions/dentist/appointmentsAction';
import {
    saveFindingTypeStepAction,
    updateFindingTypeAction,
    updateOpenCompleteStepAction
} from '../../actions/dentist/clinicalExam/clinicalExamFilterAction';
import {
    APPOINTMENT_DETAILS_REQUEST,
    CHANGE_LOGIN_USER_PERMISSION_REQUEST,
    DASHBOARD_MEMBERS_REQUEST,
    DENTIST_NEW_APPOINTMENT_REQUEST,
} from '../../actionTypes/dentist/appointment';
import {RootState} from '../../reducers/rootReducer';

// get appointments all staff
function* dentistNewAppointmentRequestSaga(payload: any): any {
    const {
        appointmentsReducer: {
            startDate,
            endDate,
            desc,
            sortBy,
            searchData,
            exam,
            role,
            status,
            teamMember,
        }
    } = yield select((state: RootState) => state) as any
    try {
        const params = [
            {key: 'search', value: searchData},
            {key: 'page', value: payload.payload.page},
            {key: 'size', value: payload.payload.size},
            {key: 'clinic_id', value: payload.payload.clinic_id},
            {key: 'from_date', value:  newDateFormate(payload.payload.from_date) || newDateFormate(startDate)},
            {key: 'to_date', value: newDateFormate(payload.payload.to_date) || newDateFormate(endDate)},
            {key: 'role', value: role},
            {key: 'staff_id', value: teamMember},
            {key: 'status', value: status?.toString()},
            {key: 'appointment_type', value: exam},
            {key: 'sort_by', value: sortBy},
            {key: 'desc', value: desc},
        ]
        const response = yield API.get(`${ApiEndpointVersion}/appointments/all/staff${checkAPIParams(params)}`)
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data)
        }
        yield put(
            dentistNewAppointmentRequestSuccess({
                newAppointment_data: response.data?.detail,
                newAppointment_meta: response.data?.meta
            })
        )
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            dentistNewAppointmentRequestFailure({
                error: e?.meta?.message,
            })
        )
    }
}

// dashboard members
function* dashboardMembersRequestSaga(payload: any): any {
    yield put(dashboardMemberCacheDataClearRequest())
    try {
        const params = [
            {key: 'clinic_id', value: payload.payload.clinic_id},
        ]
        const response = yield API.get(`${ApiEndpointVersion}/dashboard/members${checkAPIParams(params)}`)
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data)
        }
        yield put(
            dashboardMemberRequestSuccess({
                members_data: response.data?.detail,
                members_meta: response.data?.meta
            })
        )
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            dashboardMemberRequestFailure({
                member_error: e?.meta?.message,
            })
        )
    }
}

//appointment details
function* appointmentDetailsRequestSaga(payload: any): any {
    const {clinicalExamFilters: {saveFindingTypeStep}} = yield select((state: RootState) => state) as any

    try {
        const response = yield API.get(`/${ApiEndpointVersion}/appointments/${payload.payload.appointment_id}`);
        yield put(updateOpenCompleteStepAction(false))
        yield put(
            appointmentDetailsRequestSuccessAction({
                appointmentDetails: response?.data?.detail
            })
        );
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
        const newStep = saveFindingTypeStep === null ? photo : saveFindingTypeStep

        yield put(updateFindingTypeAction(newStep))
        if (!payload.payload.fromPreIntake) {
            yield put(saveFindingTypeStepAction(null))
        }

    } catch (e: any) {
        yield put(
            appointmentDetailsRequestFailureAction({
                appointmentDetailsError: e?.detail
            })
        );
    }
}

// role base update
function* changeLoginUserPermissionRequestSaga(payload: any): any {
    try {
        const payloadData = {
            staff_id: payload.payload?.staff_id,
            clinic_id: payload.payload?.clinic_id,
            position: payload.payload?.position
        }

        const response = yield API.put(`${ApiEndpointVersion}/appointments/${payload.payload.appointment_id}/staff/replace`, payloadData);

        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data)
        }
        yield put(
            changeLoginUserPermissionRequestSuccessAction(response?.data?.detail)
        )
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            changeLoginUserPermissionRequestFailureAction(e?.meta?.message)
        )
    }
}

function* dentistNewAppointmentSaga() {
    yield takeEvery(DENTIST_NEW_APPOINTMENT_REQUEST, dentistNewAppointmentRequestSaga)
}

function* dashboardMembersSaga() {
    yield takeEvery(DASHBOARD_MEMBERS_REQUEST, dashboardMembersRequestSaga)
}

function* appointmentDetailsSaga() {
    yield takeEvery(APPOINTMENT_DETAILS_REQUEST, appointmentDetailsRequestSaga)
}

function* changeLoginUserPermissionSaga() {
    yield takeEvery(CHANGE_LOGIN_USER_PERMISSION_REQUEST, changeLoginUserPermissionRequestSaga)
}


export default function* appointmentSaga() {
    yield all([
        dentistNewAppointmentSaga(),
        dashboardMembersSaga(),
        appointmentDetailsSaga(),
        changeLoginUserPermissionSaga(),
    ])
}
