import React, { useEffect, useRef } from "react";
import { faAngleUp, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
    getFieldsAction,
    updateMedicalHealthHistoryAction,
    updatePreIntakeValidationsAction,
} from "../../../../redux/actions/dentist/preIntakeActions";
import { RootState } from "../../../../redux/reducers/rootReducer";
import CollapsibleBox from "../../UIComponents/CollapsibleBox";
import { textLengthStyles } from "../../../../pages/dentist/PreIntake";
import {
    convertToLowerCase,
    formatPhoneNumber,
    handleChangeWithDebounce,
    phoneNumberPlaceholder,
    usDateFormat,
} from "../../../../utils/helper";
import { radioButtonOptions, } from "../../../../utils/constant/preIntakeConstant/preIntakeConstant";
import { medicalHealthHistoryValidator } from "../../../../validation/preIntakeValidator";
import CheckboxSelect from "../../UIComponents/CheckboxSelect";
import DateSelect from "../../UIComponents/DateSelect";
import EndAdornmentIcon from "../../UIComponents/EndAdornmentIcon";
import InputHeading from "../../UIComponents/InputHeading";
import RadioSelect from "../../UIComponents/RadioSelect";
import StartAdornmentIcon from "../../UIComponents/StartAdornmentIcon";
import TextBox from "../../UIComponents/TextBox";
import {ALLERGIES, MEDICALCONDITIONS} from "../../../../utils/constant/commonConstant";

const useStyles = makeStyles((theme: any) => ({
    accordionRoot: {
        "&:before": {
            backgroundColor: "transparent !important",
        },
    },
    accordionSummary: {
        height: "48px",
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.common.borderGrey}`,
        padding: "0px 12px !important",
        margin: 0,
    },
    accordionDetails: {
        backgroundColor: theme.palette.common.white,
        borderRadius: "0px 0px 8px 8px",
        borderLeft: `1px solid ${theme.palette.common.borderGrey}`,
        borderRight: `1px solid ${theme.palette.common.borderGrey}`,
        borderBottom: `1px solid ${theme.palette.common.borderGrey}`,
        padding: "12px",
    },
    expanded: {
        "&$expanded": {
            minHeight: 43,
            maxHeight: 43,
        },
    },
    borderRadiusExpanded: {
        margin: 0,
        borderRadius: "8px 8px 0px 0px !important",
    },
    borderRadiusAccordionSummary: {
        borderRadius: "8px !important",
    },
    accordionDetailsBorderExpanded: {
        border: "none !important",
    },
}));

type Props = {
    disabledField?: boolean;
    info?: string;
};
const MedicalHealthHistory = React.memo((props: Props) => {
    const { disabledField, info="" } = props;
    const lengthClasses = textLengthStyles();
    const dispatch = useDispatch();
    const noteRef = useRef(null) as any
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<boolean>(true) as any;
    const [expandedSecond, setExpandedSecond] = React.useState<boolean>(true) as any;
    const { medicalHealthHistory, errors, getFiledData } = useSelector((state: RootState) => state.preIntake) as any;

    useEffect(() => {
        dispatch(getFieldsAction({
            slug: 'pre-intake',
            fieldName: MEDICALCONDITIONS,
            fieldKey: 'medicalConditionsData',
            callback: () => {
                dispatch(getFieldsAction({
                    slug: 'pre-intake',
                    fieldName: ALLERGIES,
                    fieldKey: 'allergiesListData',
                }))
            }
        }))
    }, [dispatch]);

    const handleChange = handleChangeWithDebounce((e: any) => {
        const { value, name, id } = e.target as any;
        let updatedMedicalHealthHistory = medicalHealthHistory as any;
        updatedMedicalHealthHistory[name || id] =
            name === "physicianContactNumber" ? formatPhoneNumber(value) : value;
        dispatch(updateMedicalHealthHistoryAction(updatedMedicalHealthHistory));

        // for the update error
        if (errors?.medicalHealthHistory?.[name || id]) {
            const medicalHealthHistoryValid = medicalHealthHistoryValidator(
                updatedMedicalHealthHistory, getFiledData
            ) as any;
            dispatch(
                updatePreIntakeValidationsAction({
                    ...errors,
                    medicalHealthHistory: {
                        ...errors.medicalHealthHistory,
                        [name || id]: errors.medicalHealthHistory[name || id]
                            ? medicalHealthHistoryValid.errors[name || id]
                            : "",
                    },
                })
            );
        }
    });
    const handleChangeWithoutDebounce = (e: any) => {
        const { value, name, id } = e.target as any;
        let updatedMedicalHealthHistory = medicalHealthHistory as any;
        updatedMedicalHealthHistory[name || id] =
            name === "physicianContactNumber" ? formatPhoneNumber(value) : value;
        dispatch(updateMedicalHealthHistoryAction(updatedMedicalHealthHistory));
        // for the update error
        if (errors?.medicalHealthHistory?.[name || id]) {
            const medicalHealthHistoryValid = medicalHealthHistoryValidator(
                updatedMedicalHealthHistory, getFiledData
            ) as any;
            dispatch(
                updatePreIntakeValidationsAction({
                    ...errors,
                    medicalHealthHistory: {
                        ...errors?.medicalHealthHistory,
                        [name || id]: errors?.medicalHealthHistory[name || id]
                            ? medicalHealthHistoryValid?.errors[name || id]
                            : "",
                    },
                })
            );
        }
    };
    const clearClickHandler = (id: string) => {
        let updatedMedicalHealthHistory = medicalHealthHistory;
        updatedMedicalHealthHistory[id] = null;
        dispatch(updateMedicalHealthHistoryAction(updatedMedicalHealthHistory));
    };
    const removeOtherValue = (id: any) => {
        let updatedMedicalHealthHistory = medicalHealthHistory;
        updatedMedicalHealthHistory[id] = "";
        dispatch(updateMedicalHealthHistoryAction(updatedMedicalHealthHistory));

        // for the update error
        if (errors?.medicalHealthHistory?.[id]) {
            const medicalHealthHistoryValid = medicalHealthHistoryValidator(
                updatedMedicalHealthHistory, getFiledData
            ) as any;
            dispatch(
                updatePreIntakeValidationsAction({
                    ...errors,
                    medicalHealthHistory: {
                        ...errors?.medicalHealthHistory,
                        [id]: errors?.medicalHealthHistory[id]
                            ? medicalHealthHistoryValid?.errors[id]
                            : "",
                    },
                })
            );
        }
    };
    const handleChangeVal = (val?: any, key?: any) => {
        let updatedMedicalHealthHistory = medicalHealthHistory;
        updatedMedicalHealthHistory[key] = val;
        dispatch(updateMedicalHealthHistoryAction(updatedMedicalHealthHistory));
    };

    const scrollBottomKeys = ['checkboxListTwoOtherInput', 'medicalConditionsOther']
    const scrollBlockBottom = scrollBottomKeys?.some((f: any) => Object.keys(errors?.medicalHealthHistory)?.includes(f))
    const otherIdMedical = getFiledData?.medicalConditionsData?.find((item: any) => item.name === "Other");
    const isOtherMedicalIncluded = otherIdMedical && medicalHealthHistory?.medicalConditions.includes(otherIdMedical?.id);
    const noneOfAboveIdMedical = getFiledData?.medicalConditionsData?.find((item: any) => item.name === "None of the above");
    const isNoneOfAboveMedicalIncluded = noneOfAboveIdMedical && medicalHealthHistory?.medicalConditions.includes(noneOfAboveIdMedical?.id);


    const otherAllergies = getFiledData?.allergiesListData?.find((item: any) => item.name === "Other");
    const isOtherAllergiesIncluded = otherAllergies && medicalHealthHistory?.checkboxListTwo?.includes(otherAllergies?.id);
    const noneOfAboveAllergies = getFiledData?.allergiesListData?.find((item: any) => item.name === "None of the above");
    const isNoneOfAboveAllergiesIncluded = noneOfAboveAllergies && medicalHealthHistory?.checkboxListTwo?.includes(noneOfAboveAllergies?.id);

    const disabledCheckBoxMedical = (id: string) => {
        return isNoneOfAboveMedicalIncluded && noneOfAboveIdMedical?.id !== id && otherIdMedical?.id !== id
    }
    const disabledCheckBoxAllergies = (id: string) => {
        return isNoneOfAboveAllergiesIncluded && noneOfAboveAllergies?.id !== id && otherAllergies?.id !== id
    }
    const checkBoxChangeOne = (e: any, item: any) => {
        let updatedMedicalHealthHistory = medicalHealthHistory;

        if (e?.target?.checked === true) {
            if (item?.name === "None of the above") {
                // Retain only "Other" and "None of the above" if "None of the above" is checked
                updatedMedicalHealthHistory.medicalConditions = updatedMedicalHealthHistory?.medicalConditions?.filter(
                    (id: any) =>
                        id === otherIdMedical?.id ||
                        id === noneOfAboveIdMedical?.id
                ) || [];
                updatedMedicalHealthHistory.medicalConditions.push(item?.id);
            } else {
                // Add the item ID to the list
                updatedMedicalHealthHistory?.medicalConditions?.push(item?.id);
            }
        } else {
            if (item?.name === "Other") {
                removeOtherValue('medicalConditionsOther')
            }
            updatedMedicalHealthHistory.medicalConditions =
                medicalHealthHistory?.medicalConditions?.filter(
                    (f: any) => f !== item?.id
                );
        }
        dispatch(updateMedicalHealthHistoryAction(updatedMedicalHealthHistory));
    };

    const checkBoxChangeTwo = (e: any, item: any) => {
        let updatedMedicalHealthHistory = medicalHealthHistory;
        if (e?.target?.checked === true) {
            if (item?.name === "None of the above") {
                // Retain only "Other" and "None of the above" if "None of the above" is checked
                updatedMedicalHealthHistory.checkboxListTwo = updatedMedicalHealthHistory?.checkboxListTwo?.filter(
                    (id: any) =>
                        id === otherAllergies?.id ||
                        id === noneOfAboveAllergies?.id
                ) || [];
                updatedMedicalHealthHistory.checkboxListTwo.push(item?.id);
            } else {
                // Add the item ID to the list
                updatedMedicalHealthHistory?.checkboxListTwo?.push(item?.id);
            }
        } else {
            updatedMedicalHealthHistory.checkboxListTwo =
                medicalHealthHistory?.checkboxListTwo?.filter(
                    (f: any) => f !== item?.id
                );
        }
        dispatch(updateMedicalHealthHistoryAction(updatedMedicalHealthHistory));
    };

    return (
        <CollapsibleBox
            title={"Medical Health History"}
            id={"medicalHealthHistory"}
            scrollBlock={scrollBlockBottom ? 'end' : 'start'}
            info={info}
        >
            <Grid container rowSpacing={1.5} columnSpacing={2.5}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextBox
                        disabled={disabledField}
                        label={"Physician's Name"}
                        labelProps={{
                            htmlFor: "physicianName",
                        }}
                        maxLength={30}
                        inputProps={{
                            id: "physicianName",
                            name: "physicianName",
                            onChange: (e: any) => handleChange(e),
                            defaultValue: medicalHealthHistory?.physicianName,
                            error: errors?.medicalHealthHistory?.physicianName,
                            helperText: errors?.medicalHealthHistory?.physicianName,
                            fullWidth: true,
                            InputProps: {
                                classes: {
                                    root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                    input: "textFieldStartEndIcon",
                                },
                                endAdornment: (
                                    <InputAdornment position="end" className={lengthClasses.textLengthCount}>
                                        {`${medicalHealthHistory?.physicianName?.length || 0} / 30`}
                                    </InputAdornment>
                                )
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextBox
                        disabled={disabledField}
                        label={"Physician's Contact Number"}
                        labelProps={{
                            htmlFor: "physicianContactNumber",
                        }}
                        maxLength={14}
                        inputProps={{
                            placeholder: phoneNumberPlaceholder,
                            id: "physicianContactNumber",
                            name: "physicianContactNumber",
                            InputProps: {
                                classes: {
                                    root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                    input: "textFieldStartEndIcon",
                                },
                                startAdornment: (
                                    <StartAdornmentIcon text={"+1"} disabled={disabledField} />
                                ),
                                endAdornment: (
                                    <EndAdornmentIcon icon={faPhone} disabled={disabledField} />
                                ),
                            },
                            onChange: (e: any) => handleChangeWithoutDebounce(e),
                            value: formatPhoneNumber(
                                medicalHealthHistory?.physicianContactNumber
                            ),
                            error: errors?.medicalHealthHistory?.physicianContactNumber,
                            helperText: errors?.medicalHealthHistory?.physicianContactNumber,
                            fullWidth: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <InputHeading title={"Date of Last Visit"} />
                    <DateSelect
                        disabled={disabledField}
                        disableFuture
                        clearClickHandler={() => clearClickHandler("dateOfLastVisit")}
                        value={medicalHealthHistory?.dateOfLastVisit || null}
                        showClearIcon
                        onChange={(newValue: any) => {
                            let newFormatted = moment(new Date(newValue))?.format(
                                usDateFormat
                            );
                            handleChangeVal(newFormatted, "dateOfLastVisit");
                        }}
                    />
                </Grid>

                <Grid item
                    xs={12}
                    sm={(convertToLowerCase(medicalHealthHistory?.seriousIllnessesOrOperations) === "yes") ? 12 : 6}
                    md={(convertToLowerCase(medicalHealthHistory?.seriousIllnessesOrOperations) === "yes") ? 12 : 6}
                    lg={6}
                >
                    <InputHeading title={"Have you had any serious illnesses or operations?"} />
                    <Box display={"flex"}>

                        <RadioSelect
                            disabled={disabledField}
                            sx={{ flexWrap: "nowrap", marginRight: "14px !important" }}
                            options={radioButtonOptions}
                            groupProps={{
                                name: "seriousIllnessesOrOperations",
                                id: "seriousIllnessesOrOperations",
                                defaultValue:
                                    medicalHealthHistory?.seriousIllnessesOrOperations,
                                onChange: (e: any) => {
                                    handleChange(e);
                                    removeOtherValue("seriousIllnessesOrOperationsInput");
                                },
                            }}
                        />
                        {convertToLowerCase(
                            medicalHealthHistory?.seriousIllnessesOrOperations
                        ) === "yes" && (
                                <Grid item xs={12}>
                                    <TextBox
                                        disabled={disabledField}
                                        placeholder="list any serious illness or operations. (ie: knee surgery, heart surgery, etc..) "
                                        maxLength={150}
                                        resize={true}
                                        resizeVerticalScreen={true}
                                        inputProps={{
                                            id: "seriousIllnessesOrOperationsInput",
                                            name: "seriousIllnessesOrOperationsInput",
                                            onChange: (e: any) => handleChange(e),
                                            defaultValue:
                                                medicalHealthHistory?.seriousIllnessesOrOperationsInput,
                                            error:
                                                errors?.medicalHealthHistory
                                                    ?.seriousIllnessesOrOperationsInput,
                                            helperText:
                                                errors?.medicalHealthHistory
                                                    ?.seriousIllnessesOrOperationsInput,
                                            fullWidth: true,
                                            InputProps: {
                                                classes: {
                                                    root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                                    input: "textFieldStartEndIconNumbers",
                                                },
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end"
                                                        className={lengthClasses.textLengthCount}
                                                        sx={{ alignSelf: 'flex-end', paddingBottom: '15px' }}
                                                    >
                                                        {`${medicalHealthHistory?.seriousIllnessesOrOperationsInput?.length || 0} / 150`}
                                                    </InputAdornment>
                                                )
                                            },
                                            multiline: true,
                                            ref: noteRef
                                        }}
                                    />
                                </Grid>
                            )}
                    </Box>
                </Grid>

                <Grid item
                    xs={12}
                    sm={(convertToLowerCase(medicalHealthHistory?.takingMedicationsOrSupplements) === "yes") ? 12 : 6}
                    md={(convertToLowerCase(medicalHealthHistory?.takingMedicationsOrSupplements) === "yes") ? 12 : 6}
                    lg={6}
                >
                    <InputHeading title={"Are you currently taking any medications and/or supplements?"} />
                    <Box display={"flex"}>
                        <RadioSelect
                            disabled={disabledField}
                            sx={{ flexWrap: "nowrap", marginRight: "14px !important" }}
                            options={radioButtonOptions}
                            groupProps={{
                                name: "takingMedicationsOrSupplements",
                                id: "takingMedicationsOrSupplements",
                                defaultValue:
                                    medicalHealthHistory?.takingMedicationsOrSupplements,
                                onChange: (e: any) => {
                                    handleChange(e);
                                    removeOtherValue("takingMedicationsOrSupplementsInput");
                                },
                            }}
                        />
                        {convertToLowerCase(
                            medicalHealthHistory?.takingMedicationsOrSupplements
                        ) === "yes" && (
                                <Grid item xs={12}>
                                    <TextBox
                                        disabled={disabledField}
                                        placeholder="list any medications or supplements they are taking (ie: vitamins, aspirin..etc..) "
                                        maxLength={150}
                                        resize={true}
                                        resizeVerticalScreen={true}
                                        inputProps={{
                                            id: "takingMedicationsOrSupplementsInput",
                                            name: "takingMedicationsOrSupplementsInput",
                                            onChange: (e: any) => handleChange(e),
                                            defaultValue:
                                                medicalHealthHistory?.takingMedicationsOrSupplementsInput,
                                            error:
                                                errors?.medicalHealthHistory
                                                    ?.takingMedicationsOrSupplementsInput,
                                            helperText:
                                                errors?.medicalHealthHistory
                                                    ?.takingMedicationsOrSupplementsInput,
                                            fullWidth: true,
                                            InputProps: {
                                                classes: {
                                                    root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                                    input: "textFieldStartEndIconNumbers",
                                                },
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end"
                                                        className={lengthClasses.textLengthCount}
                                                        sx={{ alignSelf: 'flex-end', paddingBottom: '15px' }}
                                                    >
                                                        {`${medicalHealthHistory?.takingMedicationsOrSupplementsInput?.length || 0} / 150`}
                                                    </InputAdornment>
                                                )
                                            },
                                            multiline: true,
                                            ref: noteRef
                                        }}
                                    />
                                </Grid>
                            )}
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                    <InputHeading
                        title={"Do you experience chest pains and/or shortness of breath?"}
                    />
                    <RadioSelect
                        disabled={disabledField}
                        sx={{ flexWrap: "nowrap" }}
                        options={radioButtonOptions}
                        groupProps={{
                            name: "shortnessBreath",
                            id: "shortnessBreath",
                            defaultValue: medicalHealthHistory?.shortnessBreath,
                            onChange: (e: any) => handleChange(e),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <InputHeading title={"Are you nursing?"} />
                    <RadioSelect
                        disabled={disabledField}
                        sx={{ flexWrap: "nowrap" }}
                        options={radioButtonOptions}
                        groupProps={{
                            name: "nursing",
                            id: "nursing",
                            defaultValue: medicalHealthHistory?.nursing,
                            onChange: (e: any) => handleChange(e),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <InputHeading title={" Are you taking birth control?"} />
                    <RadioSelect
                        disabled={disabledField}
                        sx={{ flexWrap: "nowrap" }}
                        options={radioButtonOptions}
                        groupProps={{
                            name: "takingBirthControl",
                            id: "takingBirthControl",
                            defaultValue: medicalHealthHistory?.takingBirthControl,
                            onChange: (e: any) => handleChange(e),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <InputHeading title={"Are you pregnant?"} />
                    <RadioSelect
                        disabled={disabledField}
                        sx={{ flexWrap: "nowrap" }}
                        options={radioButtonOptions}
                        groupProps={{
                            name: "pregnant",
                            id: "pregnant",
                            defaultValue: medicalHealthHistory?.pregnant,
                            onChange: (e: any) => handleChange(e),
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Accordion
                        className={classes.accordionRoot}
                        expanded={expanded}
                        onChange={() => setExpanded(!expanded)}
                    >
                        <AccordionSummary
                            className={`${classes.accordionSummary} ${expanded
                                ? classes.borderRadiusExpanded
                                : classes.borderRadiusAccordionSummary
                                }  ${classes.expanded}`}
                            expandIcon={
                                <FontAwesomeIcon icon={faAngleUp} className={`f-16`} />
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <InputHeading
                                titleProps={{
                                    sx: {
                                        margin: "12px 0px 12px 0px",
                                        whiteSpace: {
                                            xs: 'break-spaces',
                                        },
                                    }
                                }}
                                title={"Please Check If You Have Ever Had Any Of The Following:"}
                            />
                        </AccordionSummary>
                        <AccordionDetails
                            className={`${!expanded && classes.accordionDetailsBorderExpanded
                                } ${classes.accordionDetails}`}
                        >
                            <Grid container>
                                {getFiledData?.medicalConditionsData?.map((res: any, i: any) => (
                                    <Grid item xs={12} sm={4} lg={3} key={i}>
                                        <CheckboxSelect
                                            disabled={disabledField || disabledCheckBoxMedical(res?.id)}
                                            label={res?.name}
                                            checkBoxProps={{
                                                name: "medicalConditions",
                                                checked:
                                                    medicalHealthHistory?.medicalConditions?.includes(
                                                        res?.id
                                                    ),
                                                value: medicalHealthHistory?.medicalConditions?.includes(
                                                    res?.id
                                                ),
                                                onChange: (e: any) => checkBoxChangeOne(e, res),
                                            }}
                                        />
                                    </Grid>
                                ))}
                                <Grid item xs={12} md={8} lg={6}>
                                    <Box display={"flex"}>
                                        {/*<CheckboxSelect*/}
                                        {/*    disabled={disabledField}*/}
                                        {/*    label={"Other"}*/}
                                        {/*    checkBoxProps={{*/}
                                        {/*        name: "checkboxListOne",*/}
                                        {/*        checked:*/}
                                        {/*            medicalHealthHistory?.checkboxListOne?.includes(*/}
                                        {/*                "Other"*/}
                                        {/*            ),*/}
                                        {/*        value:*/}
                                        {/*            medicalHealthHistory?.checkboxListOne?.includes(*/}
                                        {/*                "Other"*/}
                                        {/*            ),*/}
                                        {/*        onChange: (e: any) => {*/}
                                        {/*            const updatedValue = medicalHealthHistory;*/}
                                        {/*            updatedValue.medicalConditionsOther = "";*/}
                                        {/*            dispatch(*/}
                                        {/*                updateMedicalHealthHistoryAction(updatedValue)*/}
                                        {/*            );*/}
                                        {/*            checkBoxChangeOne(e, {*/}
                                        {/*                heading: "Other",*/}
                                        {/*                value: "Other",*/}
                                        {/*            });*/}
                                        {/*        },*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                        {isOtherMedicalIncluded && (
                                            <TextBox
                                                disabled={disabledField}
                                                placeholder="Please describe"
                                                maxLength={255}
                                                resize={true}
                                                resizeVerticalScreen={true}
                                                inputProps={{
                                                    id: "medicalConditionsOther",
                                                    name: "medicalConditionsOther",
                                                    onChange: (e: any) => handleChange(e),
                                                    defaultValue:
                                                        medicalHealthHistory?.medicalConditionsOther,
                                                    error:
                                                        errors?.medicalHealthHistory
                                                            ?.medicalConditionsOther,
                                                    helperText:
                                                        errors?.medicalHealthHistory
                                                            ?.medicalConditionsOther,
                                                    fullWidth: true,
                                                    InputProps: {
                                                        classes: {
                                                            root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                                            input: "textFieldStartEndIconNumbers",
                                                        },
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position="end"
                                                                className={lengthClasses.textLengthCount}
                                                                sx={{ alignSelf: 'flex-end', paddingBottom: '15px' }}
                                                            >
                                                                {`${medicalHealthHistory?.medicalConditionsOther?.length || 0} / 255`}
                                                            </InputAdornment>
                                                        )
                                                    },
                                                    multiline: true,
                                                    ref: noteRef
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion
                        className={classes.accordionRoot}
                        expanded={expandedSecond}
                        onChange={() => setExpandedSecond(!expandedSecond)}
                    >
                        <AccordionSummary
                            className={`${classes.accordionSummary} ${expandedSecond
                                ? classes.borderRadiusExpanded
                                : classes.borderRadiusAccordionSummary
                                }  ${classes.expanded}`}
                            expandIcon={
                                <FontAwesomeIcon icon={faAngleUp} className={`f-16`} />
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <InputHeading
                                titleProps={{
                                    sx: {
                                        margin: "12px 0px 12px 0px", whiteSpace: {
                                            xs: 'break-spaces',
                                        },
                                    }
                                }}
                                title={"Are You Allergic To Any Of The Following:"}
                            />
                        </AccordionSummary>
                        <AccordionDetails
                            className={`${!expandedSecond && classes.accordionDetailsBorderExpanded
                                } ${classes.accordionDetails}`}
                        >
                            <Grid container>
                                {getFiledData?.allergiesListData?.map((res: any, i: any) => (
                                    <Grid item xs={12} sm={4} lg={3} key={i}>
                                        <CheckboxSelect
                                            disabled={disabledField || disabledCheckBoxAllergies(res?.id)}
                                            label={res?.name}
                                            checkBoxProps={{
                                                name: "checkboxListTwo",
                                                checked: medicalHealthHistory?.checkboxListTwo?.includes(res?.id),
                                                value: medicalHealthHistory?.checkboxListTwo?.includes(res?.id),
                                                onChange: (e: any) => checkBoxChangeTwo(e, res),
                                            }}
                                        />
                                    </Grid>
                                ))}
                                <Grid item xs={12} md={8} lg={6}>
                                    <Box display={"flex"} alignItems={"center"}>
                                        {isOtherAllergiesIncluded && (
                                            <TextBox
                                                label={''}
                                                labelProps={{
                                                    htmlFor: 'notes'
                                                }}
                                                resize={true}
                                                placeholder="Please describe"
                                                maxLength={255}
                                                resizeVerticalScreen={true}
                                                disabled={disabledField}
                                                // sx={{
                                                //     '& .MuiOutlinedInput-input': {
                                                //         minWidth: '100% !important',
                                                //     }
                                                // }}
                                                inputProps={{
                                                    id: "checkboxListTwoOtherInput",
                                                    name: "checkboxListTwoOtherInput",
                                                    onChange: (e: any) => handleChange(e),
                                                    defaultValue: medicalHealthHistory?.checkboxListTwoOtherInput,
                                                    error: errors?.medicalHealthHistory?.checkboxListTwoOtherInput,
                                                    helperText: errors?.medicalHealthHistory?.checkboxListTwoOtherInput,
                                                    fullWidth: true,
                                                    InputProps: {
                                                        classes: {
                                                            root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                                            input: "textFieldStartEndIconNumbers",
                                                        },
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position="end"
                                                                className={lengthClasses.textLengthCount}
                                                                sx={{ alignSelf: 'flex-end', paddingBottom: '15px' }}
                                                            >
                                                                {`${medicalHealthHistory?.checkboxListTwoOtherInput?.length || 0} / 255`}
                                                            </InputAdornment>
                                                        )
                                                    },
                                                    multiline: true,
                                                    ref: noteRef
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </CollapsibleBox>
    );
})

export default MedicalHealthHistory;
