import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { procedureColors } from "../../../../../utils/procedureHelper";
import FlippedTeeth from "./FlippedTeeth";
import MeasureScaleBg from "./MeasureScaleBg";
import OdontogramFindingList from "./OdontogramFindingList";
import OdontogramSurface from "./OdontogramSurface";
import OdontogramSync from './OdontogramSyncPms';
import OdontogramTeeth from "./OdontogramTeeth";
import ToothNumberWrapper from "./ToothNumberWrapper";
import ShowRejectedTreatments from './ShowRejectedTreatments';
import OdontogramContourFinding from './OdontogramTeeth/OdontogramContourFinding';
import ProcedureFilters from "./ProcedureFilters";
import theme from "../../../../../theme";
import { aboveTeeth, examConstantInterface, findingData, findingTypes } from "../../../../../utils/constant/examConstant";
import FullScreenLoader from "../../../../common/FullScreenLoader";

const useStyles = makeStyles((theme: any) => ({
    odontogramContainer: {
        overflowY: 'scroll',
        width: 1085,
        margin: 'auto',
        paddingTop: '45px',
    },
    odontogramWrapper: {
        width: '854px',
        margin: 'auto',
        [theme.breakpoints.down(1300)]: {
            margin: 'auto 20px'
        }
    },
    toothSectionWrapper: {
        position: 'relative',
        height: '204px',
        paddingTop: 5.5,
        marginTop: 1.75,
        width: '100%',
        justifyContent: 'start !important',
        flexDirection: 'column'
    },
    selectedTooth: {
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: '100%',
            height: '100%',
            background: `rgba(51, 51, 51, 0.05)`,
            borderRadius: '30px',
        },
    },
}));

const Odontogram = React.memo(() => {
    const classes = useStyles();
    const { palette } = theme
    const {
        odontogram: { teeth, procedureFilters, cameOdontogramFromTreatment, showRejectedTreatmentsOnly },
        examFindingReducer: { examFindingData,syncOdontogramPmsLoader },
        appointmentsReducer: { appointmentDetails },
        clinicalExamFilters: { riskFilters, findingType },
    } = useSelector((state: any) => state) as any;

    const isWellnessProfile = window.location.pathname?.includes(`/dentist/wellness-profile/`)
    const isMissingTooth = (tooth: number) => {
        const toothStr = `${tooth}`;
        let missingTeeth = []
        if (typeof examFindingData?.occlusion_exam?.missing_tooth_tooth_number === 'string') {
            missingTeeth = (examFindingData && examFindingData?.occlusion_exam?.missing_tooth_tooth_number?.split(",")) || [];
        } else {
            missingTeeth = (examFindingData && examFindingData?.occlusion_exam?.missing_tooth_tooth_number) || [];
        }

        // Check if the tooth is marked as missing
        const isToothMissing = missingTeeth?.includes(toothStr);

        // Helper function to search for a tooth in any data structure
        const isToothPresentInData = (data: any): boolean => {
            if (Array.isArray(data)) {
                return data?.some(item => {
                    if (typeof item === "object" && item?.tooth_number) {
                        if (Array.isArray(item?.tooth_number)) {
                            return item?.tooth_number.includes(toothStr);
                        }
                        return item?.tooth_number === toothStr;
                    }
                    return false;
                });
            }
            return false;
        };

        // Check all relevant sections for the presence of the tooth
        const sectionsToCheck = [
            examFindingData?.existing_treatment,
            examFindingData?.periodontal_exam
        ];

        const isToothPresent = sectionsToCheck?.some(section => {
            if (section) {
                return Object.values(section)?.some(isToothPresentInData);
            }
            return false;
        });

        // If `procedureFilters` includes only "Existing", skip the presence check and return result as is
        const hasOnlyExisting = procedureFilters?.length === 1 && procedureFilters?.includes("Existing");
        if (hasOnlyExisting) {
            return isToothMissing && ((procedureFilters?.includes("Existing") || procedureFilters?.length < 1) && !showRejectedTreatmentsOnly);
        }

        // Final condition for cases where `procedureFilters` includes other values
        return (
            isToothMissing &&
            (!isToothPresent || procedureFilters?.includes("Existing")) &&
            (procedureFilters?.length < 1 && !showRejectedTreatmentsOnly)
        );
    };

    const filteredFinding = useMemo(() => {
        let filteredData = [] as any
        const findingsWithSurfaces = ["decay", "watch", "filling", "crown", 'non_carious_cervical_lesions', 'implant']
        const findingTypeWiseFilteredData = findingData?.filter((f: examConstantInterface) =>
            f?.findingTypes?.includes(findingTypes[findingType]) && findingsWithSurfaces.includes(f?.key || '')
        ) as any;
        findingTypeWiseFilteredData?.map((res: any) => {
            if (riskFilters?.length) {
                if (res?.risks?.filter((ff: string) => riskFilters.includes(ff))?.length) {
                    filteredData.push(res?.key)
                }
            } else {
                filteredData.push(res?.key)
            }
            return true
        })
        return filteredData
        // eslint-disable-next-line
    }, [riskFilters, riskFilters?.length, findingType]);

    const riskWiseFilteredData = useMemo(() => {
        /*Filter the data only if there is at least one risk is selected else will show data without risk filters*/
        return riskFilters?.length
            ? (findingData?.filter((f: examConstantInterface) => f?.risks?.filter((ff: string) => riskFilters.includes(ff))?.length) as any)
            : findingData
        // eslint-disable-next-line
    }, [riskFilters, riskFilters?.length, findingData]);


    const displayOnlyPlannedFindingForTreatment = (finding: any) => {
        return cameOdontogramFromTreatment ? finding['procedure_status'] === 'Planned' : true
    }

    const isContour = useCallback((tooth: any, isBottomTeeth: boolean) => {
        const toothNumber = isBottomTeeth ? tooth + 20 : tooth;
        let hasTooth = false;
        let contoursSubType = null;
        let color = null;
        let toothSite = null
        const currentFindingData = riskWiseFilteredData?.find((f: any) => f?.key === 'contour') || {} as any
        const updatedFindingData = currentFindingData && examFindingData?.[currentFindingData?.examGroup]?.['contour'];

        if (updatedFindingData?.length === 1) {
            const contourData = updatedFindingData[0];
            const isTooth = contourData['tooth_number'];
            const isSubType = contourData['subtype'];
            const procedure_status = contourData['procedure_status'];
            const isRejected = contourData['rejected'];
            const isToothSite = contourData['tooth_site'];
            if (displayOnlyPlannedFindingForTreatment(contourData) &&
                (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${toothNumber}`) &&
                isSubType
            ) {
                hasTooth = true;
                contoursSubType = isSubType;
                color = procedureColors[procedure_status]?.color;
                toothSite = isToothSite
            }
        } else if (updatedFindingData) {
            updatedFindingData?.forEach((contourData: any) => {
                const isTooth = contourData['tooth_number'];
                const isSubType = contourData['subtype'];
                const procedure_status = contourData['procedure_status'];
                const isRejected = contourData['rejected'];
                const isToothSite = contourData['tooth_site'];
                if (displayOnlyPlannedFindingForTreatment(contourData) &&
                    (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                    (!showRejectedTreatmentsOnly || isRejected) &&
                    isTooth?.includes(`${toothNumber}`) &&
                    isSubType
                ) {
                    hasTooth = true;
                    contoursSubType = isSubType;
                    color = procedureColors[procedure_status]?.color;
                    toothSite = isToothSite
                }
            });
        }

        return {
            hasTooth,
            contoursSubType,
            color,
            toothSite
        };
        // eslint-disable-next-line
    }, [examFindingData, procedureFilters, showRejectedTreatmentsOnly, riskWiseFilteredData]);
    return (
        <Box className='d-flex pos-relative'>
            {syncOdontogramPmsLoader && <FullScreenLoader/>}
            {!cameOdontogramFromTreatment && <ProcedureFilters />}
            <OdontogramFindingList />

            <Box className={classes.odontogramContainer}>
                {(appointmentDetails?.pms_appointment_no !== null || appointmentDetails?.patient?.pms_id !== null) && !isWellnessProfile &&
                    <OdontogramSync />
                }
                <Box className={classes.odontogramWrapper}>
                    {/*Upside*/}
                    <Grid container className='w-100'>
                        {aboveTeeth?.map((tooth: number, index: number) => <Grid item xs={0.75} md={0.75} key={index} className={`d-flex-all-center`}>
                                <Box className='w-100'>
                                    <ToothNumberWrapper tooth={tooth} />
                                    {/*Tooth section start*/}
                                    <Box
                                        className={`${classes.toothSectionWrapper} d-flex-all-center ${teeth.includes(tooth) ? classes.selectedTooth : ''}`}
                                        sx={{
                                            borderTop: `0.122819px solid #8b8b8a66`,
                                            borderRight: index === 7 ? `1px solid ${palette.grey.lightBorderGray}` : `0.122819px solid #8b8b8a66`,
                                            borderBottom: `0.5px solid ${palette.grey.lightBorderGray}`,
                                            borderLeft: index === 0 ? `0.122819px solid #8b8b8a66` : '',
                                        }}
                                    >
                                        {isContour(tooth, false)?.hasTooth &&
                                            <OdontogramContourFinding isContour={isContour(tooth, false)} />
                                        }

                                        {/* measureScale bg */}
                                        <MeasureScaleBg />
                                        {
                                            !isMissingTooth(tooth) && <React.Fragment>
                                                <OdontogramTeeth tooth={tooth} />
                                                <OdontogramSurface tooth={tooth} filteredFinding={filteredFinding} />
                                                <FlippedTeeth tooth={tooth} />
                                            </React.Fragment>
                                        }
                                    </Box>
                                    {/*Tooth section end*/}
                                </Box>
                            </Grid>)
                        }
                    </Grid>

                    {/*Downside*/}
                    <Grid container className='w-100' sx={{ transform: 'rotate(180deg)' }}>
                        {aboveTeeth?.map((tooth: number, index: any) =>
                            <Grid item xs={0.75} md={0.75} key={index} className={`d-flex-all-center`}>
                                <Box className='w-100'>
                                    <Box sx={{ transform: 'rotate(-180deg)' }}>
                                        <ToothNumberWrapper tooth={tooth + 20} />
                                    </Box>
                                    {/*Tooth section start*/}
                                    <Box
                                        className={`${classes.toothSectionWrapper} d-flex-all-center ${teeth.includes(tooth + 20) ? classes.selectedTooth : ''}`}
                                        sx={{
                                            borderTop: `0.122819px solid #8b8b8a66`,
                                            borderLeft: index === 8 ? `1px solid ${palette.grey.lightBorderGray}` : `0.122819px solid #8b8b8a66`,
                                            borderRight: index === 15 ? `0.122819px solid #8b8b8a66` : '',
                                        }}
                                    >
                                        {isContour(tooth, true)?.hasTooth &&
                                            <OdontogramContourFinding isContour={isContour(tooth, true)} />
                                        }
                                        {/* measureScale bg */}
                                        <MeasureScaleBg />
                                        {
                                            !isMissingTooth(tooth + 20) && <React.Fragment>
                                                <OdontogramTeeth tooth={tooth} isBottomTeeth />
                                                <OdontogramSurface tooth={tooth} isBottomTeeth filteredFinding={filteredFinding} />
                                                <FlippedTeeth tooth={tooth} isBottomTeeth />
                                            </React.Fragment>
                                        }
                                    </Box>
                                    {/*Tooth section end*/}
                                </Box>
                            </Grid>)
                        }
                    </Grid>
                </Box>
            </Box>

            {/* Show rejected treatments switch*/}
            <ShowRejectedTreatments />
        </Box>
    );
})

export default Odontogram;
