import {exam, limitedExam} from '../../../../../utils/constant/clinicalExamConstant';
import {
    ApiEndpointVersion,
    dentistProfileData,
    getLocalStorageItem,
    removeRefFromJson
} from '../../../../../utils/helper';
import {
    getOdontogramTreatmentDataAction
} from '../../../../actions/dentist/clinicalExam/treatment/proposedTreatments/oraqProposedAction';
import {isArray} from "lodash";
import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import API from '../../../../../utils/api';
import {
    convertMultiSelectArrayToString,
    convertMultiSelectStringToArray,
    errorToaster,
    removeNullValue,
    successToaster
} from '../../../../../utils/helper';
import {
    getRiskFilterScoreRequestAction,
    loadingScreenTimingAction,
    updateAppointmentStepAction,
    updateFindingTypeAction
} from '../../../../actions/dentist/clinicalExam/clinicalExamFilterAction';
import {
    addExistingFindingsDataAction,
    checkedExistingFindingsDataAction,
    failureExamAPIDataAction,
    failurePutExamAPIDataAction,
    getLimitedExamRequestAction,
    getOdontogramOtherFindingRequestFailureAction,
    getOdontogramOtherFindingRequestSuccessAction,
    getOdontogramRequestAction,
    getOtherFormFindingRequestAction,
    getPeriogramRequestAction,
    setExamDataLoader,
    successExamAPIDataAction,
    syncOdontogramPmsDataRequestFailureAction,
    syncOdontogramPmsDataRequestSuccessAction,
    syncPeriogramPmsDataRequestFailureAction,
    syncPeriogramPmsDataRequestSuccessAction,
    updateExamAPIDataAction,
} from '../../../../actions/dentist/clinicalExam/exam/examFindingsTypesAction';
import {handleOdontogramComingAction} from "../../../../actions/dentist/clinicalExam/odontogramAction";
import {
    GET_EXAM_REQUEST,
    GET_HARD_TISSUE_REQUEST,
    GET_LIMITED_EXAM_REQUEST, GET_ODONTOGRAM_OTHER_FINDINGS_REQUEST,
    GET_ODONTOGRAM_REQUEST,
    GET_OTHER_FORM_FINDING_REQUEST,
    GET_PERIOGRAM_REQUEST,
    GET_SOFT_TISSUE_REQUEST, PUT_EXAM_REQUEST,
    PUT_HARD_TISSUE_REQUEST,
    PUT_LIMITED_EXAM_REQUEST,
    PUT_ODONTOGRAM_REQUEST,
    PUT_OTHER_FORM_FINDING_REQUEST,
    PUT_PERIOGRAM_REQUEST,
    PUT_SOFT_TISSUE_REQUEST,
    SYNC_ODONTOGRAM_PMS_DATA_REQUEST,
    SYNC_PERIOGRAM_PMS_DATA_REQUEST
} from '../../../../actionTypes/dentist/clinicalExam/exams/examFindingsTypes';
import {RootState} from '../../../../reducers/rootReducer';
import {
    hardTissue,
    medical,
    odontogram,
    periogram,
    softTissue,
    xray
} from '../../../../../utils/constant/clinicalExamConstant';
import {examGroupsData, findingData, LE} from '../../../../../utils/constant/examConstant';
import {appointmentDetailsRequestAction} from "../../../../actions/dentist/appointmentsAction";
import wellnessProfile from "../../../../../pages/dentist/WellnessProfile";


function* softTissueRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/${ApiEndpointVersion}/exam/soft/tissue?patient_id=${payload.payload?.patient_id}`)
        if (response) {
            yield put(setExamDataLoader(true))
        }
        const data = {
            ...response?.data?.detail
        }
        delete data['appointment_id']
        delete data['appointment_type']
        yield put(
            updateExamAPIDataAction(
                convertMultiSelectStringToArray(data)
            )
        )
    } catch (e: any) {
        yield put(failureExamAPIDataAction())
    }
}

function* putSoftTissueRequestSaga(payload: any): any {
    const {
        examFindingReducer: {examFindingData, examDataFromAPI},
    } = yield select((state: RootState) => state) as any
    const isWellnessProfile = window.location.pathname.includes('/dentist/wellness-profile/')

    let payloadData = {} as any;
    if (isWellnessProfile && payload?.payload?.patient_id) {
        payloadData = {
            patient_id: payload?.payload?.patient_id || undefined,
            profile_id: dentistProfileData?.id || undefined,
            ...convertMultiSelectArrayToString(examFindingData)
        }
    } else {
        payloadData = {
            // appointment_id: payload?.payload?.appointment_id || null,
            profile_id: dentistProfileData?.id || undefined,
            ...convertMultiSelectArrayToString(examFindingData)
        }
    }
    if (payload?.payload?.submit) {
        payloadData['submit'] = payload?.payload?.submit
    }
    //TODO: Need optimisations
    if (examDataFromAPI) {
        Object.keys(examDataFromAPI)?.map((eg: string) => {
            if (examDataFromAPI?.[eg] && examDataFromAPI?.[eg] !== null && examDataFromAPI?.[eg] !== undefined) {
                Object.keys(examDataFromAPI?.[eg])?.forEach((f: string) => {
                    if (examDataFromAPI?.[eg]?.[f] && examDataFromAPI?.[eg]?.[f] !== null && examDataFromAPI?.[eg]?.[f] !== undefined) {
                        if (isArray(examDataFromAPI?.[eg]?.[f])) {
                            examDataFromAPI?.[eg]?.[f]?.forEach((res: any) => {
                                //Find updated find start
                                if (payloadData && payloadData?.[eg] && payloadData?.[eg]?.[f] && payloadData?.[eg]?.[f] !== null && payloadData?.[eg]?.[f] !== undefined) {
                                    const newRes = res as any
                                    if (res?.tooth_surface) {
                                        newRes.tooth_surface = res?.tooth_surface?.toString()
                                    }
                                    if (res?.tooth_number) {
                                        newRes.tooth_number = res?.tooth_number?.toString()
                                    }
                                    let dynamicIndex = payloadData?.[eg]?.[f] ? payloadData?.[eg]?.[f]?.findIndex((item: any) => item?.ID === res?.ID) : -1;

                                    if (dynamicIndex >= 0 && JSON.stringify(newRes) !== JSON.stringify(payloadData?.[eg]?.[f]?.[dynamicIndex])) {
                                        payloadData[eg][f][dynamicIndex].flag = 'edit'
                                    }
                                }
                                //Find updated find start
                                //Find Deleted finding start
                                if ((payloadData && payloadData?.[eg] && !payloadData?.[eg]?.[f]) || (payloadData?.[eg] && payloadData?.[eg]?.[f] && payloadData?.[eg]?.[f]?.length < 1) || (payloadData?.[eg] && payloadData?.[eg]?.[f] && !payloadData?.[eg]?.[f]?.map((k: any) => k?.ID)?.includes(res?.ID))) {
                                    //Deleted Finding
                                    if (!payloadData?.[eg]?.[f]) {
                                        payloadData[eg][f] = [{
                                            ID: res?.ID,
                                            flag: "delete"
                                        }]
                                    } else {
                                        payloadData?.[eg]?.[f].push({
                                            ID: res?.ID,
                                            flag: "delete"
                                        })
                                    }
                                }
                                //Find Deleted finding end
                            })
                        } else {
                            //non array value
                        }
                    }
                })
            }
            return true
        })
    }
    if (payloadData) {
        Object.keys(payloadData)?.forEach((eg: string) => {
            if (payloadData?.[eg] && typeof payloadData?.[eg] !== 'string' && payloadData?.[eg] !== null && payloadData?.[eg] !== undefined) {
                Object.keys(payloadData?.[eg]) && Object.keys(payloadData?.[eg])?.forEach((f: string) => {
                    if (payloadData?.[eg]?.[f] && payloadData?.[eg]?.[f] !== null && payloadData?.[eg]?.[f] !== undefined) {
                        if (isArray(payloadData?.[eg]?.[f])) {
                            if (payloadData?.[eg]?.[f]?.filter((f: any) => !f?.ID || (f?.ID && f?.flag?.length > 0))) {
                                payloadData[eg][f] = payloadData?.[eg]?.[f]?.filter((f: any) => !f?.ID || (f?.ID && f?.flag?.length > 0))
                            }
                        }
                    }
                })
            }
        })
    }

    try {
        const response = yield API.put(`/${ApiEndpointVersion}/exam/soft/tissue`, removeNullValue(payloadData))
        yield put(successExamAPIDataAction())

        if (!isWellnessProfile) {
            yield put(getRiskFilterScoreRequestAction({
                appointment_id: payload?.payload?.appointment_id,
                isPusher: true
            }));
        }

        if (payload?.payload.callback) {
            yield call(payload?.payload?.callback)
        }
        if (payload?.payload?.submit && !isWellnessProfile) {
            const step = 2 as any
            yield put(updateAppointmentStepAction(step));
            yield put(appointmentDetailsRequestAction({appointment_id: payload?.payload?.appointment_id,}));
            yield put(loadingScreenTimingAction(true));
        } else {
            successToaster(response?.data?.meta?.message)
            if (payload?.payload?.next) {
                yield put(updateFindingTypeAction(hardTissue))
            } else if (payload?.payload?.prev) {
                yield put(updateFindingTypeAction(xray))
            }
        }
    } catch (e: any) {
        yield put(failurePutExamAPIDataAction())
        errorToaster(e?.meta?.message)
    }
}

function* hardTissueRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/${ApiEndpointVersion}/exam/hard/tissue?patient_id=${payload.payload?.patient_id}`)
        if (response) {
            yield put(setExamDataLoader(true))
        }
        const data = {
            ...response?.data?.detail
        }
        delete data['appointment_id']
        delete data['appointment_type']

        yield put(
            updateExamAPIDataAction(
                convertMultiSelectStringToArray(data)
            )
        )
    } catch (e: any) {
        yield put(failureExamAPIDataAction())
    }
}


function* putHardTissueRequestSaga(payload: any): any {
    const {examFindingData, examDataFromAPI,} = yield select((state: RootState) => state?.examFindingReducer) as any
    const isWellnessProfile = window.location.pathname.includes('/dentist/wellness-profile/')

    let payloadData = {} as any;
    if (isWellnessProfile && payload?.payload?.patient_id) {
        payloadData = {
            patient_id: payload?.payload?.patient_id,
            profile_id: dentistProfileData?.id,
            ...convertMultiSelectArrayToString(examFindingData)
        }
    } else {
        payloadData = {
            // appointment_id: payload?.payload?.appointment_id || null,
            profile_id: dentistProfileData?.id,
            ...convertMultiSelectArrayToString(examFindingData)
        }
    }
    if (payload?.payload?.submit) {
        payloadData['submit'] = payload?.payload?.submit
    }
    //TODO: Need optimisations
    if (examDataFromAPI) {
        Object.keys(examDataFromAPI)?.map((eg: string) => {
            if (examDataFromAPI?.[eg] && examDataFromAPI?.[eg] !== null && examDataFromAPI?.[eg] !== undefined) {
                Object.keys(examDataFromAPI[eg])?.forEach((f: string) => {
                    if (examDataFromAPI?.[eg]?.[f] && examDataFromAPI?.[eg]?.[f] !== null && examDataFromAPI?.[eg]?.[f] !== undefined) {
                        if (isArray(examDataFromAPI?.[eg]?.[f])) {
                            examDataFromAPI?.[eg]?.[f]?.forEach((res: any) => {
                                //Find updated find start
                                if (payloadData && payloadData?.[eg] && payloadData?.[eg]?.[f]) {
                                    const newRes = res as any
                                    if (res?.tooth_surface) {
                                        newRes.tooth_surface = res?.tooth_surface?.toString()
                                    }
                                    if (res?.tooth_number) {
                                        newRes.tooth_number = res?.tooth_number?.toString()
                                    }
                                    let dynamicIndex = payloadData?.[eg]?.[f] ? payloadData?.[eg]?.[f]?.findIndex((item: any) => item?.ID === res?.ID) : -1;
                                    if (dynamicIndex >= 0 && JSON.stringify(newRes) !== JSON.stringify(payloadData?.[eg]?.[f]?.[dynamicIndex])) {
                                        payloadData[eg][f][dynamicIndex].flag = 'edit'
                                    }
                                }
                                //Find updated find start
                                //Find Deleted finding start
                                if (
                                    payloadData &&
                                    payloadData?.[eg] &&
                                    (
                                        !payloadData?.[eg]?.[f] ||
                                        (payloadData?.[eg] && payloadData?.[eg]?.[f] && payloadData?.[eg]?.[f]?.length < 1) ||
                                        !payloadData?.[eg]?.[f]?.map((k: any) => k?.ID)?.includes(res?.ID)
                                    )
                                ) {
                                    //Deleted Finding
                                    if (!payloadData?.[eg]?.[f]) {
                                        payloadData[eg][f] = [{
                                            ID: res?.ID,
                                            flag: "delete"
                                        }]
                                    } else {
                                        payloadData?.[eg]?.[f].push({
                                            ID: res?.ID,
                                            flag: "delete"
                                        })
                                    }
                                }
                                //Find Deleted finding end
                            })
                        } else {
                            //non array value
                        }
                    }
                })
            }
            return true
        })
    }
    try {
        const response = yield API.post(`/${ApiEndpointVersion}/exam/hard/tissue`, removeNullValue(payloadData))
        yield put(successExamAPIDataAction())

        if (!isWellnessProfile) {
            yield put(getRiskFilterScoreRequestAction({
                appointment_id: payload?.payload?.appointment_id,
                isPusher: true
            }));
        }
        if (payload?.payload.callback) {
            yield call(payload?.payload?.callback)
        }
        if (payload?.payload?.submit && !wellnessProfile) {
            const step = 2 as any
            yield put(updateAppointmentStepAction(step));
            yield put(appointmentDetailsRequestAction({appointment_id: payload?.payload?.appointment_id,}));
            yield put(loadingScreenTimingAction(true))
        } else {
            successToaster(response?.data?.meta?.message)
            if (payload?.payload?.next) {
                yield put(updateFindingTypeAction(odontogram))
            } else if (payload?.payload?.prev) {
                yield put(updateFindingTypeAction(softTissue))
            }
        }
    } catch (e: any) {

        yield put(failurePutExamAPIDataAction())
        errorToaster(e?.meta?.message)
    }

}

function* odontogramRequestSaga(payload: any): any {
    const isWellnessProfile = window.location.pathname.includes('/dentist/wellness-profile/')

    try {
        const urlForWellnessProfile = `/${ApiEndpointVersion}/exam/odontogram?patient_id=${payload.payload?.patient_id}`
        const urlForClinicalExam = `/${ApiEndpointVersion}/exam/odontogram?appointment_id=${payload.payload?.appointment_id}`
        const response = yield API.get(isWellnessProfile ? urlForWellnessProfile : urlForClinicalExam)
        if (response) {
            yield put(setExamDataLoader(true))
        }
        const data = {
            ...response?.data?.detail
        }
        delete data['appointment_id']
        delete data['appointment_type']
        const processNcclTypes = () => {
            const updatedPayload = {...data} as any
            const {non_carious_cervical_lesions} = updatedPayload?.periodontal_exam;
            if (non_carious_cervical_lesions && Array.isArray(non_carious_cervical_lesions)) {
                non_carious_cervical_lesions?.forEach((ncclFelids: any) => {
                    const ncclTypes = [];
                    if (ncclFelids?.erosion) {
                        ncclTypes.push('Erosion');
                    }
                    if (ncclFelids?.attrition) {
                        ncclTypes.push('Attrition');
                    }
                    if (ncclFelids?.is_dentin_exposed) {
                        ncclTypes.push('Is Dentin Exposed');
                    }
                    if (ncclFelids?.abrasion) {
                        ncclTypes.push('Abrasion');
                    }
                    if (ncclFelids?.abfraction) {
                        ncclTypes.push('Abfraction');
                    }
                    delete ncclFelids.erosion;
                    delete ncclFelids.attrition;
                    delete ncclFelids.is_dentin_exposed;
                    delete ncclFelids.abrasion;
                    delete ncclFelids.abfraction;

                    if (ncclTypes.length > 0) {
                        ncclFelids.ncclTypes = ncclTypes;
                    }
                });
            }
            return updatedPayload;
        };

        const processedPayload = processNcclTypes();
        yield put(
            updateExamAPIDataAction(
                convertMultiSelectStringToArray(processedPayload)
            )
        )

        if (response?.data?.detail) {
            const selectedFindings = [] as any
            Object.keys(response?.data?.detail['existing_treatment'])?.map((res: any) => {
                if (response?.data?.detail['existing_treatment'] && response?.data?.detail['existing_treatment'][res] !== null) {
                    selectedFindings.push(res)
                }
                return true
            })
            yield put(addExistingFindingsDataAction(removeRefFromJson(selectedFindings)))
            yield put(checkedExistingFindingsDataAction(removeRefFromJson(selectedFindings)))
        }
    } catch (e: any) {
        yield put(failureExamAPIDataAction())
    }
}

function* putOdontogramRequestSaga(payload: any): any {
    const {
        examFindingReducer: {
            addExistingFindingData,
            examFindingData,
            examDataFromAPI
        },
        appointmentsReducer: {appointmentDetails}
    } = yield select((state: RootState) => state) as any
    const isWellnessProfile = window.location.pathname.includes('/dentist/wellness-profile/')

    const filteredExistingData = (res: any) => {
        let filteredFinding = {} as any
        if (addExistingFindingData?.length && res) {
            Object.keys(res)?.filter((finding_key: any) => addExistingFindingData?.includes(finding_key))?.map((finding: any) =>
                filteredFinding[finding] = res?.[finding]
            )
            return filteredFinding
        }
        return filteredFinding
    }
    let payloadData = {} as any;
    if (isWellnessProfile && payload?.payload?.patient_id) {
        payloadData = {
            patient_id: payload?.payload?.patient_id,
            profile_id: dentistProfileData?.id,
            ...convertMultiSelectArrayToString({
                ...examFindingData,
                existing_treatment: {...filteredExistingData(examFindingData['existing_treatment'])}
            })
        }
    } else {
        payloadData = {
            treatment_planning: payload?.payload?.treatment_planning,
            appointment_id: payload?.payload?.appointment_id || null,
            profile_id: dentistProfileData?.id,
            ...convertMultiSelectArrayToString({
                ...examFindingData,
                existing_treatment: {...filteredExistingData(examFindingData['existing_treatment'])}
            })
        }
    }

    // const payloadData = {
    //     treatment_planning: payload?.payload?.treatment_planning,
    //     appointment_id: payload?.payload?.appointment_id,
    //     profile_id: dentistProfileData?.id,
    //     ...convertMultiSelectArrayToString({
    //         ...examFindingData,
    //         existing_treatment: {...filteredExistingData(examFindingData['existing_treatment'])}
    //     })
    // } as any

    if (payload?.payload?.submit) {
        payloadData['submit'] = payload?.payload?.submit
    }
    //TODO: Need optimizations
    if (examDataFromAPI) {
        Object.keys(examDataFromAPI)?.map((eg: string) => {
            if (examDataFromAPI && examDataFromAPI?.[eg] && examDataFromAPI?.[eg] !== null && examDataFromAPI?.[eg] !== undefined) {
                Object.keys(examDataFromAPI?.[eg])?.forEach((f: string) => {
                    if (examDataFromAPI?.[eg]?.[f] && examDataFromAPI?.[eg]?.[f] !== null && examDataFromAPI?.[eg]?.[f] !== undefined) {
                        if (isArray(examDataFromAPI?.[eg]?.[f])) {
                            examDataFromAPI?.[eg]?.[f]?.forEach((res: any) => {
                                //Find updated find start
                                if (payloadData && payloadData?.[eg] && payloadData?.[eg]?.[f]) {
                                    const newRes = res as any
                                    if (res?.tooth_surface) {
                                        newRes.tooth_surface = res?.tooth_surface?.toString()
                                    }
                                    if (res?.tooth_number) {
                                        newRes.tooth_number = res?.tooth_number?.toString()
                                    }
                                    let dynamicIndex = payloadData?.[eg]?.[f] ? payloadData?.[eg]?.[f]?.findIndex((item: any) => item?.ID === res?.ID) : -1;
                                    if (dynamicIndex >= 0 && JSON.stringify(newRes) !== JSON.stringify(payloadData?.[eg]?.[f]?.[dynamicIndex])) {
                                        payloadData[eg][f][dynamicIndex].flag = 'edit'
                                    }
                                }
                                //Find updated find start

                                //Find Deleted finding start
                                if (
                                    payloadData &&
                                    payloadData?.[eg] &&
                                    (
                                        !payloadData?.[eg]?.[f] ||
                                        (payloadData?.[eg] && payloadData?.[eg]?.[f] && payloadData?.[eg]?.[f]?.length < 1) ||
                                        !payloadData?.[eg]?.[f]?.map((k: any) => k?.ID)?.includes(res?.ID)
                                    )) {
                                    //Deleted Finding
                                    if (!payloadData?.[eg]?.[f]) {
                                        payloadData[eg][f] = [{
                                            ID: res?.ID,
                                            flag: "delete"
                                        }]
                                    } else {
                                        payloadData?.[eg]?.[f].push({
                                            ID: res?.ID,
                                            flag: "delete"
                                        })
                                    }
                                }
                                //Find Deleted finding end
                            })
                        } else {
                            //non array value
                        }
                    }
                })
            }
            return true
        })
    }
    if (payloadData) {
        Object.keys(payloadData)?.forEach((eg: string) => {
            if (payloadData?.[eg] && typeof payloadData?.[eg] !== 'string') {
                Object.keys(payloadData?.[eg]) && Object.keys(payloadData?.[eg])?.forEach((f: string) => {
                    if (payloadData?.[eg]?.[f] && payloadData?.[eg]?.[f] !== null && payloadData?.[eg]?.[f] !== undefined) {
                        if (isArray(payloadData?.[eg]?.[f])) {
                            payloadData[eg][f] = payloadData?.[eg]?.[f]?.filter((f: any) => !f?.ID || (f?.ID && f?.flag?.length > 0))
                        }
                    }
                })
            }
        })
    }
    // added erosion Attrition and Is Dentin Exposed as a separate filed using ncclTypes
    const processNcclTypes = () => {
        const updatedPayload = {...payloadData};
        if (updatedPayload && updatedPayload?.periodontal_exam) {
            const {non_carious_cervical_lesions} = updatedPayload?.periodontal_exam;
            if (non_carious_cervical_lesions && Array.isArray(non_carious_cervical_lesions)) {
                non_carious_cervical_lesions?.forEach((ncclOption: any) => {
                    const {ncclTypes} = ncclOption;
                    if (ncclTypes) {
                        ncclOption.erosion = ncclOption?.ncclTypes?.includes("Erosion") ? true : null;
                        ncclOption.attrition = ncclOption?.ncclTypes?.includes("Attrition") ? true : null;
                        ncclOption.is_dentin_exposed = ncclOption?.ncclTypes?.includes("Is Dentin Exposed") ? true : null;
                        ncclOption.abrasion = ncclOption?.ncclTypes?.includes("Abrasion") ? true : null;
                        ncclOption.abfraction = ncclOption?.ncclTypes?.includes("Abfraction") ? true : null;
                        delete ncclOption?.ncclTypes;
                    }
                });
            }
        }

        return updatedPayload;
    };
    const processedPayload = processNcclTypes();
    try {
        const response = yield API.put(`/${ApiEndpointVersion}/exam/odontogram`, removeNullValue(processedPayload))
        yield put(successExamAPIDataAction())
        if (payload?.payload.callback) {
            yield call(payload?.payload?.callback)
        }
        if (payloadData?.treatment_planning) {
            const step = 3 as any;
            yield put(handleOdontogramComingAction(false));
            yield put(updateAppointmentStepAction(step));
        }

        if (payload.payload?.isCallGetAPI) {
            yield put(getOdontogramRequestAction({
                appointment_id: payload?.payload?.appointment_id,
            }));
        }
        if (!isWellnessProfile) {
            yield put(getRiskFilterScoreRequestAction({
                appointment_id: payload?.payload?.appointment_id,
                isPusher: true
            }));
        }
        if (payload?.payload?.submit && !payloadData?.treatment_planning && !isWellnessProfile) {
            const step = 2 as any
            yield put(updateAppointmentStepAction(step));
            yield put(appointmentDetailsRequestAction({appointment_id: payload?.payload?.appointment_id,}));
            yield put(loadingScreenTimingAction(true))
        } else {
            successToaster(response?.data?.meta?.message)
            if (payload?.payload?.next) {
                yield put(updateFindingTypeAction(periogram))
            } else if (payload?.payload?.prev) {
                yield put(updateFindingTypeAction(appointmentDetails?.appointmentType === LE ? xray : exam))
            }
        }

    } catch (e: any) {
        yield put(failurePutExamAPIDataAction())
        errorToaster(e?.meta?.message)
    }
}

function* periogramRequestSaga(payload: any): any {
    try {
        const isWellnessProfile = window.location.pathname.includes('/dentist/wellness-profile/')
        const urlForWellnessProfile = `/${ApiEndpointVersion}/exam/periogram?patient_id=${payload.payload?.patient_id}`
        const urlForClinicalExam = `/${ApiEndpointVersion}/exam/periogram?appointment_id=${payload.payload?.appointment_id}`
        const response = yield API.get(isWellnessProfile ? urlForWellnessProfile : urlForClinicalExam)
        if (response) {
            yield put(setExamDataLoader(true))
        }
        const data = {
            ...response?.data?.detail
        }
        delete data['appointment_id']
        delete data['appointment_type']
        yield put(
            updateExamAPIDataAction(
                convertMultiSelectStringToArray(data)
            )
        )
    } catch (e: any) {
        yield put(failureExamAPIDataAction())
    }
}

function* putPeriogramRequestSaga(payload: any): any {
    const {examFindingData, examDataFromAPI} = yield select((state: RootState) => state?.examFindingReducer) as any
    const {appointmentDetails} = yield select((state: RootState) => state?.appointmentsReducer) as any
    const isWellnessProfile = window.location.pathname.includes('/dentist/wellness-profile/')
    let payloadData = {} as any;
    if (isWellnessProfile) {
        payloadData = {
            patient_id: payload?.payload?.patient_id,
            profile_id: dentistProfileData?.id,
            ...convertMultiSelectArrayToString(examFindingData)
        }
    } else {
        payloadData = {
            appointment_id: payload?.payload?.appointment_id || null,
            profile_id: dentistProfileData?.id,
            ...convertMultiSelectArrayToString(examFindingData)
        }
    }
    if (payload?.payload?.submit) {
        payloadData['submit'] = payload?.payload?.submit
    }

    //TODO: Need optimisations
    if (examDataFromAPI) {
        Object.keys(examDataFromAPI)?.map((eg: string) => {
            if (examDataFromAPI?.[eg] && examDataFromAPI?.[eg] !== null && examDataFromAPI?.[eg] !== undefined) {
                Object.keys(examDataFromAPI?.[eg])?.filter((finding: string) => finding !== 'non_carious_cervical_lesions')?.forEach((f: string) => {
                    if (examDataFromAPI?.[eg]?.[f] && examDataFromAPI?.[eg]?.[f] !== null && examDataFromAPI?.[eg]?.[f] !== undefined) {
                        if (isArray(examDataFromAPI?.[eg]?.[f])) {
                            examDataFromAPI?.[eg]?.[f]?.forEach((res: any) => {
                                //Find updated find start
                                if (payloadData && payloadData?.[eg] && payloadData?.[eg]?.[f]) {
                                    const newRes = res as any
                                    if (res?.tooth_surface) {
                                        newRes.tooth_surface = res?.tooth_surface?.toString()
                                    }
                                    if (res?.tooth_number) {
                                        newRes.tooth_number = res?.tooth_number?.toString()
                                    }

                                    let dynamicIndex = payloadData?.[eg]?.[f] ? payloadData?.[eg]?.[f]?.findIndex((item: any) => item?.ID === res?.ID) : -1;

                                    if (dynamicIndex >= 0 && JSON.stringify(newRes) !== JSON.stringify(payloadData?.[eg]?.[f]?.[dynamicIndex])) {
                                        payloadData[eg][f][dynamicIndex].flag = 'edit'
                                    }
                                }
                                //Find updated find start

                                //Find Deleted finding start
                                if (
                                    payloadData &&
                                    payloadData?.[eg] &&
                                    (
                                        !payloadData?.[eg]?.[f] ||
                                        (payloadData?.[eg] && payloadData?.[eg]?.[f] && payloadData?.[eg]?.[f]?.length < 1) ||
                                        !payloadData?.[eg]?.[f]?.map((k: any) => k?.ID)?.includes(res?.ID)
                                    )) {
                                    //Deleted Finding
                                    if (!payloadData?.[eg]?.[f]) {
                                        payloadData[eg][f] = [{
                                            ID: res?.ID,
                                            flag: "delete"
                                        }]
                                    } else {
                                        payloadData?.[eg]?.[f].push({
                                            ID: res?.ID,
                                            flag: "delete"
                                        })
                                    }
                                }
                                //Find Deleted finding end

                            })
                        } else {
                            //non array value
                        }
                    }
                })
            }
            return true
        })
    }
    if (payloadData) {
        Object.keys(payloadData)?.forEach((eg: string) => {
            if (payloadData?.[eg] && typeof payloadData?.[eg] !== 'string') {
                Object.keys(payloadData?.[eg]) && Object.keys(payloadData?.[eg])?.forEach((f: string) => {
                    if (payloadData?.[eg]?.[f] && payloadData?.[eg]?.[f] !== null && payloadData?.[eg]?.[f] !== undefined) {
                        if (isArray(payloadData?.[eg]?.[f])) {
                            payloadData[eg][f] = payloadData?.[eg]?.[f]?.filter((f: any) => !f.ID || (f?.ID && f?.flag?.length > 0))
                        }
                    }
                })
            }
        })
    }
    try {
        const response = yield API.post(`/${ApiEndpointVersion}/exam/periogram`, removeNullValue(payloadData))
        if (payload?.payload.callback) {
            yield call(payload?.payload?.callback)
        }
        if (payload?.payload?.submit && !isWellnessProfile) {
            const step = 2 as any
            yield put(loadingScreenTimingAction(true))
            yield put(updateAppointmentStepAction(step));
            yield put(appointmentDetailsRequestAction({appointment_id: payload?.payload?.appointment_id,}));
        } else {
            successToaster(response?.data?.meta?.message)
            if (payload.payload?.isCallGetAPI) {
                yield put(getPeriogramRequestAction({
                    appointment_id: payload?.payload?.appointment_id,
                }));
            }
            if (!isWellnessProfile) {
                yield put(getRiskFilterScoreRequestAction({
                    appointment_id: payload?.payload?.appointment_id,
                    isPusher: true
                }));
            }
            if (payload?.payload?.next) {
                if (appointmentDetails?.appointmentType === LE) {
                    yield put(updateFindingTypeAction(limitedExam))
                } else {
                    const step = 2 as any
                    yield put(updateAppointmentStepAction(step));
                }
            } else if (payload?.payload?.prev) {
                yield put(updateFindingTypeAction(odontogram))
            }
        }
        yield put(successExamAPIDataAction())
    } catch (e: any) {
        yield put(failurePutExamAPIDataAction())
        errorToaster(e?.meta?.message)
    }
}

function* otherFormFindingRequestSaga(payload: any): any {
    try {
        const isWellnessProfile = window.location.pathname.includes('/dentist/wellness-profile/')
        const urlForWellnessProfile = `/${ApiEndpointVersion}/exam/miscellaneous?patient_id=${payload.payload?.patient_id}`
        const urlForClinicalExam = `/${ApiEndpointVersion}/exam/miscellaneous?appointment_id=${payload.payload?.appointment_id}`
        const response = yield API.get(isWellnessProfile ? urlForWellnessProfile : urlForClinicalExam)
        if (response) {
            yield put(setExamDataLoader(true))
        }
        const data = {
            ...response?.data?.detail
        }
        delete data['appointment_id']
        delete data['appointment_type']
        yield put(
            updateExamAPIDataAction(
                convertMultiSelectStringToArray(data)
            )
        )
    } catch (e: any) {
        yield put(failureExamAPIDataAction())
    }
}

function* putOtherFormFindingRequestSaga(payload: any): any {
    const {examFindingData, examDataFromAPI} = yield select((state: RootState) => state?.examFindingReducer) as any

    const payloadData = {
        appointment_id: payload?.payload?.appointment_id,
        profile_id: dentistProfileData?.id,
        ...convertMultiSelectArrayToString(examFindingData)
    }
    if (payload?.payload?.submit) {
        payloadData['submit'] = payload?.payload?.submit
    }


    //TODO: Need optimiZations
    if (examDataFromAPI) {
        Object.keys(examDataFromAPI)?.map((eg: string) => {
            if (examDataFromAPI?.[eg] && examDataFromAPI?.[eg] !== null && examDataFromAPI?.[eg] !== undefined) {
                Object.keys(examDataFromAPI?.[eg])?.forEach((f: string) => {
                    if (examDataFromAPI?.[eg]?.[f] && examDataFromAPI?.[eg]?.[f] !== null && examDataFromAPI?.[eg]?.[f] !== undefined) {
                        if (isArray(examDataFromAPI?.[eg]?.[f])) {
                            examDataFromAPI?.[eg]?.[f]?.forEach((res: any) => {
                                //Find updated find start
                                if (payloadData && payloadData?.[eg] && payloadData?.[eg]?.[f]) {
                                    const newRes = res as any
                                    if (res?.tooth_surface) {
                                        newRes.tooth_surface = res?.tooth_surface?.toString()
                                    }
                                    if (res?.tooth_number) {
                                        newRes.tooth_number = res?.tooth_number?.toString()
                                    }

                                    let dynamicIndex = payloadData?.[eg]?.[f] ? payloadData?.[eg]?.[f]?.findIndex((item: any) => item?.ID === res?.ID) : -1;

                                    if (dynamicIndex >= 0 && JSON.stringify(newRes) !== JSON.stringify(payloadData?.[eg]?.[f]?.[dynamicIndex])) {
                                        payloadData[eg][f][dynamicIndex].flag = 'edit'
                                    }
                                }
                                //Find updated find start

                                //Find Deleted finding start
                                if (payloadData &&
                                    payloadData?.[eg] &&
                                    (
                                        !payloadData?.[eg]?.[f] ||
                                        (payloadData?.[eg] && payloadData?.[eg]?.[f] && payloadData?.[eg]?.[f]?.length < 1) ||
                                        !payloadData?.[eg]?.[f]?.map((k: any) => k?.ID)?.includes(res?.ID)
                                    )) {
                                    //Deleted Finding
                                    if (payloadData && payloadData?.[eg] && !payloadData?.[eg]?.[f]) {
                                        payloadData[eg][f] = [{
                                            ID: res?.ID,
                                            flag: "delete"
                                        }]
                                    } else {
                                        payloadData?.[eg]?.[f].push({
                                            ID: res?.ID,
                                            flag: "delete"
                                        })
                                    }
                                }
                                //Find Deleted finding end

                            })
                        } else {
                            //non array value
                        }
                    }
                })
            }
            return true
        })
    }

    if (payloadData) {
        Object.keys(payloadData)?.forEach((eg: string) => {
            if (payloadData?.[eg] && typeof payloadData?.[eg] !== 'string') {
                Object.keys(payloadData?.[eg]) && Object.keys(payloadData?.[eg])?.forEach((f: string) => {
                    if (payloadData?.[eg] && payloadData?.[eg]?.[f] && payloadData?.[eg]?.[f] !== null && payloadData?.[eg]?.[f] !== undefined) {
                        if (isArray(payloadData?.[eg]?.[f])) {
                            payloadData[eg][f] = payloadData?.[eg]?.[f]?.filter((f: any) => !f?.ID || (f?.ID && f?.flag?.length > 0))
                        }
                    }
                })
            }
        })
    }
    try {
        const response = yield API.put(`/${ApiEndpointVersion}/exam/miscellaneous`, payloadData)
        yield put(successExamAPIDataAction())
        if (payload?.payload.callback) {
            yield call(payload?.payload?.callback)
        }
        if (payload?.payload?.submit) {
            const step = 2 as any
            yield put(updateAppointmentStepAction(step));
            yield put(appointmentDetailsRequestAction({appointment_id: payload?.payload?.appointment_id,}));
            yield put(loadingScreenTimingAction(true));
        } else {
            successToaster(response?.data?.meta?.message)
            if (payload.payload?.isCallGetAPI) {
                yield put(getOtherFormFindingRequestAction({
                    appointment_id: payload?.payload?.appointment_id,
                }));
            }
            if (payload?.payload?.next) {
                yield put(updateFindingTypeAction(medical))
            } else if (payload?.payload?.prev) {
                yield put(updateFindingTypeAction(periogram))
            }
        }
        yield put(getRiskFilterScoreRequestAction({
            appointment_id: payload?.payload?.appointment_id,
            isPusher: true
        }));

    } catch (e: any) {
        yield put(failurePutExamAPIDataAction())
        errorToaster(e?.meta?.message)
    }
}

//sync pms
function* syncPeriogramPmsRequestSaga(payload: any): any {
    try {
        const isWellnessProfile = window.location.pathname.includes('/dentist/wellness-profile/')
        const urlForWellnessProfile = `${process.env.REACT_APP_PMS_API_URL}/charts/periogram/patient/${payload.payload?.patientId}?clinic_id=${getLocalStorageItem('clinic')}`
        const urlForExam = `${process.env.REACT_APP_PMS_API_URL}/charts/periogram/${payload.payload?.appointment_id}`
        const response = yield API.get(isWellnessProfile ? urlForWellnessProfile : urlForExam)
        yield put(
            syncPeriogramPmsDataRequestSuccessAction(response?.data?.detail)
        )
        if (payload?.payload.callback) {
            yield call(payload?.payload?.callback)
        }
        if (!payload.payload?.preventGetApiCall) {
            yield put(
                getPeriogramRequestAction({
                    appointment_id: payload.payload?.appointment_id,
                    patient_id: payload.payload?.patientId,
                })
            )
        }
    } catch (e: any) {
        // errorToaster(e?.detail)
        if (payload?.payload.errorCallback) {
            yield call(payload?.payload?.errorCallback)
        }
        yield put(
            syncPeriogramPmsDataRequestFailureAction(e?.detail)
        )
    }
}


//sync odontogram
function* syncOdontogramPmsRequestSaga(payload: any): any {
    try {
        const isWellnessProfile = window.location.pathname.includes('/dentist/wellness-profile/')
        const urlForWellnessProfile = `${process.env.REACT_APP_PMS_API_URL}/charts/odontogram/patient/${payload.payload?.patientId}?clinic_id=${getLocalStorageItem('clinic')}`
        const urlForExam = `${process.env.REACT_APP_PMS_API_URL}/charts/odontogram/${payload.payload?.appointment_id}`
        const response = yield API.get(isWellnessProfile ? urlForWellnessProfile : urlForExam)
        yield put(
            syncOdontogramPmsDataRequestSuccessAction(response?.data?.detail)
        )
        if (payload?.payload.callback) {
            yield call(payload?.payload?.callback)
        }
        if (!payload.payload?.preventGetApiCall) {
            if (payload.payload?.fromTreatmentPlan) {
                yield put(getOdontogramTreatmentDataAction({appointmentID: payload.payload?.appointment_id}))
            } else {
                yield put(
                    getOdontogramRequestAction({
                        appointment_id: payload.payload?.appointment_id,
                        patient_id: payload.payload?.patientId,
                    })
                )
            }
        }
    } catch (e: any) {
        if (payload?.payload.errorCallback) {
            yield call(payload?.payload?.errorCallback)
        }
        // errorToaster(e?.detail)
        yield put(
            syncOdontogramPmsDataRequestFailureAction(e?.detail)
        )
    }
}

// Limited Exam
function* limitedExamRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/${ApiEndpointVersion}/exam/limited?appointment_id=${payload.payload?.appointment_id}`)
        if (response) {
            yield put(setExamDataLoader(true))
        }
        const data = {
            ...response?.data?.detail
        }
        delete data['appointment_id']
        delete data['appointment_type']
        const customizeResponse = {} as any
        examGroupsData?.forEach((item: any) => {
            const {key} = item;
            if (key in data?.soft_tissue) {
                customizeResponse[key] = data?.soft_tissue[key];
            } else if (key in data?.hard_tissue) {
                customizeResponse[key] = data?.hard_tissue[key];
            }
        });
        yield put(
            updateExamAPIDataAction(
                convertMultiSelectStringToArray(customizeResponse)
            )
        )
    } catch (e: any) {
        yield put(failureExamAPIDataAction())
    }
}

// Put Limited exam
function* putLimitedExamRequestSaga(payload: any): any {
    const {examFindingData, examDataFromAPI} = yield select((state: RootState) => state?.examFindingReducer) as any
    const payloadData = {
        ...convertMultiSelectArrayToString(examFindingData)

    }
    if (examDataFromAPI) {
        Object.keys(examDataFromAPI)?.map((eg: string) => {
            if (examDataFromAPI?.[eg] && examDataFromAPI?.[eg] !== null && examDataFromAPI?.[eg] !== undefined) {
                Object.keys(examDataFromAPI?.[eg])?.forEach((f: string) => {
                    if (examDataFromAPI?.[eg]?.[f] && examDataFromAPI?.[eg]?.[f] !== null && examDataFromAPI?.[eg]?.[f] !== undefined) {
                        if (isArray(examDataFromAPI?.[eg]?.[f])) {
                            examDataFromAPI?.[eg]?.[f]?.forEach((res: any) => {
                                //Find updated find start
                                if (payloadData && payloadData?.[eg] && payloadData?.[eg]?.[f] && payloadData?.[eg]?.[f] !== null && payloadData?.[eg]?.[f] !== undefined) {
                                    const newRes = res as any
                                    if (res?.tooth_surface) {
                                        newRes.tooth_surface = res?.tooth_surface?.toString()
                                    }
                                    if (res?.tooth_number) {
                                        newRes.tooth_number = res?.tooth_number?.toString()
                                    }

                                    let dynamicIndex = payloadData?.[eg]?.[f] ? payloadData?.[eg]?.[f]?.findIndex((item: any) => item?.ID === res?.ID) : -1;

                                    if (dynamicIndex >= 0 && JSON.stringify(newRes) !== JSON.stringify(payloadData?.[eg]?.[f]?.[dynamicIndex])) {
                                        payloadData[eg][f][dynamicIndex].flag = 'edit'
                                    }
                                }
                                //Find updated find start

                                //Find Deleted finding start
                                if (payloadData &&
                                    payloadData?.[eg] &&
                                    (
                                        !payloadData?.[eg]?.[f] ||
                                        (payloadData?.[eg] && payloadData?.[eg]?.[f] && payloadData?.[eg]?.[f]?.length < 1) ||
                                        !payloadData?.[eg]?.[f]?.map((k: any) => k?.ID)?.includes(res?.ID)
                                    )) {
                                    //Deleted Finding
                                    if (payloadData && payloadData?.[eg] && !payloadData?.[eg]?.[f]) {
                                        payloadData[eg][f] = [{
                                            ID: res?.ID,
                                            flag: "delete"
                                        }]
                                    } else {
                                        payloadData?.[eg]?.[f]?.push({
                                            ID: res?.ID,
                                            flag: "delete"
                                        })
                                    }
                                }
                                //Find Deleted finding end

                            })
                        } else {
                            //non array value
                        }
                    }
                })
            }
            return true
        })
    }
    if (payloadData) {
        Object.keys(payloadData)?.forEach((eg: string) => {
            if (payloadData?.[eg] && typeof payloadData?.[eg] !== 'string') {
                Object.keys(payloadData?.[eg]) && Object.keys(payloadData?.[eg])?.forEach((f: string) => {
                    if (payloadData?.[eg] && payloadData?.[eg]?.[f] && payloadData?.[eg]?.[f] !== null && payloadData?.[eg]?.[f] !== undefined) {
                        if (isArray(payloadData?.[eg]?.[f])) {
                            payloadData[eg][f] = payloadData?.[eg]?.[f]?.filter((f: any) => !f?.ID || (f?.ID && f?.flag?.length > 0))
                        }
                    }
                })
            }
        })
    }
    const newPayload = {
        hard_tissue: {},
        soft_tissue: {},
        appointment_id: payload?.payload?.appointment_id,
        profile_id: dentistProfileData?.id,
    } as any
    const convertExamTypesKey = (key: string) => {
        const typesK = {
            hard_tissue: 'hardTissue',
            soft_tissue: 'softTissue'
        } as any
        return typesK[key]
    }
    Object.keys(newPayload)?.map((et: string) => {
        Object.keys(payloadData)?.map((eg: string) => {
            Object.keys(payloadData?.[eg])?.map((fi: string) => {
                const findingInfo = removeRefFromJson(findingData)?.find((ff: any) => ff?.key === fi || ff?.options?.map((fo: any) => fo?.key)?.includes(fi))
                if (findingInfo?.findingTypes?.includes(convertExamTypesKey(et))) {
                    if (newPayload?.[et]?.[eg]) {
                        newPayload[et][eg][fi] = payloadData?.[eg]?.[fi]
                    } else {
                        newPayload[et][eg] = {[fi]: payloadData?.[eg]?.[fi]}
                    }
                }
                return true
            })
            return true
        })
        return true
    })

    if (payload?.payload?.submit) {
        newPayload['submit'] = payload?.payload?.submit
    }
    try {
        const response = yield API.post(`/${ApiEndpointVersion}/exam/limited`, newPayload)
        yield put(successExamAPIDataAction())
        if (payload?.payload.callback) {
            yield call(payload?.payload?.callback)
        }

        if (payload?.payload?.submit) {
            const step = 2 as any
            yield put(updateAppointmentStepAction(step));
            yield put(appointmentDetailsRequestAction({appointment_id: payload?.payload?.appointment_id,}));
            yield put(loadingScreenTimingAction(true));
        } else {
            successToaster(response?.data?.meta?.message)
            if (payload.payload?.isCallGetAPI) {
                yield put(getLimitedExamRequestAction({
                    appointment_id: payload?.payload?.appointment_id,
                }));
            }
            if (payload?.payload?.next) {
                const step = 2 as any
                yield put(updateAppointmentStepAction(step));
            } else if (payload?.payload?.prev) {
                yield put(updateFindingTypeAction(periogram))
            }
        }
        yield put(getRiskFilterScoreRequestAction({
            appointment_id: payload?.payload?.appointment_id,
            isPusher: true
        }));
    } catch (e: any) {
        yield put(failurePutExamAPIDataAction())
        errorToaster(e?.meta?.message)
    }
}


function* getOdontogramOtherFindingRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/${ApiEndpointVersion}/exam/other-findings/${payload.payload?.patient_id}`)
        yield put(
            getOdontogramOtherFindingRequestSuccessAction(response?.data?.detail)
        )
    } catch (e: any) {
        yield put(getOdontogramOtherFindingRequestFailureAction(e?.meta?.message))
    }
}


function* examRequestSaga(payload: any): any {
    try {

        const response = yield API.get(`/${ApiEndpointVersion}/exam/ce-re?appointment_id=${payload.payload?.appointment_id}`)
        if (response) {
            yield put(setExamDataLoader(true))
        }
        const data = {
            ...response?.data?.detail
        }
        delete data['appointment_id']
        delete data['appointment_type']
        yield put(
            updateExamAPIDataAction(
                convertMultiSelectStringToArray(data)
            )
        )
    } catch (e: any) {
        yield put(failureExamAPIDataAction())
    }
}

function* putExamRequestSaga(payload: any): any {
    const {
        examFindingReducer: {examFindingData, examDataFromAPI},
    } = yield select((state: RootState) => state) as any
    const isWellnessProfile = window.location.pathname.includes('k/dentist/wellness-profile/')

    let payloadData = {} as any;
    if (isWellnessProfile && payload?.payload?.patient_id) {
        payloadData = {
            patient_id: payload?.payload?.patient_id || undefined,
            profile_id: dentistProfileData?.id || undefined,
            ...convertMultiSelectArrayToString(examFindingData)
        }
    } else {
        payloadData = {
            appointment_id: payload?.payload?.appointment_id || null,
            profile_id: dentistProfileData?.id || undefined,
            ...convertMultiSelectArrayToString(examFindingData)
        }
    }
    if (payload?.payload?.submit) {
        payloadData['submit'] = payload?.payload?.submit
    }
    //TODO: Need optimisations
    if (examDataFromAPI) {
        Object.keys(examDataFromAPI)?.map((eg: string) => {
            if (examDataFromAPI?.[eg] && examDataFromAPI?.[eg] !== null && examDataFromAPI?.[eg] !== undefined) {
                Object.keys(examDataFromAPI?.[eg])?.forEach((f: string) => {
                    if (examDataFromAPI?.[eg]?.[f] && examDataFromAPI?.[eg]?.[f] !== null && examDataFromAPI?.[eg]?.[f] !== undefined) {
                        if (isArray(examDataFromAPI?.[eg]?.[f])) {
                            examDataFromAPI?.[eg]?.[f]?.forEach((res: any) => {
                                //Find updated find start
                                if (payloadData && payloadData?.[eg] && payloadData?.[eg]?.[f] && payloadData?.[eg]?.[f] !== null && payloadData?.[eg]?.[f] !== undefined) {
                                    const newRes = res as any
                                    if (res?.tooth_surface) {
                                        newRes.tooth_surface = res?.tooth_surface?.toString()
                                    }
                                    if (res?.tooth_number) {
                                        newRes.tooth_number = res?.tooth_number?.toString()
                                    }
                                    let dynamicIndex = payloadData?.[eg]?.[f] ? payloadData?.[eg]?.[f]?.findIndex((item: any) => item?.ID === res?.ID) : -1;

                                    if (dynamicIndex >= 0 && JSON.stringify(newRes) !== JSON.stringify(payloadData?.[eg]?.[f]?.[dynamicIndex])) {
                                        payloadData[eg][f][dynamicIndex].flag = 'edit'
                                    }
                                }
                                //Find updated find start
                                //Find Deleted finding start
                                if ((payloadData && payloadData?.[eg] && !payloadData?.[eg]?.[f]) || (payloadData?.[eg] && payloadData?.[eg]?.[f] && payloadData?.[eg]?.[f]?.length < 1) || (payloadData?.[eg] && payloadData?.[eg]?.[f] && !payloadData?.[eg]?.[f]?.map((k: any) => k?.ID)?.includes(res?.ID))) {
                                    //Deleted Finding
                                    if (!payloadData?.[eg]?.[f]) {
                                        payloadData[eg][f] = [{
                                            ID: res?.ID,
                                            flag: "delete"
                                        }]
                                    } else {
                                        payloadData?.[eg]?.[f].push({
                                            ID: res?.ID,
                                            flag: "delete"
                                        })
                                    }
                                }
                                //Find Deleted finding end
                            })
                        } else {
                            //non array value
                        }
                    }
                })
            }
            return true
        })
    }
    if (payloadData) {
        Object.keys(payloadData)?.forEach((eg: string) => {
            if (payloadData?.[eg] && typeof payloadData?.[eg] !== 'string' && payloadData?.[eg] !== null && payloadData?.[eg] !== undefined) {
                Object.keys(payloadData?.[eg]) && Object.keys(payloadData?.[eg])?.forEach((f: string) => {
                    if (payloadData?.[eg]?.[f] && payloadData?.[eg]?.[f] !== null && payloadData?.[eg]?.[f] !== undefined) {
                        if (isArray(payloadData?.[eg]?.[f])) {
                            if (payloadData?.[eg]?.[f]?.filter((f: any) => !f?.ID || (f?.ID && f?.flag?.length > 0))) {
                                payloadData[eg][f] = payloadData?.[eg]?.[f]?.filter((f: any) => !f?.ID || (f?.ID && f?.flag?.length > 0))
                            }
                        }
                    }
                })
            }
        })
    }

    try {
        const response = yield API.post(`/${ApiEndpointVersion}/exam/ce-re`, removeNullValue(payloadData))
        yield put(successExamAPIDataAction())

        if (!isWellnessProfile) {
            yield put(getRiskFilterScoreRequestAction({
                appointment_id: payload?.payload?.appointment_id,
                isPusher: true
            }));
        }

        if (payload?.payload.callback) {
            yield call(payload?.payload?.callback)
        }
        if (payload?.payload?.submit && !isWellnessProfile) {
            const step = 2 as any
            yield put(updateAppointmentStepAction(step));
            yield put(appointmentDetailsRequestAction({appointment_id: payload?.payload?.appointment_id,}));
        } else {
            successToaster(response?.data?.meta?.message)
            if (payload?.payload?.next) {
                yield put(updateFindingTypeAction(odontogram))
            } else if (payload?.payload?.prev) {
                yield put(updateFindingTypeAction(xray))
            }
        }
    } catch (e: any) {
        yield put(failurePutExamAPIDataAction())
        errorToaster(e?.meta?.message)
    }
}

function* softTissueWatch() {
    yield takeEvery(GET_SOFT_TISSUE_REQUEST, softTissueRequestSaga)
}

function* putSoftTissueWatch() {
    yield takeEvery(PUT_SOFT_TISSUE_REQUEST, putSoftTissueRequestSaga)
}

function* hardTissueWatch() {
    yield takeEvery(GET_HARD_TISSUE_REQUEST, hardTissueRequestSaga)
}

function* putHardTissueWatch() {
    yield takeEvery(PUT_HARD_TISSUE_REQUEST, putHardTissueRequestSaga)
}

function* odontogramWatch() {
    yield takeEvery(GET_ODONTOGRAM_REQUEST, odontogramRequestSaga)
}

function* putOdontogramWatch() {
    yield takeEvery(PUT_ODONTOGRAM_REQUEST, putOdontogramRequestSaga)
}

function* periogramWatch() {
    yield takeEvery(GET_PERIOGRAM_REQUEST, periogramRequestSaga)
}

function* putPeriogramWatch() {
    yield takeEvery(PUT_PERIOGRAM_REQUEST, putPeriogramRequestSaga)
}

function* otherFormFindingWatch() {
    yield takeEvery(GET_OTHER_FORM_FINDING_REQUEST, otherFormFindingRequestSaga)
}

function* putOtherFormFindingWatch() {
    yield takeEvery(PUT_OTHER_FORM_FINDING_REQUEST, putOtherFormFindingRequestSaga)
}

function* syncPeriogramPmsWatch() {
    yield takeEvery(SYNC_PERIOGRAM_PMS_DATA_REQUEST, syncPeriogramPmsRequestSaga)
}

function* syncOdontogramPmsWatch() {
    yield takeEvery(SYNC_ODONTOGRAM_PMS_DATA_REQUEST, syncOdontogramPmsRequestSaga)
}

function* limitedExamWatch() {
    yield takeEvery(GET_LIMITED_EXAM_REQUEST, limitedExamRequestSaga)
}

function* putLimitedExamWatch() {
    yield takeEvery(PUT_LIMITED_EXAM_REQUEST, putLimitedExamRequestSaga)
}

function* getOdontogramOtherFindingWatch() {
    yield takeEvery(GET_ODONTOGRAM_OTHER_FINDINGS_REQUEST, getOdontogramOtherFindingRequestSaga)
}


function* exmWatch() {
    yield takeEvery(GET_EXAM_REQUEST, examRequestSaga)
}

function* putExamWatch() {
    yield takeEvery(PUT_EXAM_REQUEST, putExamRequestSaga)
}

export default function* examFindingSaga() {
    yield all([
        softTissueWatch(),
        putSoftTissueWatch(),
        hardTissueWatch(),
        putHardTissueWatch(),
        odontogramWatch(),
        putOdontogramWatch(),
        periogramWatch(),
        putPeriogramWatch(),
        otherFormFindingWatch(),
        putOtherFormFindingWatch(),
        syncPeriogramPmsWatch(),
        syncOdontogramPmsWatch(),
        limitedExamWatch(),
        putLimitedExamWatch(),
        getOdontogramOtherFindingWatch(),
        exmWatch(),
        putExamWatch()
    ])
}
