import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import API from '../../../../../utils/api';
import {
    ApiEndpointVersion,
    checkAPIParams,
    dentistProfileData,
    errorToaster,
    getUniqId,
    removeRefFromJson,
    successToaster
} from '../../../../../utils/helper';
import {
    addAdjunctiveAction,
    addHygieneRegimeDataAction,
    addNotesForHygieneRegimeAction,
    addReferralsAction,
    deleteTreatmentDataFailAction,
    deleteTreatmentDataSuccessAction,
    generateRecommendationRequestFailureAction,
    generateRecommendationRequestSuccessAction,
    getRecommendationDataFailAction,
    getRecommendationDataSuccessAction,
    getTreatmentDataFailAction,
    getTreatmentDataSuccessAction,
    updateExtentAction,
    updateRecommendationLoadingScreenAction,
    updateStagingGradingAction,
    updateTreatmentDataFailAction,
    updateTreatmentDataSuccessAction, updateXRayCycleRequestFailureAction, updateXRayCycleRequestSuccessAction,
} from "../../../../actions/dentist/recommendationsTreatmentActions";
import {
    getOdontogramTreatmentDataFailAction,
    getOdontogramTreatmentDataSuccessAction,
} from '../../../../actions/dentist/clinicalExam/treatment/proposedTreatments/oraqProposedAction';
import {
    DELETE_TREATMENT_DATA_REQUEST,
    GENERATE_RECOMMENDATION_REQUEST,
    GET_RECOMMENDATION_DATA_REQUEST,
    GET_TREATMENT_DATA_REQUEST,
    UPDATE_TREATMENT_DATA_REQUEST, UPDATE_X_RAY_CYCLE_REQUEST
} from "../../../../actionTypes/dentist/clinicalExam/treatment/recommendationsTreatment";
import {
    GET_ODONTOGRAM_TREATMENT_DATA_REQUEST
} from '../../../../actionTypes/dentist/clinicalExam/treatment/proposedTreatments/oraqProposed';
import {RootState} from '../../../../reducers/rootReducer';
import {appointmentDetailsRequestAction} from '../../../../actions/dentist/appointmentsAction';

function* getTreatmentDataSaga(payload: any): any {
    const {hygieneRegimeFromInsight} = yield select((state: RootState) => state?.recommendationsTreatment) as any
    try {
        const response = yield API.get(`${ApiEndpointVersion}/treatment/scripts/recommendations/${payload.payload?.appointmentID}`);
        const data = response?.data?.detail

        yield put(
            getTreatmentDataSuccessAction(data)
        );
        yield put(addReferralsAction(data?.referrals))
        if (data?.hygiene_regime !== null) {
            const monthValuesByTag = {
                xray: data?.hygiene_regime?.xray_cycle !== null ? data?.hygiene_regime?.xray_cycle : hygieneRegimeFromInsight?.xray,
                exam: data?.hygiene_regime?.exam_cycle !== null ? data?.hygiene_regime?.exam_cycle : hygieneRegimeFromInsight?.exam,
                hygiene: data?.hygiene_regime?.hygiene_cycle !== null ? data?.hygiene_regime?.hygiene_cycle : hygieneRegimeFromInsight?.hygiene,
            } as any
            const adjunctiveValue = {
                adjunctive: data?.hygiene_regime?.adjunctive ?? data?.hygiene_regime?.adjunctive,
                adjunctiveCodes: data?.hygiene_regime?.adjunctive_codes ?? data?.hygiene_regime?.adjunctive_codes,
            } as any
            const stagingGradingValues = {
                staging: data?.hygiene_regime?.staging ?? data?.hygiene_regime?.staging,
                grading: data?.hygiene_regime?.grading ?? data?.hygiene_regime?.grading,

            } as any
            const extentData = {
                extent: data?.hygiene_regime?.extent ?? data?.hygiene_regime?.extent,
                gingival_status: data?.hygiene_regime?.gingival_status ?? data?.hygiene_regime?.gingival_status}
            yield put(updateStagingGradingAction(stagingGradingValues))
            yield put(updateExtentAction(extentData))
            yield put(addNotesForHygieneRegimeAction(data?.hygiene_regime?.notes))
            if (data?.hygiene_regime?.xray_cycle !== null || data?.hygiene_regime?.exam_cycle !== null || data?.hygiene_regime?.hygiene_cycle !== null) {
                yield put(addHygieneRegimeDataAction(monthValuesByTag))
            }
            if (data?.hygiene_regime?.adjunctive !== null) {
                yield put(addAdjunctiveAction(adjunctiveValue))
            }
        }


        // TODO Future Use
        // yield put(getRecommendationDataAction({
        //   clinicID: selectedClinic,
        //   appointment_id: payload.payload?.appointmentID
        // }))


    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            getTreatmentDataFailAction()
        );
    }
}

function* updateTreatmentDataSaga(payload: any): any {
    const {
        additionalTreatments,
        oraQProposedData,
        hygieneRegimeFromInsight,
        adjunctiveFromInsight,
        stagingGradingData,
        extentData,
        hygieneRegimeNotes,
        referralsFromInsight
    } = yield select((state: RootState) => state?.recommendationsTreatment) as any
    const {removeDataId,} = yield select((state: RootState) => state?.plannedTreatmentReducer) as any
    const {masterProposedData} = yield select((state: RootState) => state?.oraqProposedReducer) as any
    const deleteTreatmentData = [...removeDataId]

    const handleFindingData = (data: any) => {
        if (data?.tooth_surface) {
            return {
                ...data,
                tooth_surface: data?.tooth_surface?.toString()
            }
        } else {
            return data
        }
    }
    const updateTreatmentData = (additionalTreatments: any, treatments: any) => {
        if (treatments) {
            return treatments?.map((treatment: any) => {
                const updatedTreatments = treatment?.treatments?.map((treat: any) => {
                    const matchingAdditional = additionalTreatments?.find((addItem: any) => treat?.treatment_details_id && (treat.treatment_details_id === addItem?.treatment_details_id));
                    if (matchingAdditional) {
                        return {
                            ...treat,
                            treatment_details_id_local: matchingAdditional?.ID
                        };
                    }
                    return treat;
                });
                return {
                    ...treatment,
                    treatments: updatedTreatments
                };
            });
        }
    }

    const removedEmptyTreatments = updateTreatmentData(additionalTreatments, payload.payload.treatments)?.filter((item: any) => {
        return item?.treatments && item?.treatments?.length > 0;
    }) || [];

    const formattedPayloadTreatmentPlan = [
        ...removedEmptyTreatments?.map((treatmentGroup: any, index: number) => ({
            position: index,
            treatment_id: treatmentGroup?.treatment_id,
            notes: treatmentGroup.notes,
            treatments: treatmentGroup.treatments?.map((treatment: any, innerIndex: number) => ({
                treatment_details_id: treatment?.treatment_details_id,
                procedures: treatment?.procedures?.map((procedure:any)=>({...procedure,is_additional:procedure?.is_additional || false})),
                inner_position: innerIndex,
                recommendation_id: treatment?.recommendation_id,
                treatment_finding: treatment?.treatment_finding ? handleFindingData(treatment?.treatment_finding) : null,
                treatment_finding_id: treatment?.treatment_finding_id || null,
                treatment_type: treatment?.treatment_type || null,
                procedure_text: treatment?.procedure_text || null,
                additional_id: (treatment?.treatment_type === 'additional' ? treatment?.treatment_details_id_local : null) || null,
            })),
        }))
    ] as any

    let additionalTreatmentsArray = [] as any;

    // payload?.payload?.treatments?.forEach((item: any) => {
    //     item.treatments?.forEach((treatment: any) => {
    //         if (treatment?.treatment_type === "proposed") {
    //             if (treatment?.procedures?.some((procedure: any) => procedure?.quantity !== null)) {
    //                 treatment?.procedures?.forEach((procedure: any) => {
    //                     if (procedure?.quantity !== null) {
    //                         additionalTreatmentsArray?.push({
    //                             code: procedure?.code,
    //                             quantity: procedure?.quantity || null,
    //                             treatment_type: treatment?.treatment_type,
    //                             notes: procedure?.notes || null, // You can include notes if available
    //                             ID: getUniqId()
    //                         });
    //                     }
    //                 });
    //             }
    //         }
    //     });
    // });

    let proceduresArray = [] as any;
    const removeRefAndHygieneTag = () => {
        return additionalTreatments?.filter((f: any) =>
            f?.hygiene_tag === null || f?.hygiene_tag === undefined
        )?.filter((ff: any) =>
            ff?.code_type !== "referral"
        );
    };

    removeRefAndHygieneTag()?.map((res: any) => {
        if (res?.treatment_finding?.tooth_number===null || res?.treatment_finding?.tooth_number===undefined) {
            proceduresArray?.push({
                code: res?.code,
                treatment_type: "additional",
                notes: res?.notes,
                quantity: res?.quantity,
                ID: res?.treatment_details_id_local,
                treatment_details_id: res?.treatment_details_id,
            })
        }
        return true
    })

    masterProposedData?.map((res: any) => {
        if (res?.additional_codes) {
            res?.additional_codes?.forEach((additional: any) => {
                if (additional?.quantity > 0) {
                    proceduresArray?.push({
                        code: additional?.code,
                        treatment_type: "proposed",
                        quantity: additional?.quantity || null,
                        ID: getUniqId()
                    })
                }
            })
            return true
        }
        return true
    })

    const newSelectedData = removeRefFromJson(oraQProposedData)?.filter((res: any) => res?.selected_code)

    newSelectedData?.map((newData: any) => {
        proceduresArray?.push({
            code: newData?.default_code?.code,
            treatment_type: 'proposed',
            selected_code: newData?.selected_code?.code,
            default_treatment_id: newData?.ID,
            ID: getUniqId()
        })
        return true
    })

    additionalTreatmentsArray = [...additionalTreatmentsArray, ...proceduresArray]


    const hygieneRegimen = {
        hygiene_cycle: hygieneRegimeFromInsight?.hygiene || 6,
        exam_cycle: hygieneRegimeFromInsight?.exam || 12,
        xray_cycle: hygieneRegimeFromInsight?.xray || 24,
        adjunctive: adjunctiveFromInsight?.adjunctiveCodes,
        staging: stagingGradingData?.staging || null,
        grading: stagingGradingData?.grading || null,
        extent: extentData?.extent || null,
        notes: hygieneRegimeNotes,
        gingival_status: extentData?.gingival_status || null
    }
    const removedDesFromReferrals: any = referralsFromInsight?.map(({description, id, ...rest}: any) => rest);

    let updatedData = {
        recommendations: payload?.payload?.recommendations,
        treatments: formattedPayloadTreatmentPlan,
        delete_treatment: deleteTreatmentData,
        additional_procedures: additionalTreatmentsArray,
        submit: payload?.payload?.submit,
        appointment_id: payload.payload?.appointmentID,
        hygiene_regimen: hygieneRegimen,
        referrals: removedDesFromReferrals,
        profile_id: dentistProfileData?.id
    };

    try {
        const response = yield API.post(`${ApiEndpointVersion}/treatment/recommendations`, updatedData);
        yield put(updateTreatmentDataSuccessAction(updatedData));

        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback)
        }
        if (payload?.payload?.submit) {
            yield put(appointmentDetailsRequestAction({
                appointment_id: payload.payload?.appointmentID,
            }));
            // yield put(updateOpenCompleteStepAction(true)) //popup open
        } else {
            successToaster(response?.data?.meta?.message)
        }
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            updateTreatmentDataFailAction({
                error: e?.detail
            })
        );
    }
}

function* deleteTreatmentDataSaga(payload: any): any {
    const newPayload = payload?.payload?.deleteData
    try {
        const response =
            newPayload?.position === undefined ?
                yield API.delete(`${ApiEndpointVersion}/treatment/${newPayload?.appointmentID}`) :
                yield API.delete(`${ApiEndpointVersion}/treatment/${newPayload?.appointmentID}?position=${newPayload?.position}&is_second_screen=${newPayload?.is_second_screen}`);
        if (response) {
            successToaster(response?.data?.meta?.message)
        }
        yield put(
            deleteTreatmentDataSuccessAction({
                deleteTreatment: response?.data,
            })
        );
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback)
        }
    } catch (e: any) {
        errorToaster(e?.detail)
        yield put(
            deleteTreatmentDataFailAction({
                error: e?.detail,
            })
        );
    }
}

// generate Recommendations
function* generateRecommendationsRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/${ApiEndpointVersion}/generate/recommendation/${payload.payload?.appointment_id}`)
        if (response) {
            yield put(updateRecommendationLoadingScreenAction(true))
        }

        yield put(
            generateRecommendationRequestSuccessAction(
                response?.data?.detail
            )
        )
    } catch (e: any) {
        generateRecommendationRequestFailureAction(
            e?.meta?.message,
        )
    }
}

/*Master Api for Get recommendation*/
function* getNewRecommendationDataSaga(payload: any): any {
    try {
        const params = [
            {key: 'search', value: payload.payload.search},
            {key: 'appointment_id', value: payload.payload.appointment_id},
        ]

        let url = `/${ApiEndpointVersion}/treatment/procedures/clinic/${payload.payload?.clinicID}${checkAPIParams(params)}`
        const response = yield API.get(url);
        const data = response?.data?.detail
        yield put(
            getRecommendationDataSuccessAction(data)
        );
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            getRecommendationDataFailAction()
        );
    }
}

/*Api for Get odontogram*/
function* getOdontogramDataSaga(payload: any): any {
    try {
        const url = `/${ApiEndpointVersion}/treatment/odontogram/${payload.payload?.appointmentID}`
        const response = yield API.get(url);
        const data = response?.data?.detail

        yield put(
            getOdontogramTreatmentDataSuccessAction(data)
        );
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            getOdontogramTreatmentDataFailAction(e?.meta?.message)
        );
    }
}

function* getRecommendationsTreatmentDataWatcher() {
    yield takeEvery(GET_TREATMENT_DATA_REQUEST, getTreatmentDataSaga);
}


function* updateXrayCycleRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/${ApiEndpointVersion}/treatment/check/x-ray`, payload?.payload)
        if (response) {
            yield put(updateXRayCycleRequestSuccessAction(response?.data?.detail))
        }
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback)
        }
        successToaster(response?.data?.meta?.message)
    } catch (e: any) {
        updateXRayCycleRequestFailureAction(
            e?.meta?.message,
        )
    }
}


function* updateRecommendationsTreatmentDataWatcher() {
    yield takeEvery(UPDATE_TREATMENT_DATA_REQUEST, updateTreatmentDataSaga);
}

function* deleteRecommendationsTreatmentDataWatcher() {
    yield takeEvery(DELETE_TREATMENT_DATA_REQUEST, deleteTreatmentDataSaga);
}

function* generateRecommendationsRequestWatcher() {
    yield takeEvery(GENERATE_RECOMMENDATION_REQUEST, generateRecommendationsRequestSaga);
}

function* getNewRecommendationsDataWatcher() {
    yield takeEvery(GET_RECOMMENDATION_DATA_REQUEST, getNewRecommendationDataSaga);
}

function* getOdontogramDataWatcher() {
    yield takeEvery(GET_ODONTOGRAM_TREATMENT_DATA_REQUEST, getOdontogramDataSaga);
}

function* updateXrayCycleWatch() {
    yield takeEvery(UPDATE_X_RAY_CYCLE_REQUEST, updateXrayCycleRequestSaga)
}

export default function* recommendationsTreatmentSaga() {
    yield all([
        getRecommendationsTreatmentDataWatcher(),
        updateRecommendationsTreatmentDataWatcher(),
        deleteRecommendationsTreatmentDataWatcher(),
        generateRecommendationsRequestWatcher(),
        getNewRecommendationsDataWatcher(),
        getOdontogramDataWatcher(),
        updateXrayCycleWatch()
    ]);
}
