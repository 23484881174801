// ResourcesSubmenu.tsx
import React from "react";
import { Paper, ListItem, Typography, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faLock,
  faFileCircleCheck,
  faCircleInfo,
  faAddressBook,
  faChartArea,
} from "@fortawesome/free-solid-svg-icons";

interface ResourcesSubmenuProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  compareSidebarWidth: () => boolean;
  classes: any;
  setDisclaimerOpen: (open: boolean) => void;
  setSupportFormPopUp: (open: boolean) => void;
}

export const submenuItems = [
  {
    path: "/dentist/analytics",
    label: "Analytics",
    id: 3,
  },
  {
    path: "/dentist/resources",
    label: "Resources",
    id: 5,
    hasSubmenu: true,
  },
  {
    path: "/dentist/termOfUse",
    label: "Terms of Use",
  },
  {
    path: "/dentist/privacyPolicy",
    label: "Privacy Policy",
  },
  {
    label: "Disclaimer",
  },
  {
    label: "Support",
  },
];

const ResourcesSubmenu: React.FC<ResourcesSubmenuProps> = ({
  open,
  anchorEl,
  onClose,
  compareSidebarWidth,
  classes,
  setDisclaimerOpen,
  setSupportFormPopUp,
}) => {
  const submenuWithActions: any[] = submenuItems.map((item) => {
    if (item.label === "Disclaimer") {
      return {
        ...item,
        action: () => {
          setDisclaimerOpen(true);
          onClose();
        },
      };
    }

    if (item.label === "Support") {
      return {
        ...item,
        action: () => {
          setSupportFormPopUp(true);
          onClose();
        },
      };
    }

    return item; // Keep the item unchanged if no action is needed
  });

  if (!open) return null;

  const anchorRect = anchorEl?.getBoundingClientRect();
  const top = anchorRect ? anchorRect.top : 0;

  return (
    <Paper
      sx={{
        position: "fixed",
        left: compareSidebarWidth() ? "250px" : "64px",
        top: top,
        width: 220,
        backgroundColor: "white",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        zIndex: 1300,
      }}
      onMouseEnter={() => open}
      onMouseLeave={onClose}
    >
      {submenuWithActions.map((item, index) =>
        item.action ? (
          <Tooltip
            title={item.label}
            placement={"right"}
            classes={{ tooltip: classes.customWidth }}
          >
            <ListItem
              key={index}
              onClick={item.action}
              className={classes.button}
              sx={{
                padding: "12px 16px",
                "&:hover": {
                  backgroundColor: "#ecf9f7",
                },
              }}
            >
              {item.icon}
              <Typography ml={2} className="fw-regular f-16 lh-21 f-w-400">
                {item.label}
              </Typography>
            </ListItem>
          </Tooltip>
        ) : (
          <Link key={index} to={item.path} style={{ textDecoration: "none" }}>
            <Tooltip
              title={item.label}
              placement={"right"}
              classes={{ tooltip: classes.customWidth }}
            >
              <ListItem
                className={classes.button}
                sx={{
                  padding: "12px 16px",
                  "&:hover": {
                    backgroundColor: "#ecf9f7",
                  },
                }}
              >
                {item.icon}
                <Typography ml={2} className="fw-regular f-16 lh-21 f-w-400">
                  {item.label}
                </Typography>
              </ListItem>
            </Tooltip>
          </Link>
        )
      )}
    </Paper>
  );
};

export default ResourcesSubmenu;
