import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import {isArray} from 'lodash';
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from "@mui/material/CircularProgress";
import ExamFormAccordion from "./ExamFormAccordion";
import OdontogramForm from "./OdontogramForm";
import ExamGroup from '../ExamGroup';
import ExamGroupCE from "../ExamGroup/ExamGroupCE";
import {
    examFindingDataCountAction, setExamDataLoader,
    setExamFindingsAction
} from '../../../../../redux/actions/dentist/clinicalExam/exam/examFindingsTypesAction';
import {RootState} from '../../../../../redux/reducers/rootReducer';
import {examList, getUniqId} from "../../../../../utils/helper";
import {
    exam,
    hardTissue,
    limitedExam,
    odontogram,
    photo,
    softTissue,
    xray
} from '../../../../../utils/constant/clinicalExamConstant';
import {
    examGroupsData, examGroupsDataForCEREMainCat,
    examTabName,
    findingData,
    findingTypes
} from '../../../../../utils/constant/examConstant';

type Props = {
    handleExpanded: any
    handleFullSize: any
    showFooter: boolean;
};
type examConstantInterface = {
    key?: string;
    title?: string;
    risks?: Array<string>;
    examType?: Array<string>;
    findingTypes?: Array<string>;
    examGroup?: string;
    options?: Array<any>;
};

const ExamForm = React.memo((props: Props) => {
    const {handleExpanded, handleFullSize, showFooter} = props;
    const dispatch = useDispatch()
    const examFormRef = useRef(null) as any
    const {
        annotation: {annotationDataFromAPI},
        examFindingReducer: {examDataFromAPI, examFindingData, examDataLoader},
        appointmentsReducer: {appointmentDetails},
        wellnessProfileFilters: {wellnessProfileStep},
        clinicalExamFilters: {
            riskFilters,
            findingType,
            openCompleteStep,
            appointmentStep
        },
    } = useSelector((state: RootState) => state) as any

    const isWellnessProfile = window.location.pathname?.includes(`/dentist/wellness-profile/`)
    const tabData = isWellnessProfile ? wellnessProfileStep : findingType
    const isPhotoXray = (isWellnessProfile ? true : appointmentStep === 1) && (tabData === photo || tabData === xray)

    useEffect(() => {
        // To scroll to top while changing tab
        if (examFormRef?.current) {
            examFormRef.current.scroll(0, 0)
        }
    }, [tabData])

    useEffect(() => {
        createStructureForCurrentExam()
        // eslint-disable-next-line
    }, [riskFilters, tabData, examDataFromAPI, openCompleteStep, annotationDataFromAPI])


    const getData = useMemo(() => {
        const examType = appointmentDetails?.appointmentType;
        const findingTypeWiseFilteredData = findingData?.filter((f: examConstantInterface) =>
            f?.findingTypes?.includes(findingTypes[tabData])
        ) as any;
        // Limited Exam data
        const limitedExamData = (tabData === limitedExam) ? findingTypeWiseFilteredData?.filter((res: any) => res?.findingTypes?.includes(softTissue) || res?.findingTypes?.includes(hardTissue)) : findingTypeWiseFilteredData
        const riskWiseFilteredData = riskFilters?.length
            ? (limitedExamData?.filter((f: examConstantInterface) => f?.risks?.filter((ff: string) => riskFilters.includes(ff))?.length) as any)
            : limitedExamData; /*Filter the data only if there is at least one risk is selected else will show data without risk filters*/
        const examTypeWiseFilteredData = riskWiseFilteredData?.filter((f: examConstantInterface) => f?.examType?.includes(examType));
        return (isPhotoXray || isWellnessProfile) ? riskWiseFilteredData : examTypeWiseFilteredData
        // eslint-disable-next-line
    }, [appointmentDetails, tabData, isWellnessProfile, riskFilters, isPhotoXray, riskFilters?.length])

    const examGroupWiseFilteredData = useCallback((key: any) => {
        return getData?.filter((f: examConstantInterface) => f?.examGroup === key);
    }, [getData])

    const examTabWiseFilteredData = useCallback((key: any) => {
        return getData?.filter((f: examConstantInterface) => f?.findingTypes?.includes(key));
    }, [getData])

    const createStructureForCurrentExam = () => {
        let dataForReducer = {} as any
        // get exam groups
        let examGroups = getData?.map((res: any) => res?.examGroup) as any
        // remove duplicate exam groups and assign exam key to dataForReducer
        examGroups?.filter((item: any, index: number) => examGroups?.indexOf(item) === index)?.map((examKey: string) => {
            dataForReducer[examKey] = {}
            return true
        })
        // addExistingFindingData
        Object.keys(dataForReducer)?.forEach((examKey: string) => {
            let currentExamValues = {} as any
            examGroupWiseFilteredData(examKey)?.forEach((res: any) => {
                if (res?.isMulti && !isPhotoXray) {
                    const findingOptions = {} as any
                    res?.options?.forEach((option: any) => {
                        findingOptions[option?.key] = null
                    })
                    if (examDataFromAPI && examDataFromAPI[examKey] && (((isArray(examDataFromAPI[examKey][res?.key]) && examDataFromAPI[examKey][res?.key].length > 0) || (!isArray(examDataFromAPI[examKey][res?.key]) && examDataFromAPI[examKey][res?.key])) || examDataFromAPI[examKey][res?.key] === false)) {
                        //Set Data from an API
                        currentExamValues[res?.key] = examDataFromAPI && examDataFromAPI[examKey] && examDataFromAPI[examKey][res?.key]
                    } else {
                        currentExamValues[res?.key] = [findingOptions] as any
                    }
                } else {
                    res?.options?.forEach((option: any) => {
                        if (examDataFromAPI && examDataFromAPI[examKey] && (examDataFromAPI[examKey][option?.key] || examDataFromAPI[examKey][option?.key] === false || examDataFromAPI[examKey][option?.key] === 0)) {
                            currentExamValues[option?.key] = examDataFromAPI && examDataFromAPI[examKey] && examDataFromAPI[examKey][option?.key]
                        } else {
                            currentExamValues[option?.key] = null
                        }
                    })
                }
            })
            if (currentExamValues) {
                dataForReducer[examKey] = {...currentExamValues}
            }
        })

        if (isPhotoXray && annotationDataFromAPI) {
            // Make tag reducerData for Photo & Xray
            dispatch(setExamFindingsAction(annotationDataFromAPI?.map((res: any) => ({
                ...res,
                key: getUniqId()
            })) || []))
        } else {
            dispatch(setExamFindingsAction(dataForReducer))
            dispatch(setExamDataLoader(false))
        }
    }

    const hasTagForExam = useCallback((examName?: string) => {
        if (isPhotoXray && examFindingData && isArray(examFindingData))
            return examFindingData?.map((res: any) => examList[res?.title]?.key).includes(examName)
        return null
    }, [isPhotoXray, examFindingData])

    const hasFindingData = useMemo(() => {
        return examGroupsData?.filter((res: any) => examGroupWiseFilteredData(res?.key)?.length > 0)
    }, [examGroupWiseFilteredData])

    const hasFindingDataCERE = useMemo(() => {
        return examGroupsDataForCEREMainCat?.filter((res: any) => examGroupWiseFilteredData(res?.key)?.length > 0)
    }, [examGroupWiseFilteredData])

    const hasTabFindingData = useMemo(() => {
        return examTabName?.filter((res: any) => examTabWiseFilteredData(res?.key)?.length > 0)
    }, [examTabWiseFilteredData])

    useEffect(() => {
        dispatch(examFindingDataCountAction(hasFindingData.length))
        // eslint-disable-next-line
    }, [hasFindingData?.length, dispatch])

    return (
        <ExamFormAccordion showFooter={showFooter} handleExpanded={handleExpanded} handleFullSize={handleFullSize}>
            {examDataLoader ?
                <Box height={'100%'} className={'d-flex-all-center'}>
                    <CircularProgress/>
                </Box> :
                <React.Fragment>
                    {/*tabData === 4 is for Odontogram form only*/}
                    {tabData === odontogram ? (
                        <OdontogramForm getData={getData}/>
                    ) : hasFindingData?.length > 0 ? (
                        (tabData === limitedExam ? hasTabFindingData : tabData === exam ? hasFindingDataCERE : hasFindingData)
                            ?.map((res: examConstantInterface, i: number) => (
                            <React.Fragment key={i}>
                                {/* For photo or x-ray */}
                                {isPhotoXray && hasTagForExam(res?.key) && (
                                    <ExamGroup meta={res} data={examGroupWiseFilteredData(res?.key)}/>)}
                                {/* For non-photo/x-ray, non-limited exams, or wellness profile */}
                                {!isPhotoXray && tabData !== limitedExam && tabData !== exam && (
                                    <ExamGroup meta={res} data={examGroupWiseFilteredData(res?.key)}/>
                                )}
                                {/* Limited exams */}
                                {tabData === limitedExam && (
                                    <ExamGroup meta={res} data={examTabWiseFilteredData(res?.key)}/>
                                )}
                                {/* exams (CE) or RE:Created separate component for CE to wrap exam groups inside the extra oral and intra oral group */}
                                {tabData === exam && <ExamGroupCE meta={res} data={examGroupWiseFilteredData(res?.key)}
                                                                  getData={getData}/>
                                }
                            </React.Fragment>
                        ))
                    ) : (
                        <Typography className="fw-regular d-flex-all-center f-18" mt="20px">
                            No findings found...
                        </Typography>
                    )}
                </React.Fragment>}
        </ExamFormAccordion>
    );
})

export default ExamForm;
