import React, { useMemo } from "react";
import { Box, Grid, InputAdornment, Tooltip } from "@mui/material";
import { faCalendar, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import CollapsibleBox from "../../UIComponents/CollapsibleBox";
import TextBox from "../../UIComponents/TextBox";
import {
    updatePatientInfoAction,
    updatePreIntakeValidationsAction
} from "../../../../redux/actions/dentist/preIntakeActions";
import {
    formatPhoneNumber,
    phoneNumberPlaceholder,
    removeRefFromJson,
    usDateFormat,
} from "../../../../utils/helper";
import InputHeading from "../../UIComponents/InputHeading";
import StartAdornmentIcon from "../../UIComponents/StartAdornmentIcon";
import EndAdornmentIcon from "../../UIComponents/EndAdornmentIcon";
import DropDown from "../../UIComponents/DropDown";
import DateSelect from "../../UIComponents/DateSelect";
import { gender, race, sex } from "../../../../utils/constant/preIntakeConstant/preIntakeConstant";
import {
    patientInformationSecondValidator,
    patientInformationValidator,
    patientInformationValidatorWithoutAccount
} from '../../../../validation/preIntakeValidator';
import { textLengthStyles } from "../../../../pages/dentist/PreIntake";
import PreIntakeDisableInput from "../../../common/PreIntakeDisableInput";

type Props = {
    info?: string
    disabledField?: boolean
}
const PatientInformation = React.memo((props: Props) => {
    const { info, disabledField } = props
    const lengthClasses = textLengthStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const {
        preIntake: { patientInformation, errors },
    } = useSelector((state: any) => state)

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    const query = useQuery();
    const secondScreen = query.get('secondScreen') as any;

    const isHealthHistoryWithoutAccount = useMemo(() => {
        // if (secondScreen) {
        //     return false
        // } else {
        return location.pathname.includes('/access-health-history/')
        // }
    }, [location.pathname]) as boolean

    const isPatientHealthHistory = useMemo(() => {
        return location.pathname.includes('/patient/health-history')
    }, [location.pathname]) as boolean

    const isAnonymous = useMemo(() => {
        return location.pathname.includes('/anonymous-health-history/')
    }, [location.pathname]) as boolean

    const updateError = (name: string, value?: string) => {
        let updatedPatientInformation = removeRefFromJson(patientInformation);
        const patientInformationValid = patientInformationValidator(
            updatedPatientInformation
        ) as any;
        dispatch(
            updatePreIntakeValidationsAction({
                ...errors,
                patientInformation: {
                    ...errors.patientInformation,
                    [name === 'gender' ? 'genderOther' : name === 'race' ? "raceOther" : name]: value === 'Other' ? "" : patientInformationValid.errors[name],
                },
            })
        );
    }
    const handleChangeWithoutDebounce = (e: any) => {
        const { value, name } = e.target as any;
        let updatedPatientInformation = patientInformation;
        updatedPatientInformation[name] =
            name === "mobilePhoneNumber" || name === 'homePhoneNumber'
                ? formatPhoneNumber(value)
                : value;
        dispatch(updatePatientInfoAction(updatedPatientInformation));
        // for the update error
        // updateError(name)

        if (errors?.patientInformation?.[name]) {
            if (isAnonymous) {
                updateError(name, value)
            } else if (isHealthHistoryWithoutAccount) {
                let updatedPatientInformation = removeRefFromJson(patientInformation);
                const patientInformationValid = patientInformationValidatorWithoutAccount(
                    updatedPatientInformation
                ) as any;
                dispatch(
                    updatePreIntakeValidationsAction({
                        ...errors,
                        patientInformation: {
                            ...errors.patientInformation,
                            [name === 'gender' ? 'genderOther' : name === 'race' ? "raceOther" : name]: value === 'Other' ? "" : patientInformationValid.errors[name],
                        },
                    })
                );
            } else {
                const patientInformationValid = patientInformationSecondValidator(updatedPatientInformation) as any;
                dispatch(
                    updatePreIntakeValidationsAction({
                        ...errors,
                        patientInformation: {
                            ...errors.patientInformation,
                            [name === 'gender' ? 'genderOther' : name === 'race' ? "raceOther" : name]: value === 'Other' ? "" : patientInformationValid.errors[name],
                        },
                    })
                );
            }
        }
    };

    // const handleChange = handleChangeWithDebounce((e: any) => {
    //     const { value, name } = e.target as any;
    //     let updatedPatientInformation = removeRefFromJson(patientInformation);
    //     updatedPatientInformation[name] = value;
    //     dispatch(updatePatientInfoAction(updatedPatientInformation));
    //     // for the update error
    //     if (isAnonymous || isHealthHistoryWithoutAccount) {
    //         updateError(name, value)
    //     } else {
    //         const patientInformationValid = patientInformationSecondValidator(updatedPatientInformation) as any;
    //         dispatch(
    //             updatePreIntakeValidationsAction({
    //                 ...errors,
    //                 patientInformation: {
    //                     ...errors.patientInformation,
    //                     [name === 'gender' ? 'genderOther' : name === 'race' ? "raceOther" : name]: value === 'Other' ? "" : patientInformationValid.errors[name],
    //                 },
    //             })
    //         );
    //     }
    // });

    const handleChangeVal = (val?: any, key?: any) => {
        let updatedPatientInformation = patientInformation;
        updatedPatientInformation[key] = val;
        dispatch(updatePatientInfoAction(updatedPatientInformation));
        // for the update error
        updateError(key)
    };
    const clearClickHandler = (id: string) => {
        let updatedPatientInformation = patientInformation;
        updatedPatientInformation[id] = null;
        dispatch(updatePatientInfoAction(updatedPatientInformation));
    };

    const removeOtherValue = (e: any, id: any) => {
        const { value } = e.target as any;
        let updatedPatientInformation = patientInformation;
        updatedPatientInformation[id] = "";
        dispatch(updatePatientInfoAction(updatedPatientInformation));

        // for the update error
        const patientInformationValid = patientInformationSecondValidator(updatedPatientInformation) as any;
        dispatch(
            updatePreIntakeValidationsAction({
                ...errors,
                patientInformation: {
                    ...errors.patientInformation,
                    [id === 'gender' ? 'genderOther' : id === 'race' ? "raceOther" : id]: value === 'Other' ? "" : patientInformationValid.errors[id],
                },
            })
        );
    };

    return (
        <CollapsibleBox title={"Patient Information"} id={"patientInformation"} scrollBlock={'center'} info={info}>
            <Grid container rowSpacing={1.5} columnSpacing={2.5}>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    {(isHealthHistoryWithoutAccount || isPatientHealthHistory) ?
                        <PreIntakeDisableInput
                            value={patientInformation?.firstName}
                            label={"First Name"}
                            info={info}
                        />
                        :
                        <Tooltip title={!isAnonymous ? info : ''}>
                            <Box>
                                <TextBox
                                    label={"First Name"}
                                    labelProps={{
                                        htmlFor: "firstName",
                                    }}
                                    disabled={!isAnonymous}
                                    inputProps={{
                                        id: "firstName",
                                        name: "firstName",
                                        onChange: (e: any) => handleChangeWithoutDebounce(e),
                                        value: patientInformation?.firstName,
                                        error: errors.patientInformation?.firstName,
                                        helperText: errors.patientInformation?.firstName,
                                        fullWidth: true,
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    }
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    {(isHealthHistoryWithoutAccount || isPatientHealthHistory) ?
                        <PreIntakeDisableInput
                            value={patientInformation?.middleName}
                            label={"Middle Name"}
                            info={info}
                        />
                        :
                        <Tooltip title={!isAnonymous ? info : ''}>
                            <Box>
                                <TextBox
                                    label={"Middle Name"}
                                    labelProps={{
                                        htmlFor: "middleName",
                                    }}
                                    disabled={!isAnonymous}
                                    inputProps={{
                                        id: "middleName",
                                        name: "middleName",
                                        onChange: (e: any) => handleChangeWithoutDebounce(e),
                                        value: patientInformation?.middleName,
                                        error: errors.patientInformation?.middleName,
                                        helperText: errors.patientInformation?.middleName,
                                        fullWidth: true,
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    }
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    {(isHealthHistoryWithoutAccount || isPatientHealthHistory) ?
                        <PreIntakeDisableInput
                            value={patientInformation?.lastName}
                            label={"Last Name"}
                            info={info}
                        />
                        :
                        <Tooltip title={!isAnonymous ? info : ''}>
                            <Box>
                                <TextBox
                                    label={"Last Name"}
                                    labelProps={{
                                        htmlFor: "lastName",
                                    }}
                                    disabled={!isAnonymous}
                                    inputProps={{
                                        id: "lastName",
                                        name: "lastName",
                                        onChange: (e: any) => handleChangeWithoutDebounce(e),
                                        value: patientInformation?.lastName,
                                        error: errors.patientInformation?.lastName,
                                        helperText: errors.patientInformation?.lastName,
                                        fullWidth: true,
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    }
                </Grid>

                <Grid item xs={12} sm={6} md={8} lg={8} xl={8}>
                    {(isPatientHealthHistory) ?
                        <PreIntakeDisableInput
                            value={patientInformation?.emailAddress}
                            label={"Email Address"}
                            info={info}
                        />
                        :
                        <Tooltip title={!isHealthHistoryWithoutAccount ? info : ''}>
                            <Box>
                                <TextBox
                                    label={"Email Address"}
                                    labelProps={{
                                        htmlFor: "emailAddress",
                                    }}
                                    disabled={!isHealthHistoryWithoutAccount || secondScreen}
                                    inputProps={{
                                        id: "emailAddress",
                                        name: "emailAddress",
                                        type: "text",
                                        InputProps: {
                                            classes: {
                                                root: "border-radius-8 textFieldEndIconPadding bg-white",
                                                input: "textFieldEndIcon",
                                            },
                                            endAdornment: <EndAdornmentIcon icon={faEnvelope} disabled />,
                                        },
                                        onChange: (e: any) => handleChangeWithoutDebounce(e),
                                        value: patientInformation?.emailAddress,
                                        error: errors.patientInformation?.emailAddress,
                                        helperText: errors.patientInformation?.emailAddress,
                                        fullWidth: true,
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    }
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <Tooltip title={disabledField ? info : ''}>
                        <Box>
                            <InputHeading title={"Sex"} />
                            <DropDown
                                selectOptions={sex}
                                disabled={disabledField}
                                selectProps={{
                                    id: "sex",
                                    name: "sex",
                                    value: (patientInformation?.sex || ''),
                                    onChange: (e: any) => handleChangeWithoutDebounce(e),
                                    error: errors.patientInformation?.sex,
                                    helperText: errors.patientInformation?.sex,
                                }}
                            />
                        </Box>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6} md={patientInformation?.gender === 'Other' ? 3 : 6}
                    lg={patientInformation?.gender === 'Other' ? 3 : 6}
                    xl={patientInformation?.gender === 'Other' ? 3 : 6}>
                    <Tooltip title={disabledField ? info : ''}>
                        <Box>
                            <InputHeading title={"Gender"} />
                            <DropDown
                                disabled={disabledField}
                                selectOptions={gender}
                                prefixLabel={patientInformation?.gender === 'Other' ? ' - Please Specify' : ''}
                                selectProps={{
                                    id: "gender",
                                    name: "gender",
                                    value: (patientInformation?.gender || ''),
                                    onChange: (e: any) => {
                                        handleChangeWithoutDebounce(e);
                                        removeOtherValue(e, "genderOther");
                                    },
                                    error: errors.patientInformation?.gender,
                                    helperText: errors.patientInformation?.gender,
                                }}
                            />
                        </Box>
                    </Tooltip>
                </Grid>
                {patientInformation?.gender === 'Other' &&
                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                        <Tooltip title={disabledField ? info : ''}>
                            <Box>
                                <TextBox
                                    label={"Please Specify"}
                                    labelProps={{
                                        htmlFor: "genderOther",
                                    }}
                                    maxLength={30}
                                    disabled={patientInformation?.gender !== 'Other' || disabledField}
                                    inputProps={{
                                        id: "genderOther",
                                        name: "genderOther",
                                        placeholder: "Please Specify",
                                        onChange: (e: any) => handleChangeWithoutDebounce(e),
                                        value: patientInformation?.genderOther,
                                        error: errors.patientInformation?.genderOther,
                                        helperText: errors.patientInformation?.genderOther,
                                        fullWidth: true,
                                        InputProps: {
                                            classes: {
                                                root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                                input: "textFieldStartEndIcon",
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end"
                                                    className={lengthClasses.textLengthCount}>
                                                    {`${patientInformation?.genderOther?.length || 0} / 30`}
                                                </InputAdornment>
                                            )
                                        }
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    </Grid>
                }
                <Grid item xs={12} sm={6} md={patientInformation?.race === 'Other' ? 3 : 6}
                    lg={patientInformation?.race === 'Other' ? 3 : 6}
                    xl={patientInformation?.race === 'Other' ? 3 : 6}>
                    <Tooltip title={disabledField ? info : ''}>
                        <Box>
                            <InputHeading title={'Ethnic or Cultural Background'} />
                            <DropDown
                                disabled={disabledField}
                                selectOptions={race}
                                prefixLabel={patientInformation?.race === 'Southeast Asian' ? '(e.g., Vietnamese, Cambodian, Laotian, Thai)' :
                                    patientInformation?.race === 'South Asian' ? '(e.g., East Indian, Pakistani, Sri Lankan)' :
                                        patientInformation?.race === 'West Asian' ? '(e.g., Iranian, Afghan)' :
                                            patientInformation?.race === 'Other' ? ' - Please Specify' : ''}
                                selectProps={{
                                    id: "race",
                                    name: "race",
                                    value: (patientInformation?.race || ''),
                                    onChange: (e: any) => {
                                        handleChangeWithoutDebounce(e)
                                        removeOtherValue(e, "raceOther");
                                    },
                                    error: errors.patientInformation?.race,
                                    helperText: errors.patientInformation?.race,
                                }}
                            />
                        </Box>
                    </Tooltip>
                </Grid>
                {patientInformation?.race === 'Other' &&
                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                        <Tooltip title={disabledField ? info : ''}>
                            <Box>
                                <TextBox
                                    label={"Please Specify"}
                                    labelProps={{
                                        htmlFor: "raceOther",
                                    }}
                                    maxLength={100}
                                    disabled={patientInformation?.race !== 'Other'}
                                    inputProps={{
                                        id: "raceOther",
                                        name: "raceOther",
                                        placeholder: "Please Specify",
                                        onChange: (e: any) => handleChangeWithoutDebounce(e),
                                        value: patientInformation?.raceOther,
                                        error: errors.patientInformation?.raceOther,
                                        helperText: errors.patientInformation?.raceOther,
                                        fullWidth: true,
                                        InputProps: {
                                            classes: {
                                                root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                                input: "textFieldStartEndIcon",
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end"
                                                    className={lengthClasses.textLengthCount}>
                                                    {`${patientInformation?.raceOther?.length || 0} / 100`}
                                                </InputAdornment>
                                            )
                                        }
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    </Grid>
                }
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    {(isPatientHealthHistory) ?
                        <PreIntakeDisableInput
                            value={formatPhoneNumber(patientInformation?.mobilePhoneNumber)}
                            label={"Mobile Phone Number"}
                            info={info}
                            startIcon={"+1"}
                            endIcon={faPhone}
                        />
                        :
                        <Tooltip title={(!isAnonymous && !isHealthHistoryWithoutAccount) || secondScreen ? info : ''}>
                            <Box>
                                <TextBox
                                    label={"Mobile Phone Number"}
                                    labelProps={{
                                        htmlFor: "mobilePhoneNumber",
                                    }}
                                    maxLength={14}
                                    disabled={(!isAnonymous && !isHealthHistoryWithoutAccount) || secondScreen}
                                    inputProps={{
                                        placeholder: phoneNumberPlaceholder,
                                        id: "mobilePhoneNumber",
                                        name: "mobilePhoneNumber",
                                        InputProps: {
                                            classes: {
                                                root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                                input: "textFieldStartEndIcon",
                                            },
                                            startAdornment: <StartAdornmentIcon text={"+1"} disabled={(!isAnonymous && !isHealthHistoryWithoutAccount) || secondScreen} />,
                                            endAdornment: <EndAdornmentIcon icon={faPhone} disabled={(!isAnonymous && !isHealthHistoryWithoutAccount) || secondScreen} />,
                                        },
                                        onChange: (e: any) => handleChangeWithoutDebounce(e),
                                        value: formatPhoneNumber(patientInformation?.mobilePhoneNumber),
                                        error: errors.patientInformation?.mobilePhoneNumber,
                                        helperText: errors.patientInformation?.mobilePhoneNumber,
                                        fullWidth: true,
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    }
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    {(isHealthHistoryWithoutAccount || isPatientHealthHistory) ?
                        <PreIntakeDisableInput
                            value={formatPhoneNumber(patientInformation?.homePhoneNumber)}
                            label={"Home Phone Number"}
                            info={info}
                            startIcon={"+1"}
                            endIcon={faPhone}
                        />
                        :
                        <Tooltip title={!isAnonymous ? info : ''}>
                            <Box>
                                <TextBox
                                    label={"Home Phone Number"}
                                    labelProps={{
                                        htmlFor: "homePhoneNumber",
                                    }}
                                    disabled={!isAnonymous}
                                    maxLength={14}
                                    inputProps={{
                                        placeholder: phoneNumberPlaceholder,
                                        id: "homePhoneNumber",
                                        name: "homePhoneNumber",
                                        InputProps: {
                                            classes: {
                                                root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                                input: "textFieldStartEndIcon",
                                            },
                                            startAdornment: <StartAdornmentIcon text={"+1"} disabled={!isAnonymous} />,
                                            endAdornment: <EndAdornmentIcon icon={faPhone} disabled={!isAnonymous} />,
                                        },
                                        onChange: (e: any) => handleChangeWithoutDebounce(e),
                                        value: formatPhoneNumber(patientInformation?.homePhoneNumber),
                                        error: errors.patientInformation?.homePhoneNumber,
                                        helperText: errors.patientInformation?.homePhoneNumber,
                                        fullWidth: true,
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    }
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    {(isHealthHistoryWithoutAccount || isPatientHealthHistory) ?
                        <PreIntakeDisableInput
                            value={patientInformation?.dateOfBirth}
                            label={"Date of Birth"}
                            info={info}
                            endIcon={faCalendar}
                        />
                        :
                        <Tooltip title={!isAnonymous ? info : ''}>
                            <Box>
                                <InputHeading title={"Date of Birth"} />
                                <DateSelect
                                    disabled={!isAnonymous}
                                    value={patientInformation?.dateOfBirth || null}
                                    disableFuture
                                    clearClickHandler={() => clearClickHandler("dateOfBirth")}
                                    showClearIcon
                                    onChange={(newValue: any) => {
                                        let newFormatted = moment(new Date(newValue))?.format(
                                            usDateFormat
                                        );
                                        handleChangeVal(newFormatted, "dateOfBirth");
                                    }}
                                    inputProps={{
                                        id: 'dateOfBirth',
                                        name: 'dateOfBirth',
                                        error: errors?.patientInformation?.dateOfBirth,
                                        helperText: errors?.patientInformation?.dateOfBirth,
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    {(isHealthHistoryWithoutAccount || isPatientHealthHistory) ?
                        <PreIntakeDisableInput
                            value={patientInformation?.address}
                            label={"Address"}
                            info={info}
                        />
                        :
                        <Tooltip title={!isAnonymous ? info : ''}>
                            <Box>
                                <TextBox
                                    label={"Address"}
                                    labelProps={{
                                        htmlFor: "address",
                                    }}
                                    disabled={!isAnonymous}
                                    inputProps={{
                                        id: "address",
                                        name: "address",
                                        onChange: (e: any) => handleChangeWithoutDebounce(e),
                                        value: patientInformation?.address,
                                        error: false,
                                        helperText: "",
                                        fullWidth: true,
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    }
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    {(isHealthHistoryWithoutAccount || isPatientHealthHistory) ?
                        <PreIntakeDisableInput
                            value={patientInformation?.city}
                            label={"City"}
                            info={info}
                        />
                        :
                        <Tooltip title={!isAnonymous ? info : ''}>
                            <Box>
                                <TextBox
                                    label={"City"}
                                    labelProps={{
                                        htmlFor: "city",
                                    }}
                                    disabled={!isAnonymous}
                                    inputProps={{
                                        id: "city",
                                        name: "city",
                                        onChange: (e: any) => handleChangeWithoutDebounce(e),
                                        value: patientInformation?.city,
                                        error: false,
                                        helperText: "",
                                        fullWidth: true,
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    }
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    {(isHealthHistoryWithoutAccount || isPatientHealthHistory) ?
                        <PreIntakeDisableInput
                            value={patientInformation?.postalCode}
                            label={"Zip/Postal Code"}
                            info={info}
                        />
                        :
                        <Tooltip title={!isAnonymous ? info : ''}>
                            <Box>
                                <TextBox
                                    label={"Zip/Postal Code"}
                                    labelProps={{
                                        htmlFor: "postalCode",
                                    }}

                                    disabled={!isAnonymous}
                                    inputProps={{
                                        id: "postalCode",
                                        name: "postalCode",
                                        onChange: (e: any) => handleChangeWithoutDebounce(e),
                                        value: patientInformation?.postalCode,
                                        error: errors.patientInformation?.postalCode,
                                        helperText: errors.patientInformation?.postalCode,
                                        fullWidth: true,
                                        maxLength: 6,
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    }
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    {(isHealthHistoryWithoutAccount || isPatientHealthHistory) ?
                        <PreIntakeDisableInput
                            value={patientInformation?.state}
                            label={"State/Province"}
                            info={info}
                        />
                        :
                        <Tooltip title={!isAnonymous ? info : ''}>
                            <Box>
                                <TextBox
                                    label={"State/Province"}
                                    labelProps={{
                                        htmlFor: "state",
                                    }}
                                    disabled={!isAnonymous}
                                    inputProps={{
                                        id: "state",
                                        name: "state",
                                        onChange: (e: any) => handleChangeWithoutDebounce(e),
                                        value: patientInformation?.state,
                                        error: false,
                                        helperText: "",
                                        fullWidth: true,
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    }
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    {(isHealthHistoryWithoutAccount || isPatientHealthHistory) ?
                        <PreIntakeDisableInput
                            value={patientInformation?.country}
                            label={"Country"}
                            info={info}
                        />
                        :
                        <Tooltip title={!isAnonymous ? info : ''}>
                            <Box>
                                <TextBox
                                    label={"Country"}
                                    labelProps={{
                                        htmlFor: "country",
                                    }}
                                    disabled={!isAnonymous}
                                    inputProps={{
                                        id: "country",
                                        name: "country",
                                        onChange: (e: any) => handleChangeWithoutDebounce(e),
                                        value: patientInformation?.country,
                                        error: false,
                                        helperText: "",
                                        fullWidth: true,
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    }
                </Grid>
            </Grid>
        </CollapsibleBox>
    );
})

export default PatientInformation;
