import React, {useCallback, useRef, useState} from 'react';
import {useReactToPrint} from "react-to-print";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import PrintIcon from "@mui/icons-material/Print";
import {makeStyles} from "@mui/styles";
import {QRCodeSVG} from "qrcode.react";
import {Box, Button, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";
import theme from "../../../theme";
import QIcon from "../../../assets/images/QLogo.png";
import OraQIcon from "../../../assets/images/logo.png";
import {getLocalStorageItem} from "../../../utils/helper";

const useStyles = makeStyles(() => ({
    cancelButton: {
        borderColor: theme.palette.v2.primary.main, color: theme.palette.v2.primary.main
    },
    PrintButton: {
        background: theme.palette.v2.primary.main
    },
    dialogContentClass: {
        backgroundColor: 'rgba(207, 239, 234, 0.30)'
    },
    QrContainer: {
        border: `2px solid ${theme.palette.v2.primary.main}`
    },
    actionPadding: {
        padding: '20px'
    }
}))

const AccessCodeQR = () => {
    const classes = useStyles()
    const [open, setOpen] = useState(false) as any
    const qrCodeRef = useRef(null) as any
    const clinicInfo = JSON.parse(localStorage.getItem("clinicInfo") || '')

    const reactToPrintContent = useCallback(() => {
        return qrCodeRef?.current;
        // eslint-disable-next-line
    }, [qrCodeRef?.current]);

    const pageStyle = `
      @media print {
      body {
        width: 391px; /* Set the desired fixed width for the printed content */
      }
    }
  `;
    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "health-history QR code",
        pageStyle: pageStyle
    });


    return (
        <div>
            <QrCodeScannerIcon onClick={() => setOpen(true)} sx={{marginRight: 1, cursor: 'pointer'}}/>
            <Dialog
                sx={{
                    '& .MuiDialogContent-root': {
                        padding: '0px !important',
                    },
                    '& .MuiPaper-root ': {
                        width: '391px !important',
                        background: `${theme.palette.common.white}!important`,
                        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.07)',
                        borderRadius: '8px !important',
                        display: 'flex !important',
                        alignItems: 'center !important',
                        justifyContent: 'center !important',
                    },
                }}
                open={open}
                onClose={() => setOpen(false)}>
                <DialogActions className={`w-100 justify-start d-flex ${classes.actionPadding}`}>
                    <img width='20px' height='20px' src={QIcon} alt='OraQ'/>
                    <Typography className={`fw-medium f-14`} color={theme.palette.common.black50} ml={1}>Scan or Print
                        the QR Code</Typography>
                </DialogActions>
                <DialogContent className={`w-100 ${classes.dialogContentClass}`}>
                    <div ref={qrCodeRef} style={{width: '391px !important'}}
                         className={`${classes.dialogContentClass}`}>
                        <Box className={classes.QrContainer}>
                            <Box className='d-flex flex-column' p={'24px 24px 14px 24px'}>
                                <Typography className='fw-regular f-26 f-w-500' color={theme.palette.v2.primary.main}
                                            mb={1.5}>{clinicInfo?.clinic_name}</Typography>
                                <Typography className='fw-regular f-16 f-w-400' color={theme.palette.common.black50}
                                            mb={1.2}>Scan to Start your Health History Exam</Typography>
                            </Box>
                            <Box className='d-flex justify-center bg-white' p={'24px'}>
                                <QRCodeSVG
                                    height={'338px'}
                                    width={'338px'}
                                    value={`${process.env.REACT_APP_DENTIST_SITE}/patient/verify-access-code/${getLocalStorageItem('clinic')}`}
                                />
                            </Box>
                            <Box className='d-flex align-center justify-start' p={'14px 24px 24px 24px'}>
                                <Typography mt={'-2px'} className='fw-regular f-14 f-w-400 pr-5'
                                            color={theme.palette.common.black50}>Powered by</Typography>
                                <img src={OraQIcon} width={'39px'} height={'14px'} alt='OraQ'/>
                            </Box>
                        </Box>
                    </div>
                </DialogContent>
                <DialogActions className={`w-100 ${classes.actionPadding}`}>
                    <Box className='w-100 d-flex justify-content-between align-center'>
                        <Button
                            variant='outlined'
                            color="primary"
                            className={`border-radius-4 ${classes.cancelButton}`}
                            onClick={() => setOpen(false)}>
                            Close
                        </Button>
                        <Button onClick={handlePrint} variant='contained' startIcon={<PrintIcon/>}
                                className={`border-radius-4 ${classes.PrintButton}`}>
                            Print A Copy
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default AccessCodeQR;