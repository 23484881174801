import React from 'react';
import {
  Avatar,
  Box,
  Card,
  Container,
  IconButton,
  Typography,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Theme,
} from '@mui/material';
import {
  ChevronRight,
  AccessTime as AccessTimeIcon
} from '@mui/icons-material';

type DashboardHeaderProps = {
  title: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
};

const DashboardHeader: React.FC<DashboardHeaderProps> = ({children, title, icon}) => (
    <Box
    sx={(theme: any) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 1,
        borderBottom: 1,
        borderColor: 'divider',
        bgcolor: theme.palette.primary.greenLight // or any other theme color
        // You can also use the shorthand: bgcolor: 'primary.main'
      })}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {icon}
        <Typography sx={{ color: (theme: any) => theme.palette.v2.primary.main }} className='fw-regular' variant="h6">{title}</Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        {children}
      </Box>
    </Box>
  );

  export default DashboardHeader;