import { APPOINTMENT_ACTIONS } from "../../../actionTypes/nextgen/dentist/dashboard";


const initialState: any = {
    data: [],
    meta: null,
    loading: false,
    error: null
};


export const appointmentReducer = (state = initialState, action: any): any => {
    switch (action.type) {
      case APPOINTMENT_ACTIONS.FETCH_DENTIST_APPOINTMENTS_REQUEST:
        if (action.payload?.params?.isMoreFetch) {
          return {
            ...state,
            loading: false,
            isMoreLoading: true,
            error: null
          };
        }
        return {
          ...state,
          loading: true,
          error: null
        };
  
      case APPOINTMENT_ACTIONS.FETCH_DENTIST_APPOINTMENTS_SUCCESS:
        if (action.payload?.params?.isMoreFetch) {
          return {
            ...state,
            loading: false,
            isMoreLoading: false,
            data: [...state.data, ...action.payload?.newAppointment_data],
            meta: {...state.meta, ...action.payload?.newAppointment_meta},
            error: null
          };
        }
        return {
          ...state,
          loading: false,
          isMoreLoading: false,
          data: action.payload.newAppointment_data,
          meta: action.payload.newAppointment_meta,
          error: null
        };
        
  
      case APPOINTMENT_ACTIONS.FETCH_DENTIST_APPOINTMENTS_FAILURE:
        return {
          ...state,
          loading: false,
          isMoreLoading: false,
          error: action.payload.error
        };
  
      default:
        return state;
    }
  };