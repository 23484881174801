// import { APPOINTMENT_ACTIONS } from './types';
// import { 
//   dentistNewAppointmentRequestSuccess, 
//   dentistNewAppointmentRequestFailure 
// } from './actions';
// import { API } from '../services/api';
// import { checkAPIParams, newDateFormate, errorToaster } from '../utils';
// import { ApiEndpointVersion } from '../config';

import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiEndpointVersion, checkAPIParams, errorToaster, newDateFormate } from "../../../../utils/helper";
import { APPOINTMENT_ACTIONS } from "../../../actionTypes/nextgen/dentist/dashboard";
import { dentistNewAppointmentRequestFailure, dentistNewAppointmentRequestSuccess } from "../../../actions/nextgen/dentist/dashboard";
import API from '../../../../utils/api';

interface AppointmentParams {
  page: number;
  size: number;
  clinic_id: string;
  search?: string;
  from_date?: string;
  to_date?: string;
  role?: string;
  staff_id?: string;
  status?: string;
  appointment_type?: string;
  sort_by?: string;
  desc?: boolean;
}

interface FetchAppointmentAction {
  type: typeof APPOINTMENT_ACTIONS.FETCH_DENTIST_APPOINTMENTS_REQUEST;
  payload: {
    params: AppointmentParams;
    callback?: (data: any) => void;
  };
}

function* dentistNewAppointmentRequestSaga(action: FetchAppointmentAction): Generator<any, void, any> {
  try {
    const { params, callback } = action.payload;
    // Transform params into API format
    const apiParams = [
      { key: 'search', value: params?.search },
      { key: 'page', value: params?.page },
      { key: 'size', value: params?.size },
      { key: 'clinic_id', value: params?.clinic_id },
      { key: 'from_date', value: params?.from_date ? newDateFormate(params?.from_date) : undefined },
      { key: 'to_date', value: params?.to_date ? newDateFormate(params?.to_date) : undefined },
      { key: 'role', value: params?.role },
      { key: 'staff_id', value: params?.staff_id },
      { key: 'status', value: params?.status?.toString() },
      { key: 'appointment_type', value: params?.appointment_type },
      { key: 'sort_by', value: params?.sort_by },
      { key: 'desc', value: params?.desc }
    ].filter(param => param.value !== undefined);

    // Make API call
    const response = yield call(
      API.get,
      `${ApiEndpointVersion}/appointments/all/staff${checkAPIParams(apiParams)}`
    );

    // Handle callback if provided
    if (callback) {
      yield call(callback, response?.data);
    }

    // Dispatch success action
    yield put(
      dentistNewAppointmentRequestSuccess({
        newAppointment_data: response.data?.detail,
        newAppointment_meta: response.data?.meta,
        params 
      })
    );
  } catch (e: any) {
    console.error(e);
    // Show error toast
    errorToaster(e?.meta?.message);
    
    // Dispatch failure action
    yield put(
      dentistNewAppointmentRequestFailure({
        error: e?.meta?.message,
      })
    );
  }
}


// Root saga for appointments
export function* appointmentSaga() {
    // Cancel previous request if new one comes in
    yield takeLatest(
      APPOINTMENT_ACTIONS.FETCH_DENTIST_APPOINTMENTS_REQUEST, 
      dentistNewAppointmentRequestSaga
    );
  }
  

export function* rootAppointmentSaga() {
    yield all([
      appointmentSaga(),
      // Add other sagas here
      // takeLatest('BATCH_UPDATE_REQUEST', batchAppointmentUpdatesSaga),
      // fork(appointmentSocketSaga)
    ]);
  }