import React, { useMemo } from "react";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  useTheme,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { StatusIndicator } from "../StatusIndicator";

interface InfoCardProps {
  name: string;
  completed: boolean;
  variant?: "current" | "upcoming";
  data?: any;
  clickHandler?: () => void;
}

const InfoCard: React.FC<InfoCardProps> = React.memo(
  ({ name, variant = "current", data, clickHandler }) => {
    const completed = useMemo(
      () => data.pre_intake_status.toLowerCase() !== "health history pending",
      [data]
    );

    const theme: any = useTheme();
    const initials = name
      .split(" ")
      .map((n) => n[0])
      .join("");

    const computeBgColor = useMemo(() => {
      if (variant === "current") {
        return theme.palette.common.white;
      }

      const status = data.pre_intake_status.toLowerCase();

      const statusColorMap: any = {
        "health history completed": theme.palette.primary.green,
        "health history pending": data.is_invitation_sent
          ? theme.palette.primary.yellow
          : theme.palette.primary.purple,
      };

      return statusColorMap[status] || theme.palette.common.white;
    }, [data, variant]);

    const computeHoverColor = useMemo(() => {
      const status = data.pre_intake_status.toLowerCase();
      const isHealthHistoryCompleted = status === "health history completed";
      const isHealthHistoryPending = status === "health history pending";
      const { is_invitation_sent } = data;

      if (!is_invitation_sent && !isHealthHistoryCompleted) {
        return theme.palette.hover.purple;
      } else if (isHealthHistoryCompleted) {
        return theme.palette.hover.green;
      } else if (is_invitation_sent && isHealthHistoryPending) {
        return theme.palette.hover.yellow;
      }

      return theme.palette.hover.yellow;
    }, [data, variant]);

    const computeAvatarColor = (theme: any) => {
      const status = data.pre_intake_status.toLowerCase();
      const isHealthHistoryCompleted = status === "health history completed";
      const isHealthHistoryPending = status === "health history pending";
      const { is_invitation_sent } = data;

      if (!is_invitation_sent && !isHealthHistoryCompleted) {
        return theme.palette.avatar.completed;
      } else if (isHealthHistoryCompleted) {
        return theme.palette.avatar.notCompleted;
      } else if (is_invitation_sent && isHealthHistoryPending) {
        return theme.palette.avatar.amber;
      }

      return theme.palette.avatar.amber;
    };

    const computeColor = useMemo(() => {
      const status = data.pre_intake_status.toLowerCase();
      const isHealthHistoryCompleted = status === "health history completed";
      const isHealthHistoryPending = status === "health history pending";
      const { is_invitation_sent } = data;

      if (!is_invitation_sent && !isHealthHistoryCompleted) {
        return theme.palette?.text.purple;
      } else if (isHealthHistoryCompleted) {
        return theme.palette?.text.green;
      } else if (is_invitation_sent && isHealthHistoryPending) {
        return theme.palette?.text.amber;
      }

      return theme.palette?.text.amber;
    }, [data]);
    return (
      <ListItem
        onClick={clickHandler}
        sx={(theme: any) => ({
          bgcolor: computeBgColor,
          borderRadius: 1,
          mb: 1,
          p: variant === "current" ? 1.5 : 2, // Adjust padding based on variant
          cursor: "pointer",
          transition: theme.transitions.create(["background-color"]),
          "&:hover": { bgcolor: computeHoverColor },
        })}
      >
        <ListItemAvatar>
          <Avatar
            sx={(theme: any) => ({
              bgcolor: computeAvatarColor(theme),
              color: completed ? "success.dark" : "primary.dark",
              width: variant === "current" ? 36 : 48, // Adjust size based on variant
              height: variant === "current" ? 36 : 48,
            })}
          >
            <Typography
              className="fw-medium"
              sx={{ color: (theme: any) => theme.palette.common.black }}
            >
              {initials}
            </Typography>
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          sx={{
            color: (theme: any) => theme.palette.common.black,
            px: variant === "upcoming" ? 2 : 0,
          }}
          primaryTypographyProps={{
            className:
              variant === "current" ? "fw-medium f-14" : "fw-medium f-16",
          }}
          primary={name}
          secondary={
            variant !== "current" ? (
              <StatusIndicator
                data={data}
                className={{
                  primary: "fw-regular f-14",
                  secondary: "fw-medium f-12",
                }}
                completed={completed}
              />
            ) : (
              <StatusIndicator
                data={data}
                className={{
                  color: completed ? theme?.palette?.text?.amber : undefined,
                }}
                completed={completed}
              />
            )
          }
        />

        <ListItemSecondaryAction>
          <IconButton edge="end">
            <ChevronRight
              sx={(theme: any) => ({
                color:
                  variant === "upcoming"
                    ? computeColor
                    : theme.palette.text.secondary,
              })}
            />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
);

export default InfoCard;
