import React from 'react';
import {Box, Chip, Grid, InputLabel, Stack, Tooltip} from "@mui/material";
import InputHeading from "../../common/UIComponents/InputHeading";
import DropDown from "../../common/UIComponents/DropDown";
import {
    examCycleDropdown,
    gingivalExtentDropdown, gingivalStatusDropdown,
    gradingDropdown,
    hygieneCycleDropdown,
    stagingDropdown, xRayCycleDropdown
} from "../../../utils/constant/newAdminHandoffConstant";
import theme from "../../../theme";
import TextBox from "../../common/UIComponents/TextBox";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    tooltip: {
        fontSize: "12px",
        fontFamily: "FavoritPro-Regular"
    },
}))
type Props = {
    hygieneRegimeData: any
    disabled: boolean
    handleChange?: any
}
const HygieneRegime = (props: Props) => {
    const {hygieneRegimeData, disabled, handleChange} = props
    const {palette} = theme
    const classes = useStyles();

    return (
            <React.Fragment>
                <Grid container rowSpacing={1.5} columnSpacing={2.5} mb={2.5}>
                    <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                        <InputHeading title={'Gingival Status'}/>
                        <DropDown
                            selectOptions={gingivalStatusDropdown}
                            inputBackColor={theme?.palette?.common?.white}
                            disabled={disabled}
                            selectProps={{
                                name: 'gingival_status',
                                value: hygieneRegimeData?.gingival_status,
                                onChange: (e: any) => handleChange(e),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                        <InputHeading title={'Staging'}/>
                        <DropDown
                            selectOptions={stagingDropdown}
                            inputBackColor={theme?.palette?.common?.white}
                            disabled={disabled}
                            selectProps={{
                                name: 'staging',
                                value: hygieneRegimeData?.staging || '',
                                onChange: (e: any) => handleChange(e),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                        <InputHeading title={'Grading'}/>
                        <DropDown
                            selectOptions={gradingDropdown}
                            inputBackColor={theme?.palette?.common?.white}
                            disabled={disabled}
                            selectProps={{
                                name: 'grading',
                                value: hygieneRegimeData?.grading || '',
                                onChange: (e: any) => handleChange(e),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                        <InputHeading title={'Extent'}/>
                        <DropDown
                            selectOptions={gingivalExtentDropdown}
                            inputBackColor={theme?.palette?.common?.white}
                            disabled={disabled}
                            selectProps={{
                                name: 'extent',
                                value: hygieneRegimeData?.extent || '',
                                onChange: (e: any) => handleChange(e),
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1.5} columnSpacing={2.5}>
                    {hygieneRegimeData &&
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <InputHeading title={'Hygiene Cycle'}/>
                            <DropDown
                                selectOptions={hygieneCycleDropdown}
                                inputBackColor={theme?.palette?.common?.white}
                                disabled={disabled}
                                prefixLabel={"Months"}
                                selectProps={{
                                    name: 'hygiene_cycle',
                                    value: hygieneRegimeData?.hygiene_cycle || '',
                                    onChange: (e: any) => handleChange(e),
                                }}
                            />
                        </Grid>
                    }

                    {hygieneRegimeData &&
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <InputHeading title={'Exam Cycle'}/>
                            <DropDown
                                selectOptions={examCycleDropdown}
                                inputBackColor={theme?.palette?.common?.white}
                                disabled={disabled}
                                prefixLabel={"Months"}
                                selectProps={{
                                    name: 'exam_cycle',
                                    value: hygieneRegimeData?.exam_cycle || '',
                                    onChange: (e: any) => handleChange(e),
                                }}
                            />
                        </Grid>
                    }
                    {hygieneRegimeData &&
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <InputHeading title={'X-Ray Cycle'}/>
                            <DropDown
                                selectOptions={xRayCycleDropdown}
                                inputBackColor={theme?.palette?.common?.white}
                                disabled={disabled}
                                prefixLabel={"Months"}
                                selectProps={{
                                    name: 'xray_cycle',
                                    value: hygieneRegimeData?.xray_cycle || '',
                                    onChange: (e: any) => handleChange(e),
                                }}
                            />
                        </Grid>
                    }

                    {hygieneRegimeData?.adjunctive?.length ?
                        <Grid item xs={12}>
                            <InputLabel
                                htmlFor="adjunctive"
                                sx={{mb: "8px"}}
                                className={`labelColor fw-regular f-14 lh-19 f-w-350`}
                            >
                                Adjunctive
                            </InputLabel>
                            <Stack direction="row" gap={1} p={'12px'}
                                   border={`1px solid ${palette.divider}`} borderRadius={'8px'}
                                   flexWrap={'wrap'}>
                                {hygieneRegimeData?.adjunctive?.map((res: any, index: number) =>
                                    <Tooltip title={res} key={index} className={'fw-regular'}
                                             classes={{tooltip: classes.tooltip}}>
                                        <Box
                                            sx={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <Chip label={res} component="a"
                                                  className={'fw-regular'}
                                                  sx={{
                                                      textOverflow: 'ellipsis',
                                                      whiteSpace: 'nowrap',
                                                      overflow: 'hidden',
                                                      width: '170px',
                                                      '.MuiChip-root': {
                                                          color: palette.common.black50,
                                                          backgroundColor: palette.divider
                                                      }
                                                  }}
                                            />
                                        </Box>
                                    </Tooltip>
                                )}
                            </Stack>
                        </Grid>
                        : null
                    }
                    <Grid item xs={12}>
                        <TextBox
                            label={'Notes'}
                            labelProps={{
                                htmlFor: 'notes'
                            }}
                            isPadding={true}
                            disabled={disabled}
                            maxLength={1000}
                            inputProps={{
                                id: 'notes',
                                name: 'notes',
                                onChange: (e: any) => handleChange(e),
                                value: hygieneRegimeData?.notes,
                                error: false,
                                helperText: '',
                                fullWidth: true,
                                wrap: true,
                                InputProps: {
                                    classes: {
                                        root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                        input: "textFieldStartEndIcon",
                                    }
                                }
                            }}
                        />

                    </Grid>
                </Grid>
            </React.Fragment>
    )
}

export default HygieneRegime;