import React, { useEffect, useRef } from "react";
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { IMaskInput } from "react-imask";
import theme from "../../../../theme";
import CollapsibleBox from "../../UIComponents/CollapsibleBox";
import TextBox from "../../UIComponents/TextBox";
import {
  updateGeneralHealthAction,
  updatePreIntakeValidationsAction,
} from "../../../../redux/actions/dentist/preIntakeActions";
import RadioSelect from "../../UIComponents/RadioSelect";
import {
  convertToLowerCase,
  handleChangeWithDebounce,
} from "../../../../utils/helper";
import InputHeading from "../../UIComponents/InputHeading";
import DropDown from "../../UIComponents/DropDown";
import {
  alcoholUsesOption,
  heightUnitOptions,
  historyOfSmokingOption,
  radioButtonOptions,
  weightUnitOptions,
} from "../../../../utils/constant/preIntakeConstant/preIntakeConstant";
import { generalHealthValidator } from "../../../../validation/preIntakeValidator";
import { RootState } from "../../../../redux/reducers/rootReducer";
import { textLengthStyles } from "../../../../pages/dentist/PreIntake";
import { AIAlert } from "../../../nextgen/common";

const useStyles = makeStyles((theme: any) => ({
  radioButton: {
    height: "50px",
    padding: "0 10px 0 0",
    margin: 0,
    color: theme.palette.common.black50,
    "& .MuiTypography-root": {
      fontSize: `14px !important`,
      fontFamily: "FavoritPro-Regular",
    },
  },
}));

type Props = {
  disabledField?: boolean;
  info?: string;
};
const GeneralHealth = React.memo((props: Props) => {
  const { disabledField, info = "" } = props;
  const classes = useStyles();
  const lengthClasses = textLengthStyles();
  const dispatch = useDispatch();
  const weightRef: any = useRef(null);
  const heightCMRef: any = useRef(null);
  const heightInchRef: any = useRef(null);
  const heightFtRef: any = useRef(null);
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const exceptSymbols = ["e", "E", "+", "-"];
  const { generalHealth, errors, preIntakeData } = useSelector(
    (state: RootState) => state.preIntake
  );

  

  useEffect(() => {
    const handleWheel = function (e: any) {
      return e.preventDefault();
    };
    heightInchRef?.current?.addEventListener("wheel", handleWheel);
    heightFtRef?.current?.addEventListener("wheel", handleWheel);
    weightRef?.current?.addEventListener("wheel", handleWheel);
    heightCMRef?.current?.addEventListener("wheel", handleWheel);
    return () => {
      // eslint-disable-next-line
      heightInchRef?.current?.removeEventListener("wheel", handleWheel); // eslint-disable-next-line
      heightFtRef?.current?.removeEventListener("wheel", handleWheel); // eslint-disable-next-line
      weightRef?.current?.removeEventListener("wheel", handleWheel); // eslint-disable-next-line
      heightCMRef?.current?.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const handleChange = handleChangeWithDebounce((e: any) => {
    const { value, name, id } = e.target as any;
    let updatedGeneralHealth = generalHealth;
    updatedGeneralHealth[name || id] = value;
    dispatch(updateGeneralHealthAction(updatedGeneralHealth));

    // for the update error
    if (errors?.generalHealth?.[name || id]) {
      const generalHealthValid = generalHealthValidator(
        updatedGeneralHealth
      ) as any;
      dispatch(
        updatePreIntakeValidationsAction({
          ...errors,
          generalHealth: {
            ...errors.generalHealth,
            [name || id]: errors.generalHealth[name || id]
              ? generalHealthValid.errors[name || id]
              : "",
          },
        })
      );
    }
  });

  const handleChangeWithoutDebounce = (e: any) => {
    const { value, name, id } = e.target as any;
    let updatedGeneralHealth = generalHealth;
    updatedGeneralHealth[name || id] = value;
    dispatch(updateGeneralHealthAction(updatedGeneralHealth));

    // for the update error
    if (errors?.generalHealth?.[name || id]) {
      const generalHealthValid = generalHealthValidator(
        updatedGeneralHealth
      ) as any;
      dispatch(
        updatePreIntakeValidationsAction({
          ...errors,
          generalHealth: {
            ...errors.generalHealth,
            [name || id]: errors.generalHealth[name || id]
              ? generalHealthValid.errors[name || id]
              : "",
          },
        })
      );
    }
  };

  const removeOtherValue = (id: any) => {
    let updatedGeneralHealth = generalHealth;
    updatedGeneralHealth.drugInputUse = "";
    dispatch(updateGeneralHealthAction(updatedGeneralHealth));

    // for the update error
    if (errors?.generalHealth?.[id]) {
      const generalHealthValid = generalHealthValidator(
        updatedGeneralHealth
      ) as any;
      dispatch(
        updatePreIntakeValidationsAction({
          ...errors,
          generalHealth: {
            ...errors.generalHealth,
            [id]: errors.generalHealth[id] ? generalHealthValid.errors[id] : "",
          },
        })
      );
    }
  };

  const oneMaskDefinitions = {
    mask: "#",
    definitions: {
      "#": /[0-9]/,
    },
  };

  const twoMaskDefinitions = {
    mask: "##",
    definitions: {
      "#": /[0-9]/,
    },
  };

  const threeMaskDefinitions = {
    mask: "###",
    definitions: {
      "#": /[0-9]/,
    },
  };
  return (
    <>
      {preIntakeData?.is_modified_by_ai && <AIAlert message={info} severity="success" />}
      <CollapsibleBox
        title={"General Health"}
        id={"generalHealth"}
        scrollBlock={"end"}
        info={info}
      >
        <Grid container rowSpacing={1.5} columnSpacing={2.5}>
          <Grid item xs={12}>
            <Typography
              mb={"8px"}
              className={`lh-25 f-w-500 f-14 labelColor fw-medium`}
            >
              To help us deliver dental care tailored to your needs, please
              answer the following questions regarding your general health.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider
              color={theme.palette.common.borderGrey}
              sx={{ marginBottom: "8px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputHeading title={"History of Smoking"} />
            <RadioSelect
              disabled={disabledField}
              options={historyOfSmokingOption}
              groupProps={{
                name: "historyOfSmoking",
                id: "historyOfSmoking",
                defaultValue: generalHealth?.historyOfSmoking,
                onChange: (e: any) => handleChange(e),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <InputHeading
              title={"How Much Alcohol Do You Drink In An Average Week?"}
            />
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              ml={"20px"}
              p={"12px"}
              border={`1px solid ${theme.palette.common.borderGrey}`}
              borderRadius={"8px"}
            >
              <RadioSelect
                renderOptions={alcoholUsesOption?.map((res: any, i: any) => (
                  <Grid item xs={12} sm={4} key={i}>
                    <FormControlLabel
                      disabled={disabledField}
                      value={res?.value}
                      control={<Radio />}
                      sx={{
                        "& .MuiButtonBase-root": {
                          color: disabledField
                            ? theme.palette.text.secondary
                            : theme.palette.common.black50,
                        },
                        "& .Mui-checked": {
                          color: disabledField
                            ? theme.palette.text.secondary
                            : `${theme.palette.v2.primary.main} !important`,
                        },
                      }}
                      label={res?.label}
                      className={`${classes.radioButton} fw-regular`}
                    />
                  </Grid>
                ))}
                groupProps={{
                  name: "alcoholAverageWeek",
                  id: "alcoholAverageWeek",
                  defaultValue: generalHealth?.alcoholAverageWeek,
                  onChange: (e: any) => handleChange(e),
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputHeading title={"Recreational Drug Use"} />
            <Box display={"flex"}>
              <RadioSelect
                sx={{ flexWrap: "nowrap", marginRight: "14px !important" }}
                disabled={disabledField}
                options={radioButtonOptions}
                groupProps={{
                  name: "drugUse",
                  id: "drugUse",
                  defaultValue: generalHealth?.drugUse,
                  onChange: (e: any) => {
                    handleChange(e);
                    removeOtherValue("drugInputUse");
                  },
                }}
              />
              {convertToLowerCase(generalHealth?.drugUse) === "yes" && (
                <Grid item xs={12} sm={12} md={7} lg={5}>
                  <TextBox
                    disabled={disabledField}
                    placeholder="Recreational Drug Use"
                    maxLength={50}
                    inputProps={{
                      id: "drugInputUse",
                      name: "drugInputUse",
                      onChange: (e: any) => handleChange(e),
                      defaultValue: generalHealth?.drugInputUse,
                      error: errors?.generalHealth?.drugInputUse,
                      helperText: errors?.generalHealth?.drugInputUse,
                      fullWidth: true,
                      InputProps: {
                        classes: {
                          root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                          input: "textFieldStartEndIcon",
                        },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            className={lengthClasses.textLengthCount}
                          >
                            {`${generalHealth?.drugInputUse?.length || 0} / 50`}
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                </Grid>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <InputHeading title={"Height Unit"} />
            <DropDown
              disabled={disabledField}
              selectOptions={heightUnitOptions}
              selectProps={{
                id: "heightUnit",
                name: "heightUnit",
                value: generalHealth?.heightUnit || "ft",
                onChange: (e: any) => {
                  handleChange(e);
                  const { value } = e.target as any;
                  let updatedGeneralHealth = generalHealth;
                  updatedGeneralHealth["weightUnit"] =
                    value === "ft" ? "lb" : "kg";
                  dispatch(updateGeneralHealthAction(updatedGeneralHealth));
                },
              }}
            />
          </Grid>

          {generalHealth?.heightUnit === "ft" ? (
            <Grid item xs={12} sm={4} md={2}>
              <TextBox
                disabled={disabledField}
                label={`${
                  generalHealth?.heightUnit === "ft" ? "Feet" : "Centimeters"
                }`}
                labelProps={{
                  htmlFor: "heightFT",
                }}
                inputProps={{
                  InputProps: {
                    classes: {
                      root: "border-radius-8 textFieldEndIconPadding bg-white",
                      input: "textFieldEndIcon",
                    },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: "0px",
                          maxHeight: "",
                          marginRight: "13px",
                        }}
                      >
                        <IconButton
                          disableFocusRipple
                          disableRipple
                          edge="end"
                          sx={{ width: "48px", height: "48px" }}
                        >
                          <span
                            className=" f-w-400 fw-regular f-14"
                            style={{
                              color: disabledField
                                ? theme.palette.text.secondary
                                : theme.palette.common.black50,
                            }}
                          >
                            {generalHealth?.heightUnit}
                          </span>
                        </IconButton>
                      </InputAdornment>
                    ),
                    inputComponent: IMaskInput,
                    inputProps: {
                      ...oneMaskDefinitions,
                      value: generalHealth?.heightFT,
                    },
                  },
                  id: "heightFT",
                  name: "heightFT",
                  onKeyDown: (e: any) =>
                    exceptThisSymbols.includes(e.key) && e.preventDefault(),
                  onChange: (e: any) => {
                    const onlyNum = e.target.value.replace(/[0-9]+.\w+/g, "");
                    if (onlyNum.length < 2) {
                      handleChangeWithoutDebounce(e);
                    }
                  },
                  value: generalHealth?.heightFT,
                  error: false,
                  helperText: "",
                  fullWidth: true,
                  ref: heightFtRef,
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={4} md={2}>
              <TextBox
                disabled={disabledField}
                label={`${
                  generalHealth?.heightUnit === "ft" ? "Feet" : "Centimeters"
                }`}
                labelProps={{
                  htmlFor: "heightCM",
                }}
                inputProps={{
                  InputProps: {
                    classes: {
                      root: "border-radius-8 textFieldEndIconPadding bg-white",
                      input: "textFieldEndIcon",
                    },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: "0px",
                          maxHeight: "",
                          marginRight: "13px",
                        }}
                      >
                        <IconButton
                          disableFocusRipple
                          disableRipple
                          edge="end"
                          sx={{ width: "48px", height: "48px" }}
                        >
                          <span
                            className=" f-w-400 fw-regular f-14"
                            style={{
                              color: disabledField
                                ? theme.palette.text.secondary
                                : theme.palette.common.black50,
                            }}
                          >
                            {generalHealth?.heightUnit}
                          </span>
                        </IconButton>
                      </InputAdornment>
                    ),
                    inputComponent: IMaskInput,
                    inputProps: {
                      ...threeMaskDefinitions,
                      value: generalHealth?.heightCM,
                    },
                  },
                  id: "heightCM",
                  name: "heightCM",
                  onKeyDown: (e: any) =>
                    exceptSymbols.includes(e.key) && e.preventDefault(),
                  onChange: (e: any) => {
                    const { value } = e.target;
                    const onlyNum = e.target.value.replace(/[^0-9]/g, "");
                    if (
                      `${value}`.includes(".") &&
                      `${value}`.split(".")[0].length < 4 &&
                      `${value}`.split(".")[1].length < 3 &&
                      onlyNum.length < 6
                    ) {
                      handleChangeWithoutDebounce(e);
                    } else if (onlyNum.length < 4) {
                      handleChangeWithoutDebounce(e);
                    }
                  },
                  value: generalHealth?.heightCM,
                  error: false,
                  helperText: "",
                  fullWidth: true,
                  ref: heightCMRef,
                }}
              />
            </Grid>
          )}

          {generalHealth?.heightUnit === "ft" && (
            <Grid item xs={12} sm={4} md={2}>
              <TextBox
                disabled={disabledField}
                label={"Inches"}
                labelProps={{
                  htmlFor: "heightIN",
                }}
                inputProps={{
                  InputProps: {
                    classes: {
                      root: "border-radius-8 textFieldEndIconPadding bg-white",
                      input: "textFieldEndIcon",
                    },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: "0px",
                          maxHeight: "",
                          marginRight: "13px",
                        }}
                      >
                        <IconButton
                          disableFocusRipple
                          disableRipple
                          edge="end"
                          sx={{ width: "48px", height: "48px" }}
                        >
                          <span
                            className=" f-w-400 fw-regular f-14"
                            style={{
                              color: disabledField
                                ? theme.palette.text.secondary
                                : theme.palette.common.black50,
                            }}
                          >
                            {"inch"}
                          </span>
                        </IconButton>
                      </InputAdornment>
                    ),
                    inputComponent: IMaskInput,
                    inputProps: {
                      ...twoMaskDefinitions,
                      value: generalHealth?.heightIN,
                    },
                  },
                  id: "heightIN",
                  name: "heightIN",
                  onKeyDown: (e: any) =>
                    exceptThisSymbols.includes(e.key) && e.preventDefault(),
                  onChange: (e: any) => {
                    const onlyNum = e.target.value.replace(/[^0-9]/g, "");
                    if (onlyNum.length < 3 && e.target.value < 13) {
                      handleChangeWithoutDebounce(e);
                    }
                  },
                  value: generalHealth?.heightIN,
                  error: false,
                  helperText: "",
                  fullWidth: true,
                  ref: heightInchRef,
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={4} md={2}>
            <InputHeading title={"Weight Unit"} />
            <DropDown
              disabled={disabledField}
              selectOptions={weightUnitOptions}
              selectProps={{
                id: "weightUnit",
                name: "weightUnit",
                value:
                  (generalHealth?.weightUnit === "lb"
                    ? "lbs"
                    : generalHealth?.weightUnit) || "lb",
                onChange: (e: any) => {
                  handleChange(e);

                  const { value } = e.target as any;
                  let updatedGeneralHealth = generalHealth;
                  updatedGeneralHealth["heightUnit"] =
                    value === "kg" ? "cm" : "ft";
                  dispatch(updateGeneralHealthAction(updatedGeneralHealth));
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <TextBox
              disabled={disabledField}
              label={`Weight (in ${
                generalHealth?.weightUnit === "lb" ? "lbs" : "kg"
              })`}
              labelProps={{
                htmlFor: "weight",
              }}
              inputProps={{
                id: "weight",
                name: "weight",
                onKeyDown: (e: any) =>
                  exceptSymbols.includes(e.key) && e.preventDefault(),
                onChange: (e: any) => {
                  const { value } = e.target;
                  const onlyNum = e.target.value.replace(/[^0-9]/g, "");
                  if (
                    `${value}`.includes(".") &&
                    `${value}`.split(".")[0].length < 4 &&
                    `${value}`.split(".")[1].length < 3 &&
                    onlyNum.length < 7
                  ) {
                    handleChangeWithoutDebounce(e);
                  } else if (onlyNum.length < 4) {
                    handleChangeWithoutDebounce(e);
                  }
                },
                value: generalHealth?.weight,
                error: false,
                helperText: "",
                fullWidth: true,
                InputProps: {
                  classes: {
                    root: "border-radius-8 textFieldEndIconPadding bg-white",
                    input: "textFieldEndIcon",
                  },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "0px",
                        maxHeight: "",
                        marginRight: "13px",
                      }}
                    >
                      <IconButton
                        disableFocusRipple
                        disableRipple
                        edge="end"
                        sx={{ width: "48px", height: "48px" }}
                      >
                        <span
                          className=" f-w-400 fw-regular f-14"
                          style={{
                            color: disabledField
                              ? theme.palette.text.secondary
                              : theme.palette.common.black50,
                          }}
                        >
                          {generalHealth?.weightUnit}
                        </span>
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputComponent: IMaskInput,
                  inputProps: {
                    ...threeMaskDefinitions,
                    value: generalHealth?.weight,
                  },
                },
                ref: weightRef,
              }}
            />
          </Grid>
        </Grid>
      </CollapsibleBox>
    </>
  );
});

export default GeneralHealth;
