import {Grid, InputAdornment, Typography} from "@mui/material";
import {isArray} from "lodash";
import moment from "moment";
import React, {useCallback, useMemo} from "react";
import {batch, useDispatch, useSelector} from "react-redux";
import {setExamFindingsAction} from "../../../../../redux/actions/dentist/clinicalExam/exam/examFindingsTypesAction";
import {setAlternativeOdontogramAction} from '../../../../../redux/actions/dentist/clinicalExam/patientReviewAction';
import {RootState} from "../../../../../redux/reducers/rootReducer";
import theme from "../../../../../theme";
import {
    convertValueToRoman, handleChangeWithDebounce,
    removeRefFromJson,
    stringToArray,
    toothNumberPreferences,
    usDateFormat
} from "../../../../../utils/helper";
import {
    completeCheckout,
    odontogram,
    periogram,
    photo,
    viewExam,
    xray
} from '../../../../../utils/constant/clinicalExamConstant';
import {
    PMSEnableFindings,
    FindingList,
    findingTypes,
    PMSEnableFindingsForOdontogram, CE
} from "../../../../../utils/constant/examConstant";
import CheckboxSelect from '../../../../common/UIComponents/CheckboxSelect';
import DateSelect from "../../../../common/UIComponents/DateSelect";
import DropDown from "../../../../common/UIComponents/DropDown";
import InputHeading from "../../../../common/UIComponents/InputHeading";
import RadioSelect from "../../../../common/UIComponents/RadioSelect";
import RangeSlider from "../../../../common/UIComponents/RangeSlider";
import TextBox from "../../../../common/UIComponents/TextBox";
import {additionalOdontogramDataAction} from "../../../../../redux/actions/dentist/recommendationsTreatmentActions";
import {textLengthStyles} from "../../../../../pages/dentist/PreIntake";
import MultiSelectDropDown from "./MultiSelectDropDown";
import {
    EXTENT,
    MULTI_SELECT,
    PROCEDURE_STATUS,
    SELECT,
    TOOTH_NUMBER
} from "../../../../../utils/constant/commonConstant";


type Props = {
    fieldData: any;
    exam: string;
    finding: any;
    index: number;
    isMulti: boolean | undefined;
    tag?: any | undefined;
    findingData?: any;
    disableFindingTypes?: any;
    fromChart?: boolean;
    handleChartChange?: any;
    chartFindingData?: any;
    fromPatientReview?: boolean,
    fromTreatmentPlanning?: boolean
    displayOdontogramForm?: boolean
    fromAnnotation?: boolean
    annotationData?: any
    disabledWellnessAnnotation?: boolean | undefined
};
const exceptThisSymbols = ["e", "E", "+", "-"];

const ExamsCustomFields = React.memo((props: Props) => {
    const {
        fieldData,
        exam,
        finding,
        index,
        isMulti,
        tag,
        findingData,
        disableFindingTypes,
        fromChart,
        handleChartChange,
        chartFindingData,
        fromPatientReview,
        fromTreatmentPlanning,
        displayOdontogramForm,
        fromAnnotation,
        annotationData,
        disabledWellnessAnnotation,
    } = props;
    const dispatch = useDispatch();
    const {palette} = theme as any
    const lengthClasses = textLengthStyles();
    const {
        odontogram: {cameOdontogramFromTreatment},
        appointmentsReducer: {appointmentDetails},
        clinicalExamFilters: {findingType, examFormSize, appointmentStep},
        patientReview: {setAlternativeOdontogram},
        recommendationsTreatment: {additionalOdontogramData},
        wellnessProfileFilters: {wellnessProfileStep, wellnessProfileData},
    } = useSelector((state: RootState) => state) as any
    const isWellnessProfile= window.location.pathname?.includes(`/dentist/wellness-profile/`)
    const examFindingDataFromSelector = useSelector((state: RootState) => state?.examFindingReducer?.examFindingData);
    const tabData = isWellnessProfile ? wellnessProfileStep : findingType
    const examFindingData = fromChart ? chartFindingData : fromAnnotation ? annotationData : examFindingDataFromSelector as any
    const isPhotoXray = ((tabData === photo || tabData === xray) && appointmentStep !== 4) || fromAnnotation

    const disabledField = useMemo(() => {
        if (fromAnnotation || disabledWellnessAnnotation) {
            return true
        }
        if (cameOdontogramFromTreatment) {
            return !!displayOdontogramForm
        }
        const isEntireTypeDisabled = disableFindingTypes?.includes(findingTypes[tabData]) as any
        if (fromPatientReview || fromTreatmentPlanning) {
            return isEntireTypeDisabled
        } else {
            if ([completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status) ||
                fieldData?.disableInFindingType?.includes(findingTypes[tabData]) || (isWellnessProfile ? wellnessProfileData?.is_wellness_locked : false)) {
                return true
            } else {
                return (
                    isEntireTypeDisabled ||
                    ((tabData === periogram) &&
                        appointmentDetails?.pms_appointment_no !== null &&
                        (!PMSEnableFindings?.includes(finding))
                    ) || ((tabData === odontogram) &&
                        appointmentDetails?.pms_appointment_no !== null &&
                        (!PMSEnableFindingsForOdontogram?.includes(finding))
                    ))
            }
        }
    }, [cameOdontogramFromTreatment, fromPatientReview, fromTreatmentPlanning, disableFindingTypes, tabData, appointmentDetails?.sub_status, fieldData?.disableInFindingType, appointmentDetails?.pms_appointment_no, wellnessProfileData?.is_wellness_locked,isWellnessProfile,fromAnnotation,finding , displayOdontogramForm,disabledWellnessAnnotation]);

    const getColumnSize = useCallback((options: any, type?: string) => {
        if (type === "radio") {
            let optionLength = 0;
            options?.map((res: any) => (optionLength += res?.label?.length));
            return optionLength > 30 || options?.length > 2 ? 12 : 4;
        } else if (type === "label") {
            return options?.length > 30 ? 12 : 6;
        }
        return 6;
    }, []);

    const getDropDownOption = useCallback((option: any) => {
        return {
            label: option,
            value: option,
        };
    },[])

    const getIndexOfCurrentTag = useMemo(() => {
        let index = 0 as number;
        if (fromAnnotation) {
            if (examFindingData && isArray(examFindingData)) {
                index = examFindingData?.findIndex((res: any) => res?.tag_id === tag?.tag_id);
            }
        } else {
            if (examFindingData && isArray(examFindingData)) {
                index = examFindingData?.findIndex((res: any) => res?.key === tag?.key);
            }
        }
        return index || 0;
    }, [fromAnnotation, examFindingData, tag]);

    const handleChange = handleChangeWithDebounce((e: any, key: string, exam: string) => {
        const {value} = e?.target as any;
        if (fromPatientReview) {
            dispatch(setAlternativeOdontogramAction({
                ...setAlternativeOdontogram,
                [key]: value
            }))
        } else if (fromTreatmentPlanning) {
            dispatch(additionalOdontogramDataAction({
                ...additionalOdontogramData,
                [key]: value
            }))
        } else {
            let updatedExamFindingData = removeRefFromJson(examFindingData) as any;

            if (updatedExamFindingData && isPhotoXray && fieldData && !fromTreatmentPlanning && !fromPatientReview) {
                updatedExamFindingData[getIndexOfCurrentTag][fieldData?.key || ""] = value;
            } else {
                if (isMulti) {
                    findingData?.map((res: any) => {
                        res?.options?.map((option: any) => {
                            if (option?.dependent !== undefined || option?.dependent) {
                                if (
                                    option?.dependent?.key === key &&
                                    finding &&
                                    updatedExamFindingData[exam] &&
                                    updatedExamFindingData[exam][finding]
                                ) {
                                    let updatedDataForDependent = updatedExamFindingData[exam][finding] as any;
                                    if (updatedDataForDependent[index]) {
                                        updatedDataForDependent[index][option?.key] = null;
                                        if (updatedExamFindingData[exam]) {
                                            updatedExamFindingData[exam][finding] = updatedDataForDependent;
                                        }
                                    }
                                }
                            }
                            if (updatedExamFindingData && exam && finding && updatedExamFindingData[exam] && updatedExamFindingData[exam][finding]) {
                                let updatedData = updatedExamFindingData[exam] && (updatedExamFindingData[exam][finding] as any);
                                if (updatedData && updatedData[index]) {
                                    updatedData[index][key] = value || null;
                                    if (updatedExamFindingData[exam]) {
                                        updatedExamFindingData[exam][finding] = updatedData;
                                    }
                                }
                            }

                            return true;
                        })
                        return true
                    });
                } else {
                    //This function will get called only when the answer/value is changes for question who hase questions depended on it.
                    const removeDependentVal = (dependentOption: any) => {
                        findingData?.map((res: any) => {
                            res?.options?.map((option: any) => {
                                if (option && option?.dependent && option?.key && exam && updatedExamFindingData[exam] && dependentOption && option?.dependent?.key === dependentOption && updatedExamFindingData[exam]) {
                                    updatedExamFindingData[exam][option?.key] = null;
                                }
                                return true;
                            })
                            return true
                        });
                    };
                    findingData?.map((res: any) => {
                        res?.options?.map((dependentOption: any) => {
                            if (
                                dependentOption &&
                                dependentOption?.dependent &&
                                dependentOption?.dependent?.key === key &&
                                !dependentOption?.dependent?.answer?.includes(value)
                            ) {
                                updatedExamFindingData[exam][dependentOption?.key] = null;
                                removeDependentVal(dependentOption?.key);
                            }
                            if (updatedExamFindingData[exam]) {
                                updatedExamFindingData[exam][key] = value || null;
                            }
                            return true;
                        })
                        return true
                    });
                }
            }
            if (fromChart) {
                handleChartChange(updatedExamFindingData)
            } else {
                dispatch(setExamFindingsAction(updatedExamFindingData));
            }
        }
    });

    const handleSliderChange = (val: any, key: string, exam: string) => {
        handleChangeHelper(val, key, exam)
    };
    const handleChangeHelper = useCallback((val: any, key: string, exam: string) => {
        // Batching updates to reduce re-renders
        batch(() => {
            let updatedExamFindingData = removeRefFromJson(examFindingData);
            const currentTagIndex = getIndexOfCurrentTag as number;
            if (
                isPhotoXray &&
                updatedExamFindingData &&
                updatedExamFindingData[currentTagIndex] &&
                fieldData
            ) {
                updatedExamFindingData[currentTagIndex][fieldData?.key || ""] = val;
            } else if (updatedExamFindingData && updatedExamFindingData[exam]) {
                if (isMulti && updatedExamFindingData[exam][finding] && updatedExamFindingData[exam][finding][index]) {
                    updatedExamFindingData[exam][finding][index][key] = val;
                } else {
                    updatedExamFindingData[exam][key] = val;
                }
            }
            if (fromChart) {
                handleChartChange(updatedExamFindingData)
            } else {
                dispatch(setExamFindingsAction(updatedExamFindingData));
            }
        })
    }, [
        dispatch,
        examFindingData,
        fieldData,
        fromChart,
        getIndexOfCurrentTag,
        handleChartChange,
        isMulti,
        isPhotoXray,
        finding,
        index,
    ]);
    const handleChangeMultiSelect = useCallback((e: any, key: any, exam: any, isMultiSelection: any) => {
        let val = isMultiSelection ? e?.map((res: any) => res?.value) : e?.value;
        if (fromPatientReview) {
            dispatch(setAlternativeOdontogramAction({...setAlternativeOdontogram, [key]: val}));
        } else if (fromTreatmentPlanning) {
            dispatch(additionalOdontogramDataAction({...additionalOdontogramData, [key]: val}));
        } else {
            handleChangeHelper(val, key, exam);
        }
    }, [dispatch, fromPatientReview, fromTreatmentPlanning, setAlternativeOdontogram, additionalOdontogramData, handleChangeHelper]);

    const fulfilledDependency = useMemo(() => {
        if (fieldData?.key === PROCEDURE_STATUS && tabData !== odontogram) {
            return false
        }
        if (isPhotoXray) {
            // as there is no need for dependency question for tag... so commenting below code
            return true;
            // if (fieldData?.dependent && examFindingData && examFindingData[getIndexOfCurrentTag][fieldData?.dependent.key]) {
            //   if (typeof examFindingData[getIndexOfCurrentTag][fieldData?.dependent.key] === 'boolean') {
            //     return (examFindingData[getIndexOfCurrentTag][fieldData?.dependent.key] ? 'yes' : 'no') === fieldData?.dependent.answer
            //   }
            //   return examFindingData[getIndexOfCurrentTag][fieldData?.dependent.key] === fieldData?.dependent.answer
            // }
        } else if (fieldData && fieldData?.dependent && examFindingData && examFindingData[exam]) {
            if (isMulti) {
                if (
                    fieldData?.dependent.key &&
                    examFindingData[exam][finding] &&
                    examFindingData[exam][finding][index]
                ) {
                    if (
                        typeof examFindingData[exam][finding][index][
                            fieldData?.dependent?.key
                            ] === "boolean"
                    ) {
                        return (
                            (examFindingData[exam][finding][index][fieldData?.dependent?.key]
                                ? "yes"
                                : "no") === fieldData?.dependent?.answer
                        );
                    }
                    return (
                        examFindingData[exam][finding][index][fieldData?.dependent?.key] ===
                        fieldData?.dependent?.answer
                    );
                }
            } else {
                if (fieldData?.dependent?.answer && Array.isArray(fieldData?.dependent?.answer)) {
                    if (typeof examFindingData[exam][fieldData?.dependent?.key] === "boolean") {
                        return fieldData?.dependent?.answer?.includes(examFindingData[exam][fieldData?.dependent?.key] ? "yes" : "no");
                    }
                    return fieldData?.dependent?.answer?.includes(examFindingData[exam][fieldData?.dependent?.key]);
                }
            }
        }
        return fieldData?.dependent === undefined;
    },[isPhotoXray,fieldData,examFindingData,isMulti,tabData,index,exam,finding]);

    const getValueFromRedux = useMemo(() => {
        const currentTagIndex = getIndexOfCurrentTag as number
        if (isPhotoXray && fieldData && examFindingData && examFindingData[currentTagIndex]) {
            return fieldData?.label === "Presence"
                ? "yes"
                : examFindingData[currentTagIndex][fieldData?.key];
        }

        if (fieldData && fieldData?.key && examFindingData && examFindingData[exam]) {
            if (isMulti) {
                if (
                    examFindingData[exam][finding] &&
                    examFindingData[exam][finding][index] &&
                    examFindingData[exam][finding][index][fieldData?.key] !== null
                ) {
                    if (typeof examFindingData[exam][finding][index][fieldData?.key] === "boolean") {
                        return examFindingData[exam][finding][index][fieldData?.key] ? "yes" : "no";
                    }
                    return examFindingData[exam][finding][index][fieldData?.key];
                }
            } else {
                if (examFindingData[exam][fieldData?.key] !== null) {
                    if (typeof examFindingData[exam][fieldData?.key] === "boolean") {
                        return examFindingData[exam][fieldData?.key] ? "yes" : "no";
                    }
                    return examFindingData[exam][fieldData?.key] === 0 ? '0' : examFindingData[exam][fieldData?.key];
                }
            }
        }
        return null;
    }, [isPhotoXray, fieldData, examFindingData, exam, finding, index, isMulti,getIndexOfCurrentTag]);

    const multiSelectValue = useCallback((isToothNumber?: boolean, data?: any) => {
        const transformData = (dataToTransform: any) => {
            return dataToTransform?.map((res: string) => ({
                label: isToothNumber ? toothNumberPreferences(parseInt(res)) : res,
                value: res,
            }));
        };

        if (data) {
            return transformData(data);
        }

        const reduxValue = getValueFromRedux;
        if (reduxValue) {
            const processedValue = typeof reduxValue === "string" ? stringToArray(reduxValue) : reduxValue;
            return transformData(processedValue);
        }

        return null;
    },[ getValueFromRedux])

    const getUsToothNumberValue = useCallback((key: any) => {
        if (key) {
            return {
                label: toothNumberPreferences(key),
                value: key,
            };
        }
        return {label: null, value: null};
    },[])

    const checkBoxChange = (e: any, item: any, key: string, exam: string) => {
        let updatedExamFindingData = removeRefFromJson(examFindingData);
        if (fromPatientReview) {
            let val = setAlternativeOdontogram[key] || [] as any
            if (e?.target?.checked) {
                val?.push(item)
            } else {
                val = setAlternativeOdontogram[key]?.filter((f: any) => f !== item);
            }
            dispatch(setAlternativeOdontogramAction({
                ...setAlternativeOdontogram,
                [key]: val
            }))
        } else if (fromTreatmentPlanning) {
            let val = additionalOdontogramData[key] || [] as any
            if (e?.target?.checked) {
                val?.push(item)
            } else {
                val = additionalOdontogramData[key]?.filter((f: any) => f !== item);
            }
            dispatch(additionalOdontogramDataAction({
                ...additionalOdontogramData,
                [key]: val
            }))
        }
        if (updatedExamFindingData && isPhotoXray && fieldData && !fromTreatmentPlanning && !fromPatientReview) {
            let value = updatedExamFindingData[getIndexOfCurrentTag][fieldData?.key || ""] || []

            if (e?.target?.checked) {
                value?.push(item)
            } else {
                value = updatedExamFindingData[getIndexOfCurrentTag][fieldData?.key || ""]?.filter((f: any) => f !== item) || [];
            }
            updatedExamFindingData[getIndexOfCurrentTag][fieldData?.key || ""] = value;
            dispatch(setExamFindingsAction(updatedExamFindingData));
        } else {
            if (
                updatedExamFindingData &&
                updatedExamFindingData[exam] &&
                updatedExamFindingData[exam][finding] &&
                updatedExamFindingData[exam][finding][index]
            ) {

                let val = updatedExamFindingData[exam][finding][index][key] || [] as any
                if (e?.target?.checked) {
                    val?.push(item)
                } else {
                    val = examFindingData[exam][finding][index][key]?.filter((f: any) => f !== item);
                }
                updatedExamFindingData[exam][finding][index][key] = val;
                if (fromChart) {
                    handleChartChange(updatedExamFindingData)
                }

            }
        }

    };
    const getProcedureOptions = useMemo(() => {
        return fieldData?.options?.filter((opt: any) => {
            if (cameOdontogramFromTreatment || fromTreatmentPlanning) {
                return opt === 'Planned'
            } else {
                return FindingList.find((f: any) => f?.type === 'group' ? f.groupSubType?.includes(finding) : f.key === finding)?.procedures?.includes(opt)
            }
        })
    },[cameOdontogramFromTreatment,fromTreatmentPlanning,finding,fieldData])

    const clearClickHandler = (key: string) => {
        let updatedExamFindingData = removeRefFromJson(examFindingData);
        updatedExamFindingData[exam][key] = null;
        dispatch(setExamFindingsAction(updatedExamFindingData));
    }

    const checkboxValue = useCallback((key: string, res: any) => {
        return fromPatientReview
            ? setAlternativeOdontogram[key]
                ? setAlternativeOdontogram[key]?.includes(res)
                : null
            : fromTreatmentPlanning
                ? additionalOdontogramData[key]
                    ? additionalOdontogramData[key]?.includes(res)
                    : null
                : getValueFromRedux?.includes(res);
    }, [fromPatientReview, fromTreatmentPlanning, setAlternativeOdontogram, additionalOdontogramData, getValueFromRedux]);

    const selectedValue = useMemo(() => {
        if (fromPatientReview) {
            return setAlternativeOdontogram[fieldData?.key]
                ? getUsToothNumberValue(setAlternativeOdontogram[fieldData?.key])
                : null;
        } else if (fromTreatmentPlanning) {
            return additionalOdontogramData[fieldData?.key]
                ? getUsToothNumberValue(additionalOdontogramData[fieldData?.key])
                : null;
        } else if (fieldData?.type === MULTI_SELECT) {
            return multiSelectValue(fieldData?.key?.includes(TOOTH_NUMBER)) || null;
        } else {
            return getUsToothNumberValue(getValueFromRedux) || null;
        }
    }, [
        fromPatientReview,
        fromTreatmentPlanning,
        setAlternativeOdontogram,
        additionalOdontogramData,
        fieldData?.key,
        fieldData?.type,
        multiSelectValue,
        getUsToothNumberValue,
        getValueFromRedux
    ]);
    return (
        <React.Fragment>
            {
                <React.Fragment>
                {fulfilledDependency ? (
                    <React.Fragment>
                        {(fieldData?.type === SELECT && !fieldData?.key?.includes(TOOTH_NUMBER)) && (fieldData?.key === EXTENT ? appointmentDetails?.appointmentType !==CE : true) && (
                            <Grid
                                item
                                xs={12}
                                sm={examFormSize ? 6 : 12}
                                md={examFormSize ? 4 : 12}
                                lg={examFormSize ? 3 : 12}
                                xl={examFormSize ? 3 : 6}
                            >
                                <InputHeading
                                    title={fieldData?.label}
                                />
                                <DropDown
                                    selectOptions={fieldData?.options}
                                    selectProps={{
                                        id: fieldData?.key,
                                        name: fieldData?.key,
                                        value: (fromPatientReview ? setAlternativeOdontogram[fieldData?.key] ? setAlternativeOdontogram[fieldData?.key] : null : fromTreatmentPlanning
                                            ? additionalOdontogramData[fieldData?.key]
                                                ? additionalOdontogramData[fieldData?.key]
                                                : null : ((finding === 'mobility' && fieldData?.key === 'grade') ? convertValueToRoman(getValueFromRedux) : getValueFromRedux)) || null,
                                        onChange: (e: any) => handleChange(e, fieldData?.key, exam),
                                        disabled: disabledField,
                                    }}
                                    sx={{height: '50px'}}
                                />
                            </Grid>
                        )}
                        {(fieldData?.type === MULTI_SELECT || (fieldData?.type === SELECT && fieldData?.key?.includes(TOOTH_NUMBER))) && (
                            <Grid
                                item
                                xs={6}
                                sm={examFormSize ? 6 : 12}
                                md={examFormSize ? 4 : 12}
                                lg={examFormSize ? 3 : 12}
                                xl={examFormSize ? 3 : 6}
                                className="reactSelect"
                            >
                                <MultiSelectDropDown
                                    fieldData={fieldData}
                                    exam={exam}
                                    selectedValue={selectedValue}
                                    disabledField={disabledField}
                                    handleChangeMultiSelect={handleChangeMultiSelect}
                                />
                            </Grid>
                        )}

                        {fieldData?.type === "radio" && ((!(fieldData?.key === PROCEDURE_STATUS && fromTreatmentPlanning))) && (
                            <Grid
                                item
                                xs={12}
                                sm={((fromChart || fromPatientReview || fromTreatmentPlanning) || fieldData?.label?.length < 1) ? 12 : (examFormSize ? getColumnSize(fieldData?.options, "radio") : 12)}
                                md={((fromChart || fromPatientReview || fromTreatmentPlanning) || fieldData?.label?.length < 1) ? 12 : (examFormSize ? getColumnSize(fieldData?.options, "radio") : 12)}
                                lg={((fromChart || fromPatientReview || fromTreatmentPlanning) || fieldData?.label?.length > 15) ? 12 : (fieldData?.key === PROCEDURE_STATUS ? 12 : examFormSize ? getColumnSize(fieldData?.options, "radio") : 12)}
                                xl={((fromChart || fromPatientReview || fromTreatmentPlanning) || (fieldData?.label?.length || fieldData?.key?.length) > 15) ? 12 : (fieldData?.key === PROCEDURE_STATUS ? 12 : examFormSize ? getColumnSize(fieldData?.options, "radio") : 6)}
                            >
                                {fieldData?.label?.length > 1 && (fromChart ?
                                        <Typography color={palette.v2.primary.main}
                                                    className='f-w-500 fw-medium f-14 lh-16'>
                                            {fieldData?.label}
                                        </Typography> :
                                        <InputHeading
                                            sx={{marginBottom: fromPatientReview || fromTreatmentPlanning ? '0px' : ''}}
                                            title={fieldData?.label}
                                        />
                                )}
                                <RadioSelect
                                    disabled={disabledField || (isPhotoXray && fieldData?.label === "Presence")}
                                    options={(fromChart || fromPatientReview || fromTreatmentPlanning) ?
                                        (fieldData?.key === PROCEDURE_STATUS ? getProcedureOptions : fieldData?.options)?.map((res: any) =>
                                            getDropDownOption(res)
                                        ) : (fieldData?.key === "cracks") ? fieldData?.options?.map((res: any) =>
                                            getDropDownOption(res)
                                        ) : fieldData?.options}
                                    groupProps={{
                                        name: fieldData?.key,
                                        id: fieldData?.key,
                                        value: (fromPatientReview ? setAlternativeOdontogram[fieldData?.key] : fromTreatmentPlanning ? additionalOdontogramData[fieldData?.key] : getValueFromRedux) || null,
                                        onChange: (e: any) => handleChange(e, fieldData?.key, exam),
                                    }}
                                />
                            </Grid>
                        )}
                        {fieldData?.type === "number" && (
                            <Grid
                                item
                                xs={12}
                                sm={examFormSize ? ((fieldData?.label?.length > 30) ? 12 : 6) : 12}
                                md={fromChart ? 12 : examFormSize ? ((fieldData?.label?.length > 30) ? 12 : 4) : 12}
                                lg={fromChart ? 12 : examFormSize ? ((fieldData?.label?.length > 30) ? 6 : 3) : 12}
                                xl={fromChart ? 12 : examFormSize ? ((fieldData?.label?.length > 30) ? 4 : 3) : (fieldData?.label?.length > 30) ? 12 : 6}
                            >
                                <TextBox
                                    examFromChart={fromChart}
                                    labelSx={{whiteSpace: 'break-spaces !important'}}
                                    disabled={disabledField}
                                    label={fieldData?.label}
                                    labelProps={{htmlFor: fieldData?.key}}
                                    inputProps={{
                                        id: fieldData?.key,
                                        name: fieldData?.key,
                                        onWheel: (e: any) => {
                                            e.target.blur()
                                        },
                                        onKeyDown: (e: any) =>
                                            exceptThisSymbols.includes(e.key) && e.preventDefault(),
                                        onChange: (e: any) => {
                                            if (fieldData?.key === "ortho_tx_age") {
                                                const onlyNum = e.target.value.replace(/[^0-9]/g, "");
                                                if (onlyNum.length < 4) {
                                                    if (e.target.value >= 0) {
                                                        handleChange(e, fieldData?.key, exam)
                                                    }
                                                }
                                            } else {
                                                if (e.target.value >= 0) {
                                                    handleChange(e, fieldData?.key, exam)
                                                }
                                            }
                                        },
                                        defaultValue: getValueFromRedux || null,
                                        fullWidth: true,
                                        type: fieldData?.type,
                                    }}
                                />
                            </Grid>
                        )}
                        {fieldData?.type === "text" && fieldData?.multiline === undefined && !fromAnnotation && !fromChart && !fromPatientReview && !fromTreatmentPlanning && (
                            <Grid
                                item
                                xs={getColumnSize(fieldData?.label, "label")}
                                sm={examFormSize ? ((fieldData?.label?.length > 30) ? 12 : 6) : 12}
                                md={examFormSize ? ((fieldData?.label?.length > 30) ? 12 : 4) : 12}
                                lg={examFormSize ? ((fieldData?.label?.length > 30) ? 6 : 3) : 12}
                                xl={examFormSize ? ((fieldData?.label?.length > 30) ? 4 : 3) : (fieldData?.label?.length > 30) ? 12 : 6}
                            >
                                <TextBox
                                    labelSx={{whiteSpace: 'break-spaces !important'}}
                                    disabled={disabledField}
                                    label={fieldData?.label}
                                    labelProps={{htmlFor: fieldData?.key}}
                                    maxLength={(fieldData?.key?.includes('notes') || fieldData?.key?.includes('comments')) ? 255 : 30}
                                    inputProps={{
                                        id: fieldData?.key,
                                        name: fieldData?.key,
                                        onChange: (e: any) => handleChange(e, fieldData?.key, exam),
                                        defaultValue: getValueFromRedux || null,
                                        fullWidth: true,
                                        InputProps: {
                                            classes: {
                                                root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                                input: "textFieldStartEndIcon",
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end"
                                                                className={lengthClasses.textLengthCount}>
                                                    {`${getValueFromRedux?.length || 0} / ${(fieldData?.key?.includes('notes') || fieldData?.key?.includes('comments')) ? '255' : '30'}`}
                                                </InputAdornment>
                                            )
                                        },
                                    }}
                                />
                            </Grid>
                        )}
                        {fieldData?.type === "text" && fieldData?.multiline && (
                            <Grid item xs={12}>
                                <TextBox
                                    disabled={disabledField}
                                    label={fieldData?.label}
                                    labelProps={{htmlFor: fieldData?.key}}
                                    maxLength={fieldData?.key === 'medical_comments' ? '' : 255}
                                    inputProps={{
                                        id: fieldData?.key,
                                        name: fieldData?.key,
                                        onChange: (e: any) => handleChange(e, fieldData?.key, exam),
                                        defaultValue: getValueFromRedux || null,
                                        fullWidth: true,
                                        multiline: true,
                                        rows: 3,
                                        InputProps: {
                                            classes: {
                                                root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                                input: "textFieldStartEndIconNumbers",
                                            },
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    className={lengthClasses.textLengthCount}
                                                    sx={{alignSelf: 'flex-end', paddingBottom: '15px'}}
                                                >
                                                    {`${getValueFromRedux?.length || 0} / 255`}
                                                </InputAdornment>
                                            )
                                        },
                                    }}
                                />
                            </Grid>
                        )}
                        {fieldData?.type === "slider" && (
                            <Grid item xs={12} mb={1}>
                                <RangeSlider
                                    sliderTitle={fieldData?.label}
                                    leftLabel={""}
                                    rightLabel={""}
                                    sliderProps={{
                                        step: 1,
                                        min: fieldData?.min,
                                        max: fieldData?.max,
                                        marks: fieldData?.options,
                                        size: "medium",
                                        value: getValueFromRedux || null,
                                        disabled: disabledField,
                                    }}
                                    onChange={(e: any) => handleSliderChange(e, fieldData?.key, exam)}
                                />
                            </Grid>
                        )}
                        {fieldData?.type === "date" && (
                            <Grid
                                item
                                xs={12}
                                sm={examFormSize ? 6 : 12}
                                md={examFormSize ? 4 : 12}
                                lg={examFormSize ? 3 : 12}
                                xl={examFormSize ? 3 : 12}
                                mb={1}
                            >
                                {fieldData?.label?.length > 1 && (<InputHeading title={fieldData?.label}/>)}
                                <DateSelect
                                    disabled={disabledField}
                                    value={getValueFromRedux || null}
                                    showClearIcon
                                    clearClickHandler={() =>
                                        clearClickHandler(fieldData?.key)
                                    }
                                    onChange={(newValue: any) => {
                                        let newFormatted = moment(new Date(newValue))?.format(usDateFormat);
                                        handleSliderChange(newFormatted, fieldData?.key, exam);
                                    }}
                                />
                            </Grid>
                        )}
                        {fieldData?.type === "checkbox" && fieldData?.key !== TOOTH_NUMBER && !disableFindingTypes?.includes(findingTypes[tabData]) && (
                            <Grid item xs={12} mb={(fromPatientReview || fromTreatmentPlanning) ? 0 : 1}>
                                {fieldData?.label.length > 1 && fromChart ? (
                                        <Typography color={palette.v2.primary.main}
                                                    className='f-w-500 fw-medium f-14 lh-16'>
                                            {fieldData?.label}
                                        </Typography>) :
                                    <InputHeading
                                        sx={{marginBottom: (fromPatientReview || fromTreatmentPlanning) ? '0px' : ''}}
                                        title={fieldData?.label}
                                    />

                                }
                                {fieldData?.options?.map((res: any, j: number) =>
                                    <CheckboxSelect
                                        sx={{marginBottom: (fromPatientReview || fromTreatmentPlanning) ? '0px !important' : ''}}
                                        key={j}
                                        label={res}
                                        checkBoxProps={{
                                            name: fieldData?.key,
                                            checked: checkboxValue(fieldData?.key, res),
                                            value: checkboxValue(fieldData?.key, res),
                                            onChange: (e: any) => checkBoxChange(e, res, fieldData?.key, exam),
                                            disabled: disabledField
                                        }}
                                    />
                                )}
                            </Grid>)
                        }
                    </React.Fragment>
                ) : null
                }
            </React.Fragment>}
        </React.Fragment>
    );
})

export default ExamsCustomFields