import moment from "moment";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { AppBar, Drawer, Paper, Tooltip } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded'
import React, {useEffect, useMemo, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
    faAddressBook,
    faCalendarDays,
    faChevronLeft,

    faHouse,
    faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import {RootState} from "../../../redux/reducers/rootReducer";
import {getLocalStorageItem} from "../../../utils/helper";
import {changeSidebarWidthRequest} from "../../../redux/actions/dentist/sidebarAction";
import theme from "../../../theme";
import {logoutRequest} from "../../../redux/actions/authAction/logOutAction";
import AppointmentHeader from "./AppointmentHeader";
import DentistHeaderNew from "./DentistHeaderNew";
import dentalLogo from '../../../assets/images/dentalLogo.png'
import QLogo from '../../../assets/images/QLogo.png'
import DisclaimerPopUp from "../DisclaimerPopUp";
import SupportFormPopUp from "./SupportFormPopUp";
import ResourcesSubmenu, { submenuItems } from "./ResourceSubMenu";

const drawerWidth = 250;

const useStyle = makeStyles((theme: any) => ({
    activeLink: {
        cursor: "pointer",
        height: "50px",
        marginBottom: "10px",
        position: "relative",
        display: "flex",
        alignItems: "center",
        color: theme.palette.v2.primary.main,
        fontSize: "16px !important",
        textDecoration: "none",
        textTransform: "none",
        lineHeight: "23px !important",
        fontWeight: 600,
        "&:after": {
            content: '""',
            position: "absolute",
            right: 0,
            height: "40px !important",
            width: "5px",
            backgroundColor: theme.palette.primary.main,
            borderTopLeftRadius: "7px !important",
            borderBottomLeftRadius: "7px !important",
        },
        "&:hover": {
            backgroundColor: "#ecf9f7 !important",
        },
    },
    button: {
        cursor: "pointer",
        height: "50px",
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: `${theme.palette.text.darkGrey} !important`,
        fontSize: "16px !important",
        textDecoration: "none",
        textTransform: "none",
        lineHeight: "23px !important",
        fontWeight: 600,
        "&:hover": {
            color: `${theme.palette.v2.primary.main} !important`,
            backgroundColor: "#ecf9f7 !important",
        },
        "& .MuiBox-root": {
            color: theme.palette.text.darkGrey,
        },
    },
    lineItem: {
        cursor: "pointer",
        height: "50px",
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        color: `${theme.palette.text.darkGrey} !important`,
        fontSize: "16px !important",
        textDecoration: "none",
        textTransform: "none",
        lineHeight: "23px !important",
        fontWeight: 600,
        "&:hover": {
            color: `${theme.palette.v2.primary.main} !important`,
            backgroundColor: "#ecf9f7 !important",
        },
        "& .MuiBox-root": {
            color: theme.palette.text.darkGrey,
        },
    },
    logoutButton: {
        cursor: "pointer",
        height: "50px",
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        color: `${theme.palette.text.darkGrey} !important`,
        fontSize: "16px !important",
        textDecoration: "none",
        textTransform: "none",
        lineHeight: "23px !important",
        fontWeight: 600,
        "&:hover": {
            color: `${theme.palette.v2.primary.main} !important`,
            backgroundColor: "#ecf9f7 !important",
        },
        "& .MuiBox-root": {
            color: theme.palette.text.darkGrey,
        },
    },
    disableButton: {
        height: "50px",
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: `${theme.palette.text.darkGrey} !important`,
        fontSize: "16px !important",
        textDecoration: "none",
        textTransform: "none",
        lineHeight: "23px !important",
        fontWeight: 600,
        cursor: "auto",
        "& .MuiBox-root": {
            color: theme.palette.text.darkGrey,
        },
    },
    dentistProfileImageContainer: {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        objectFit: "cover",
        overflow: "hidden",
        backgroundColor: theme.palette.grey[400],
        display: "flex",
        justifyContent: "center",
    },
    dentistProfileText: {
        fontWeight: 600,
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        textTransform: "capitalize",
        marginLeft: "15px",
    },
    dentistProfileImage: {
        height: "auto",
        width: "100%",
        minWidth: "100%",
    },
    profileNameInitial: {
        borderRadius: "50%",
        border: `1px solid ${theme.palette.background.paper}`,
        backgroundColor: theme.palette.primary[100],
        height: "40px",
        width: "40px",
        opacity: 1,
    },
    drawerZIndex: {
        width: drawerWidth,
        backgroundColor: "transparent !important",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
            zIndex: 1,
        },
    },
    arrowStyle: {
        height: " 24px",
        width: " 24px",
        background: theme.palette.secondary.main,
        color: theme.palette.common.white,
        "&:hover": {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        position: 'absolute',
        left: '100%',
        bottom: 0,
        zIndex: 1027,
        transform: 'translateX(-50%)'

    },
    drawerBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "calc(100vh - 92px)",
    },
    toolBarClass: {
        background: `${theme.palette.common.white} !important`,
        paddingLeft: "20px !important",
        borderBottom: `1px solid ${theme.palette.divider}`,
        minHeight: "80px !important",
        // zIndex: 2024
    },
    toolbarSmall: {
        padding: "0px !important",
        justifyContent: "center",
        alignItems: "start",
        minHeight: "73px !important",
    },
    toolBarSmall250: {
        minHeight: "80px !important",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        padding: "0px 20px !important",
    },
    desktopDivider: {
        margin: "10px 0",
        opacity: "0.70",
        color: theme.palette.common.black50,
    },
    tabletDivider: {
        margin: "10px 0 0 0",
        opacity: "0.70",
        color: theme.palette.common.black50,
    },
    profileLatter: {
        textTransform: "uppercase",
        color: theme.palette.primary.main,
    },
    tabletAppBar: {
        zIndex: 700,
        background: theme.palette.secondary.light,
        boxShadow: "none !important",
        width: `calc(100% - ${64}px)`,
        ml: `${64}px`,
    },
    customWidth: {
        fontFamily: "FavoritPro-Regular",
    },
}));

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create(["width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `64px !important`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBarV2 = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin", "opacity"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin", "opacity"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const ListItemButton = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
}));

const DrawerV2 = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    "& .MuiPaper-root": {
        backgroundColor: "white !important",
    },
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default function Sidebar(props: any) {
    const { layout, headerTitle } = props;
    const year = moment().format("yyyy");
    const { palette } = theme;
    const classes = useStyle();
    const sidebarWidth = useSelector((state: RootState) => state.sidebar?.width);
    const dispatch = useDispatch();
    const location = useLocation();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [disclaimerOpen, setDisclaimerOpen] = React.useState(false);
    const {editProfileLoader} = useSelector((state: RootState) => state.dentistProfileReducer);
    const [openSupportFormPopUp, setSupportFormPopUp] = useState(false)
    const [resourcesAnchorEl, setResourcesAnchorEl] = useState(null);
    const [showResourcesSubmenu, setShowResourcesSubmenu] = useState(false);
    // Retrieve data from local storage and add an 'editProfileLoader' dependency to re-render the sidebar
    // when the profile picture is changed from the 'My Profile' page.
    const latestDentistData = localStorage?.getItem('clinicInfo')

    const dentistProfileData = useMemo(() => {
        return latestDentistData && JSON.parse(latestDentistData)?.profile
        // eslint-disable-next-line
    }, [getLocalStorageItem('clinicInfo'), editProfileLoader,latestDentistData])


    useEffect(() => {
        if (window.innerWidth <= 960) {
            dispatch(changeSidebarWidthRequest({width: 64}));
        } else {
            setMobileOpen(false);
        }
        // eslint-disable-next-line
    }, [window.innerWidth,dispatch]);

    const handleResourcesHover = (event: any) => {
        setResourcesAnchorEl(event.currentTarget);
        setShowResourcesSubmenu(true);
      };
      
      const handleResourcesLeave = () => {
        setShowResourcesSubmenu(false);
      };

    const navLinks = [
        {
          path: "/dentist/dashboard",
          icon: <FontAwesomeIcon icon={faHouse} className="f-22" />,
          label: "Dashboard",
          id: 1,
        },
        {
          path: "/dentist/appointment",
          icon: <FontAwesomeIcon icon={faCalendarDays} className="f-22" />,
          label: "Schedule",
          id: 2,
        },
        {
          path: "/dentist/patients-list",
          icon: <PersonAddAltRoundedIcon className="f-25" />,
          label: "Patients",
          id: 4,
        },
        {
          path: "/dentist/resources",
          icon: <FontAwesomeIcon icon={faAddressBook} className="f-22" />,
          label: "Resources & Legal",
          id: 5,
          hasSubmenu: true,
        },
      ];

    const handleDrawer = (isOpen?: any) => {
        dispatch(
            changeSidebarWidthRequest({width: isOpen === "open" ? 250 : 64})
        );
    };
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const compareSidebarWidth = () => {
        return sidebarWidth === drawerWidth;
    };

    const handleClose = () => setDisclaimerOpen(false);
    const handleLogout = async () => {
        await dispatch(logoutRequest());
        localStorage.removeItem("clinic");
        localStorage.removeItem("dentistToken");
        localStorage.removeItem("dentistUserData");
        localStorage.removeItem('clinicInfo')
        window.location.href = "/dentist/login"
    };

    const isAppointmentsPage = () => {
        return (
            location?.pathname?.includes("/dentist/appointment/clinical-exam/") ||
            location?.pathname?.includes("/dentist/appointment/health-history/") ||
            location?.pathname?.includes('/dentist/wellness-profile/')
        );
    };

    return (
        <Box className="d-flex">
            <CssBaseline/>
            {isAppointmentsPage() ? (
                <Paper
                    sx={{
                        backgroundColor: `${palette.common.white} !important`,
                        display:
                            layout === "appointment"
                                ? "block"
                                : {md: "none", sm: "block", xs: "block"},
                    }}
                >
                    <AppBar position={"fixed"} className={classes.tabletAppBar}>
                        <Toolbar className={classes.toolBarClass}>
                            <Box className="w-100">
                                <AppointmentHeader
                                    isDentist
                                    headerText={headerTitle}
                                    mobileOpen={mobileOpen}
                                    setMobileOpen={setMobileOpen}
                                />
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Paper>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                    }}
                >
                    <AppBarV2
                        position={"fixed"}
                        sx={{
                            background: palette.common.white,
                            width: {
                                md: `calc(100% - ${sidebarWidth}px)`,
                                sm: `100%`,
                                xs: `100%`,
                            },
                            ml: `${sidebarWidth}px`,
                        }}
                        open={compareSidebarWidth()}
                    >
                        <Toolbar className={classes.toolBarClass}>
                            <Box className="w-100">
                                <DentistHeaderNew
                                    setMobileOpen={setMobileOpen}
                                    mobileOpen={mobileOpen}
                                    isDentist
                                    headerText={headerTitle}
                                    handleDrawerOpen={() => handleDrawer("open")}
                                    handleDrawerClose={handleDrawer}
                                />
                            </Box>
                        </Toolbar>
                    </AppBarV2>

                    <DrawerV2
                        variant="permanent"
                        open={compareSidebarWidth()}
                        sx={{
                            display: {
                                md: "flex",
                                sm: "none",
                                xs: "none",
                            },
                        }}
                    >
                        <DrawerHeader
                            style={{
                                minHeight: "73px",
                                justifyContent: compareSidebarWidth() ? "flex-start" : "center",
                                position: "relative",
                                alignItems: "start",
                            }}
                        >
                            <Link to={`/dentist/dashboard`}>
                                <img
                                    style={{
                                        marginTop: "25px",
                                        marginLeft: compareSidebarWidth() ? "20px" : 0,
                                    }}
                                    width={compareSidebarWidth() ? '' : 35}
                                    height={compareSidebarWidth() ? '' : 36}
                                    className={"cursor-pointer"}
                                    src={compareSidebarWidth() ? dentalLogo : QLogo}
                                    alt='OraQ'
                                />
                            </Link>

                        </DrawerHeader>
                        <List sx={{paddingTop: "0", height: "calc(100vh - 92px)"}}>
                            <Box mt={"18px"} className={classes.drawerBox}>
                                <Box>
                                {navLinks?.map((nav: any, index: number) => (
                                    <React.Fragment key={index}>
                                        {nav.hasSubmenu ? (
                                            <div
                                                onMouseEnter={handleResourcesHover}
                                                onMouseLeave={handleResourcesLeave}
                                            >
                                                <Tooltip
                                                    title={nav.label}
                                                    placement={'bottom'}
                                                    classes={{tooltip: classes.customWidth}}
                                                >
                                                    

                                                    <ListItem
                                                        className={[
                                                            "/dentist/resources",
                                                            ...submenuItems.map(item => item.path).filter(Boolean) // Extract paths & filter out undefined values
                                                        ].some((path: any) => location.pathname.includes(path)) ? classes.activeLink : classes.lineItem}
                                                        sx={{ paddingLeft: "22px", height: "50px", cursor: "pointer" }}
                                                        >
                                                        {nav.icon}
                                                        <Typography
                                                            ml="15px"
                                                            className="fw-regular f-16 lh-21 f-w-400"
                                                            sx={{
                                                                opacity: compareSidebarWidth() ? 1 : 0,
                                                                transition: "left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
                                                            }}
                                                        >
                                                            {nav.label}
                                                        </Typography>
                                                    </ListItem>
                                                </Tooltip>
                                                <ResourcesSubmenu
                                                    open={showResourcesSubmenu}
                                                    anchorEl={resourcesAnchorEl}
                                                    onClose={handleResourcesLeave}
                                                    compareSidebarWidth={compareSidebarWidth}
                                                    classes={classes}
                                                    setDisclaimerOpen={setDisclaimerOpen}
                                                    setSupportFormPopUp={setSupportFormPopUp}
                                                    />
                                            </div>
                                        ) : (
                                            <NavLink
                                                to={nav.path}
                                                className={(navData) => navData.isActive ? classes.activeLink : classes.button}
                                            >
                                                 <Tooltip
                                                    title={nav.label}
                                                    placement={'right'}
                                                    classes={{tooltip: classes.customWidth}}
                                                >

                                                <ListItem sx={{ paddingLeft: "22px", height: "50px" }}>
                                                {nav.icon}
                                                <Typography
                                                    ml="15px"
                                                    className="fw-regular f-16 lh-21 f-w-400"
                                                    sx={{
                                                    opacity: compareSidebarWidth() ? 1 : 0,
                                                    transition: "left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
                                                    }}
                                                >
                                                    {nav.label}
                                                </Typography>
                                                </ListItem>
                                                </Tooltip>
                                            </NavLink>
                                        )}
                                    </React.Fragment>
                                ))}
                                </Box>
                                {/* profile and footer */}
                                <Box>
                                    <NavLink
                                        to={"/dentist/profile"}
                                        className={(navData) => {
                                            return navData.isActive
                                                ? classes.activeLink
                                                : classes.button;
                                        }}
                                    >
                                        <Tooltip title='Profile' placement={'right'}
                                                 classes={{tooltip: classes.customWidth}}>
                                            <ListItem
                                                disablePadding
                                                sx={{height: "50px"}}
                                                className={` d-flex`}
                                            >
                                                <ListItemButton
                                                    sx={{
                                                        justifyContent: compareSidebarWidth()
                                                            ? "initial"
                                                            : "center",
                                                        px: "11px",
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        sx={{
                                                            minWidth: 0,
                                                            mr: compareSidebarWidth() ? 0 : "auto",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {dentistProfileData?.profile_picture ? (
                                                            <Box className={classes.dentistProfileImageContainer}>
                                                                <img
                                                                    src={dentistProfileData?.profile_picture}
                                                                    alt={"user"}
                                                                    className={classes.dentistProfileImage}
                                                                />
                                                            </Box>
                                                        ) : (
                                                            <Box
                                                                className={`${classes.profileNameInitial} d-flex-all-center `}
                                                            >
                                                                <Typography
                                                                    variant={"subtitle1"}
                                                                    component={"span"}
                                                                    mt={0.5}
                                                                    className={`${classes.profileLatter} fw-regular f-16 lh-21 f-w-400`}
                                                                >
                                                                    {dentistProfileData?.first_name?.charAt(0)}
                                                                    {dentistProfileData?.last_name?.charAt(0)}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Box className="d-flex justify-content-between">
                                                                <ListItem
                                                                    sx={{
                                                                        paddingRight: "0px",
                                                                        paddingLeft: "0px",
                                                                    }}
                                                                    className={`${classes.dentistProfileText} fw-regular f-16 lh-21 f-w-400`}
                                                                >
                                                                    {dentistProfileData?.first_name &&
                                                                    dentistProfileData?.last_name
                                                                        ? dentistProfileData?.first_name +
                                                                        " " +
                                                                        dentistProfileData?.last_name
                                                                        : ""}
                                                                </ListItem>
                                                            </Box>
                                                        }
                                                        sx={{
                                                            opacity: compareSidebarWidth() ? 1 : 0,
                                                            transition:
                                                                "left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
                                                        }}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        </Tooltip>

                                    </NavLink>
                                    <Tooltip title='Sign out' placement={'right'}
                                             classes={{tooltip: classes.customWidth}}>
                                        <ListItem
                                            className={classes.logoutButton}
                                            onClick={handleLogout}
                                            sx={{paddingLeft: "22px", height: "50px"}}
                                        >
                                            <FontAwesomeIcon
                                                icon={faRightFromBracket}
                                                className={`f-22`}
                                            />
                                            <Typography
                                                ml={"18px"}
                                                className={`fw-regular f-16 lh-21 f-w-400`}
                                                sx={{
                                                    opacity: compareSidebarWidth() ? 1 : 0,
                                                    transition:
                                                        "left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
                                                }}
                                            >
                                                Sign out
                                            </Typography>
                                        </ListItem>
                                    </Tooltip>
                                    <Divider className={classes.tabletDivider}/>
                                    <ListItem
                                        sx={{
                                            paddingLeft: "22px",
                                            height: "80px",
                                            opacity: compareSidebarWidth() ? 1 : 0,
                                            transition:
                                                "left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Box>
                                            <Typography
                                                fontSize={"12px"}
                                                color={"text.secondary"}
                                                fontWeight={"350"}
                                                className="fw-regular f-12 lh-14"
                                            >
                                                @{year} ORAQ All Rights Reserved.
                                            </Typography>

                                            <Typography
                                                mt={"10px"}
                                                fontSize={"12px"}
                                                color={"text.secondary"}
                                                fontWeight={"350"}
                                                className="fw-regular f-12 lh-14"
                                                style={{
                                                    color: palette.primary.otherDarkPurple,
                                                }}
                                            >
                                                {process.env.REACT_APP_WEB_VERSION}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                </Box>
                            </Box>
                        </List>
                    </DrawerV2>
                    {/* drawer 80 */}
                    <Box
                        className={classes.drawerZIndex}
                        component="nav"
                        aria-label="mailbox folders"
                        sx={{
                            display: {
                                md: "flex",
                                sm: "none",
                                xs: "none",
                            },
                        }
                        }
                    >
                        <Drawer
                            variant="permanent"
                            PaperProps={{
                                sx: {
                                    backgroundColor: palette.common.white,
                                    color: "",
                                    overflowY: "hidden",
                                },
                            }}
                            sx={{
                                display: "block",
                                "& .MuiDrawer-paper": {
                                    boxSizing: "border-box",
                                    width: 64,
                                    overflowY: "hidden",
                                },
                                backgroundColor: `${palette.common.white} !important`,
                            }}
                            open
                        >
                            <Toolbar className={classes.toolbarSmall}>
                                <Link to={`/dentist/dashboard`}>
                                    <img
                                        style={{marginTop: "25px"}}
                                        width={35}
                                        height={36}
                                        className={"cursor-pointer"}
                                        src={QLogo}
                                        alt="logo"
                                    />
                                </Link>
                            </Toolbar>
                            <List sx={{paddingTop: "0", height: "calc(100vh - 92px)"}}>
                                <Box mt={"18px"} className={classes.drawerBox}>
                                    <Box>
                                    {navLinks?.map((nav: any, index: number) => (
                                        <React.Fragment key={index}>
                                            {nav.hasSubmenu ? (
                                            <div
                                                onMouseEnter={handleResourcesHover}
                                                onMouseLeave={handleResourcesLeave}
                                                style={{ position: 'relative' }}
                                            >
                                                <Tooltip
                                                    title={nav.label}
                                                    placement={'right'}
                                                    classes={{tooltip: classes.customWidth}}
                                                >
                                                    <ListItem
                                                        className={location.pathname.includes('/dentist/resources') ? classes.activeLink : classes.button}
                                                        sx={{ paddingLeft: "22px", height: "50px", cursor: "pointer" }}
                                                    >
                                                        {nav.icon}
                                                        <Typography
                                                            // ml="15px"
                                                            className="fw-regular f-16 lh-21 f-w-400"
                                                            sx={{
                                                                opacity: compareSidebarWidth() ? 1 : 0,
                                                                // transition: "left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
                                                            }}
                                                        >
                                                            {/* {nav.label} */}
                                                        </Typography>
                                                    </ListItem>
                                                </Tooltip>
                                                
                                                <ResourcesSubmenu
                                                    open={showResourcesSubmenu}
                                                    anchorEl={resourcesAnchorEl}
                                                    onClose={handleResourcesLeave}
                                                    compareSidebarWidth={compareSidebarWidth}
                                                    classes={classes}
                                                    setDisclaimerOpen={setDisclaimerOpen}
                                                    setSupportFormPopUp={setSupportFormPopUp}
                                                />
                                          </div>
                                            ) : (
                                            // Original nav link rendering code here
                                            <NavLink
                                                to={nav.path}
                                                className={(navData) => navData.isActive ? classes.activeLink : classes.button}
                                            >
                                                <Tooltip
                                                    title={nav.label}
                                                    placement={'right'}
                                                    classes={{tooltip: classes.customWidth}}
                                                >
                                                <ListItem sx={{ paddingLeft: "22px", height: "50px" }}>
                                                {nav.icon}
                                                    <Typography
                                                        ml="15px"
                                                        className="fw-regular f-16 lh-21 f-w-400"
                                                        sx={{
                                                        opacity: compareSidebarWidth() ? 1 : 0,
                                                        transition: "left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
                                                        }}
                                                    >
                                                        {nav.label}
                                                    </Typography>
                                                </ListItem>
                                                </Tooltip>
                                            </NavLink>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    </Box>
                                    {/* profile and footer */}
                                    <Box>
                                        <NavLink
                                            to={"/dentist/profile"}
                                            className={(navData) => {
                                                return navData.isActive
                                                    ? classes.activeLink
                                                    : classes.button;
                                            }}
                                        >
                                            <Tooltip title='Profile' placement={'right'}
                                                     classes={{tooltip: classes.customWidth}}>
                                                <ListItem
                                                    sx={{height: "50px"}}
                                                    className={`d-flex`}
                                                    disablePadding
                                                >
                                                    <ListItemButton
                                                        className="justify-center "
                                                        sx={{px: "11px"}}
                                                    >
                                                        <ListItemIcon
                                                            className="justify-center align-center"
                                                            sx={{
                                                                minWidth: 0,
                                                                mr: "auto",
                                                            }}
                                                        >
                                                            {dentistProfileData?.profile_picture ? (
                                                                <Box
                                                                    className={classes.dentistProfileImageContainer}
                                                                >
                                                                    <img
                                                                        src={dentistProfileData?.profile_picture}
                                                                        alt={"user"}
                                                                        className={classes.dentistProfileImage}
                                                                    />
                                                                </Box>
                                                            ) : (
                                                                <Box
                                                                    className={`${classes.profileNameInitial} d-flex-all-center `}
                                                                >
                                                                    <Typography
                                                                        variant={"subtitle1"}
                                                                        component={"span"}
                                                                        mt={0.5}
                                                                        className={`${classes.profileLatter} fw-regular f-16 lh-21 f-w-400`}
                                                                    >
                                                                        {dentistProfileData?.first_name?.charAt(0)}
                                                                        {dentistProfileData?.last_name?.charAt(0)}
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                        </ListItemIcon>
                                                    </ListItemButton>
                                                </ListItem>
                                            </Tooltip>
                                        </NavLink>

                                        <Tooltip title='Sign out' placement={'right'}
                                                 classes={{tooltip: classes.customWidth}}>
                                            <ListItem
                                                className={classes.logoutButton}
                                                onClick={handleLogout}
                                                sx={{paddingLeft: "22px", height: "50px"}}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faRightFromBracket}
                                                    className={`f-22`}
                                                />
                                            </ListItem>
                                        </Tooltip>

                                        <Divider className={classes.tabletDivider}/>
                                        <ListItem
                                            sx={{
                                                paddingLeft: "22px",
                                                height: "80px",
                                                opacity: compareSidebarWidth() ? 1 : 0,
                                                transition:
                                                    "left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    fontSize={"12px"}
                                                    color={"text.secondary"}
                                                    fontWeight={"350"}
                                                    className="fw-regular f-12 lh-14"
                                                >
                                                    @{year} ORAQ All Rights Reserved.
                                                </Typography>

                                                <Typography
                                                    mt={"10px"}
                                                    fontSize={"12px"}
                                                    color={"text.secondary"}
                                                    fontWeight={"350"}
                                                    className="fw-regular f-12 lh-14"
                                                    style={{
                                                        color: palette.primary.otherDarkPurple,
                                                    }}
                                                >
                                                    {process.env.REACT_APP_WEB_VERSION}
                                                </Typography>
                                            </Box>
                                        </ListItem>
                                    </Box>
                                </Box>
                            </List>
                        </Drawer>
                    </Box>
                </Box>
            )
            }
            {/* drawer 250 for overly */}
            <Drawer
                PaperProps={{
                    sx: {
                        backgroundColor: palette.common.white,
                        zIndex: 1024,
                        overflow: 'unset'
                    },
                }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: "block",
                    zIndex: 1024,
                    "& .MuiDrawer-paper": {
                        zIndex: 1024,
                        boxSizing: "border-box",
                        width: drawerWidth,
                    },
                    backgroundColor: "transparent !important",
                }}
            >
                <Toolbar className={classes.toolBarSmall250}>
                    <Link to={`/dentist/dashboard`}>
                        <img
                            style={{marginTop: "25px"}}
                            className={"cursor-pointer"}
                            src={dentalLogo}
                            alt="logo"
                        />
                    </Link>
                    <IconButton
                        sx={{
                            marginTop: "25px",
                            display: {sm: "inline-flex"},
                        }}
                        className={classes.arrowStyle}
                        onClick={handleDrawerToggle}
                    >
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            className={`f-14 f-w-900 cursor-pointer`}
                        />
                    </IconButton>
                </Toolbar>
                <List sx={{paddingTop: "0", height: "calc(100vh - 92px)"}}>
                    <Box mt={"10px"} className={classes.drawerBox}>
                        <Box>
                            {navLinks?.map((nav: any, index: number) => (
                                <React.Fragment key={index}>
                                    {
                                        //Add index in array to show Divider
                                        [4].includes(index) && (
                                            <Divider className={classes.desktopDivider}/>
                                        )
                                    }
                                    {nav.hasSubmenu ? (
                                        <div
                                            onMouseEnter={handleResourcesHover}
                                            onMouseLeave={handleResourcesLeave}
                                            style={{ position: 'relative' }}
                                        >
                                            <Tooltip title={nav.label} placement={'right'}
                                                    classes={{tooltip: classes.customWidth}}>
                                                <span
                                                    onClick={nav.action}
                                                    className={
                                                        (nav?.id === 7 ? disclaimerOpen : openSupportFormPopUp)
                                                            ? !nav?.disable
                                                                ? classes.activeLink
                                                                : classes.disableButton
                                                            : classes.button
                                                    }
                                                >
                                                    <ListItem
                                                        sx={{paddingLeft: "22px", height: "50px"}}
                                                        disabled={nav?.disable}
                                                    >
                                                        {nav.icon}
                                                        <Typography
                                                            mt={"7px"}
                                                            ml={"15px"}
                                                            className={`fw-regular f-16 lh-21 f-w-400`}
                                                        >
                                                            {nav.label}
                                                        </Typography>
                                                    </ListItem>
                                                </span></Tooltip>
                                            <ResourcesSubmenu
                                                open={showResourcesSubmenu}
                                                anchorEl={resourcesAnchorEl}
                                                onClose={handleResourcesLeave}
                                                compareSidebarWidth={compareSidebarWidth}
                                                classes={classes}
                                                setDisclaimerOpen={setDisclaimerOpen}
                                                setSupportFormPopUp={setSupportFormPopUp}
                                            />
                                        </div>
                                            
                                    ) : (
                                        <NavLink
                                            key={index}
                                            to={nav?.disable ? "#" : nav?.path}
                                            className={(navData) => {
                                                return (!(nav?.id === 7 ? disclaimerOpen : openSupportFormPopUp)) && navData.isActive
                                                    ? !nav?.disable
                                                        ? classes.activeLink
                                                        : classes.disableButton
                                                    : classes.button;
                                            }}
                                        >
                                            <Tooltip title={nav.label} placement={'right'}
                                                     classes={{tooltip: classes.customWidth}}>
                                                <ListItem
                                                    sx={{paddingLeft: "22px", height: "50px"}}
                                                    disabled={nav?.disable}
                                                >
                                                    {nav.icon}
                                                    <Typography
                                                        ml={"15px"}
                                                        className={`fw-regular f-16 lh-21 f-w-400`}
                                                    >
                                                        {nav.label}
                                                    </Typography>
                                                </ListItem></Tooltip>
                                        </NavLink>
                                    )}
                                </React.Fragment>
                            ))}
                        </Box>
                        <Box>
                            <NavLink
                                to={"/dentist/profile"}
                                className={(navData) => {
                                    return navData.isActive ? classes.activeLink : classes.button;
                                }}
                            >
                                <Tooltip title='Profile' placement={'right'} classes={{tooltip: classes.customWidth}}>
                                    <ListItem
                                        disablePadding
                                        sx={{height: "50px"}}
                                        className={` d-flex`}
                                    >
                                        <ListItemButton
                                            sx={{
                                                justifyContent: "center",
                                                px: "11px",
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: "auto",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {dentistProfileData?.profile_picture ? (
                                                    <Box className={classes.dentistProfileImageContainer}>
                                                        <img
                                                            src={dentistProfileData?.profile_picture}
                                                            alt={"user"}
                                                            className={classes.dentistProfileImage}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        className={`${classes.profileNameInitial} d-flex-all-center `}
                                                    >
                                                        <Typography
                                                            variant={"subtitle1"}
                                                            mt={0.5}
                                                            component={"span"}
                                                            className={`${classes.profileLatter} fw-regular f-16 lh-21 f-w-400`}
                                                        >
                                                            {dentistProfileData?.first_name?.charAt(0)}
                                                            {dentistProfileData?.last_name?.charAt(0)}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Box className="d-flex justify-content-between">
                                                        <ListItem
                                                            sx={{
                                                                paddingRight: "0px",
                                                                paddingLeft: "0px",
                                                            }}
                                                            className={`${classes.dentistProfileText} fw-regular f-16 lh-21 f-w-400`}
                                                        >
                                                            {dentistProfileData?.first_name &&
                                                            dentistProfileData?.last_name
                                                                ? dentistProfileData?.first_name +
                                                                " " +
                                                                dentistProfileData?.last_name
                                                                : ""}
                                                        </ListItem>
                                                    </Box>
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </Tooltip>
                            </NavLink>

                            <Tooltip title='Sign out' placement={'right'} classes={{tooltip: classes.customWidth}}>
                                <ListItem
                                    className={classes.logoutButton}
                                    onClick={handleLogout}
                                    sx={{paddingLeft: "22px", height: "50px"}}
                                >
                                    <FontAwesomeIcon
                                        icon={faRightFromBracket}
                                        className={`f-22`}
                                    />
                                    <Typography
                                        ml={"18px"}
                                        className={` fw-regular f-16 lh-21 f-w-400`}
                                    >
                                        Sign out
                                    </Typography>
                                </ListItem>
                            </Tooltip>

                            {/* </ListItem> */}
                            <Divider/>
                            <ListItem sx={{height: "80px"}}>
                                <Box className={`d-flex flex-col`}>
                                    <Typography
                                        fontSize={"12px"}
                                        color={"text.secondary"}
                                        fontWeight={"350"}
                                        className="fw-regular f-12 lh-14"
                                    >
                                        @{year} ORAQ All Rights Reserved.
                                    </Typography>

                                    <Typography
                                        mt={"10px"}
                                        fontSize={"12px"}
                                        color={"text.secondary"}
                                        fontWeight={"350"}
                                        className="fw-regular f-12 lh-14 "
                                        style={{
                                            color: palette.primary.otherDarkPurple,
                                        }}
                                    >
                                        {process.env.REACT_APP_WEB_VERSION}
                                    </Typography>
                                </Box>
                            </ListItem>
                        </Box>
                    </Box>
                </List>
            </Drawer>
            {disclaimerOpen && <DisclaimerPopUp open={disclaimerOpen} handleClose={handleClose}/>}
            {openSupportFormPopUp && <SupportFormPopUp open={openSupportFormPopUp} setOpen={setSupportFormPopUp}/>}
        </Box>
    );
}
