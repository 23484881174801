import React from 'react';
import {makeStyles} from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {Box, Divider, Grid, Typography} from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import {useDispatch, useSelector} from "react-redux";
import ExamGroup from "../index";
import ExamsCustomFields from "../../ExamsCustomFields";
import { examConstantInterface,  examGroupsDataForCE} from "../../../../../../utils/constant/examConstant";
import theme from "../../../../../../theme";
import QLogo from "../../../../../../assets/images/QLogo.png";
import {updateChatBoxValueAction} from "../../../../../../redux/actions/master/chatBoxActions";
import {RootState} from "../../../../../../redux/reducers/rootReducer";

const useStyles = makeStyles((theme: any) => ({
    accordionRoot: {
        margin: '0 !important',
        '& .MuiAccordion-root': {
            margin: '0 !important'
        }
    },
    accordionSummary: {
        color: theme.palette.v2.primary.main,
        height: '42px',
        backgroundColor: theme.palette.v2.primary.primary25,
        padding: '10px 12px',
        border: `1px solid ${theme.palette.v2.primary.main}`
    },
    accordionDetails: {
        padding: '16px 20px !important',
        backgroundColor: theme.palette.common.white
    },
    expanded: {
        '&$expanded': {
            minHeight: 42,
            maxHeight: 42,
        }
    },
}));
type Props = {
    meta: any;
    data: any;
    getData?: any
};
const ExamGroupCE = React.memo((props: Props) => {
    const {meta, data, getData} = props;
    const classes = useStyles();
    const dispatch = useDispatch()
    const { examFindingReducer: {examFindingData},} = useSelector((state: RootState) => state) as any
    const [expandedSubMenu, setExpandedSubMenu] = React.useState<boolean>(true) as any;

    const showNormalLabel = (info: any) => {
        return info?.options?.filter((f: any) => f?.label?.length < 1)?.length
    }
    const getMultipleFindingsData = (res: any) => {
        if (res?.isMulti && res?.examGroup && examFindingData && examFindingData[res?.examGroup] && examFindingData[res?.examGroup][res?.key]) {
            const findingLength = examFindingData[res?.examGroup][res?.key]?.length
            const newFindings = []
            for (let index = findingLength; index > 0; index--) {
                newFindings?.push(res)
            }
            return newFindings
        }
        return [res]
    }

    const examGroupWiseFilteredData = (key: any) => {
        return getData && getData?.filter((f: examConstantInterface) => f?.examGroup === key);
    };
    const hasFindingData = () => {
        return ((examGroupsDataForCE))?.filter((res: any) => examGroupWiseFilteredData(res?.key)?.length > 0)
    }
    return (
        <React.Fragment>
            <Accordion
                expanded={expandedSubMenu}
                onChange={() => setExpandedSubMenu(!expandedSubMenu)}
                className={expandedSubMenu ? classes.accordionRoot : ''}
            >
                <AccordionSummary
                    expandIcon={
                        <FontAwesomeIcon className='cursor-pointer f-16' icon={faAngleUp}
                                         color={theme.palette.v2.primary.main}/>
                    }
                    className={`${classes.accordionSummary} ${classes.expanded}`}
                > <Box className='d-flex justify-content-between align-center w-100' mr={2.5}>
                    <Typography className='f-16 f-w-400 fw-regular'>{meta?.label}</Typography>
                </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    {/*display extra oral and intra oral group*/}
                    {data.map((res: any, i: number) =>
                        (<React.Fragment key={i}>
                            {/* Check if finding is from  existing exam then show only findings those are selected from popup*/}
                            <Box>
                                <Box className='d-flex align-center' mb={1}>
                                    <Typography mr={1.2}
                                                className={`${showNormalLabel(res) ? 'f-14 fw-regular' : 'f-18 f-w-500 fw-medium'} `}
                                                color={showNormalLabel(res) ? theme.palette.common.black50 : theme.palette.v2.primary.main}>{res?.title}</Typography>
                                    {res?.hasTooltip &&
                                        <img
                                            width={25}
                                            height={25}
                                            className={"cursor-pointer"}
                                            src={QLogo}
                                            alt="logo"
                                            onClick={() => dispatch(updateChatBoxValueAction({
                                                chatBoxValue: true,
                                                keyName: res?.key
                                            }))}
                                        />
                                    }
                                </Box>
                                {[res]?.map((_: any, j: number) => <React.Fragment key={j}>
                                        <Grid container columnSpacing={1} rowSpacing={1} mb={1}>
                                            {res?.options?.map((option: any, k: number) => (
                                                <React.Fragment key={k}>
                                                    <ExamsCustomFields
                                                        displayOdontogramForm={res?.displayOdontogramForm}
                                                        exam={res?.examGroup}
                                                        fieldData={option} finding={res?.key} index={j}
                                                        isMulti={res?.isMulti}
                                                        findingData={getMultipleFindingsData(res)}
                                                        disableFindingTypes={res?.disableFindingTypes}/>
                                                </React.Fragment>
                                            ))}
                                        </Grid>

                                    </React.Fragment>
                                )}
                            </Box>
                            {!res?.isMulti && (data?.length !== (i + 1)) &&
                                <Divider sx={{width: '100%', margin: '15px 0px'}}/>}
                        </React.Fragment>)
                    )}
                    <React.Fragment>
                        {/*Display other group findings inside the extra oral and intra oral sections as per GitHub issue #10939, and set the order accordingly.*/}
                        {meta?.key === 'extra_oral' ?
                            <React.Fragment>
                                {/*exam group is located inside the extra oral*/}
                                {hasFindingData()?.filter((rr: any,) => rr?.key === 'tmj_exam' || rr?.key === 'muscle_exam')?.map((mainRes: any, i: number) =>
                                    <ExamGroup key={i} meta={mainRes} data={examGroupWiseFilteredData(mainRes?.key)}/>)
                                }
                            </React.Fragment> :
                            <React.Fragment>
                                {/*exam group is located inside the intra oral*/}
                                {hasFindingData()?.filter((rr: any) => rr?.key === 'wear_exam' || rr?.key === 'occlusion_exam')?.map((mainRes: any, i: number) =>
                                    <ExamGroup key={i} meta={mainRes} data={examGroupWiseFilteredData(mainRes?.key)}/>)}
                            </React.Fragment>
                        }
                    </React.Fragment>
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    )
})

export default ExamGroupCE;