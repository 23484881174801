import {Grid, InputAdornment} from "@mui/material";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef} from "react";
import {IMaskInput} from 'react-imask';
import {handleChangeWithDebounce, marks10, usDateFormat,} from "../../../../utils/helper";
import CollapsibleBox from "../../UIComponents/CollapsibleBox";
import TextBox from "../../UIComponents/TextBox";
import theme from "../../../../theme";
import {RootState} from "../../../../redux/reducers/rootReducer";
import RangeSlider from "../../UIComponents/RangeSlider";
import RadioSelect from "../../UIComponents/RadioSelect";
import CheckboxSelect from "../../UIComponents/CheckboxSelect";
import InputHeading from "../../UIComponents/InputHeading";
import DropDown from "../../UIComponents/DropDown";
import DateSelect from "../../UIComponents/DateSelect";
import {dentalInfoValidator} from "../../../../validation/preIntakeValidator";
import {
    updateDentalInfoAction,
    updatePreIntakeValidationsAction,
} from "../../../../redux/actions/dentist/preIntakeActions";
import {
    dentalInfoCheckboxItem,
    feelDentalHealth,
    homeCareProtectiveFactors,
    radioButtonOptions,
} from "../../../../utils/constant/preIntakeConstant/preIntakeConstant";
import {textLengthStyles} from "../../../../pages/dentist/PreIntake";

type Props = {
    disabledField?: boolean;
    info?:string;
};

const DentalInformation = React.memo((props: Props) => {
    const {disabledField, info} = props;
    const lengthClasses = textLengthStyles();
    const dispatch = useDispatch();
    const noteRef = useRef(null) as any
    const fillingsRef: any = useRef(null);
    const {dentalInfo, errors} = useSelector((state: RootState) => state.preIntake );
    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    const threeMaskDefinitions = {
        mask: '###',
        definitions: {
            '#': /[0-9]/,
        },
    };

    useEffect(() => {
        const handleWheel = function (e: any) {
            return e.preventDefault();
        };
        fillingsRef?.current?.addEventListener("wheel", handleWheel);
        return () => {
            // eslint-disable-next-line
            fillingsRef?.current?.removeEventListener("wheel", handleWheel);
        };
    }, []);

    const handleChange = handleChangeWithDebounce((e: any) => {
        const {value, name, id} = e.target as any;
        let updatedDentalInfo = dentalInfo;
        updatedDentalInfo[name || id] = value;
        dispatch(updateDentalInfoAction(updatedDentalInfo));

        // for the update error
        if (errors?.dentalInfo?.[name || id]) {
            const dentalInfoValid = dentalInfoValidator(updatedDentalInfo) as any;
            dispatch(
                updatePreIntakeValidationsAction({
                    ...errors,
                    dentalInfo: {
                        ...errors.dentalInfo,
                        [name || id]: errors.dentalInfo[name || id]
                            ? dentalInfoValid.errors[name || id]
                            : "",
                    },
                })
            );
        }
    });

    const handleChangeNumberField = (e: any) => {
        const {value, name, id} = e.target as any;
        let updatedDentalInfo = dentalInfo;
        updatedDentalInfo[name || id] = value;
        dispatch(updateDentalInfoAction(updatedDentalInfo));

        // for the update error
        if (errors?.dentalInfo?.[name || id]) {
            const dentalInfoValid = dentalInfoValidator(updatedDentalInfo) as any;
            dispatch(
                updatePreIntakeValidationsAction({
                    ...errors,
                    dentalInfo: {
                        ...errors.dentalInfo,
                        [name || id]: errors.dentalInfo[name || id]
                            ? dentalInfoValid.errors[name || id]
                            : "",
                    },
                })
            );
        }
    };

    const handleChangeVal = (val?: any, key?: any) => {
        let updatedDentalInfo = dentalInfo;
        updatedDentalInfo[key] = val;
        dispatch(updateDentalInfoAction(updatedDentalInfo));
    };
    const checkBoxChangeHomeCare = (e: any, item: any) => {
        let updatedDentalInfo = dentalInfo;
        if (e?.target?.checked === true) {
            updatedDentalInfo.homeCareProtectiveFactors?.push(item.value);
        } else {
            updatedDentalInfo.homeCareProtectiveFactors =
                dentalInfo?.homeCareProtectiveFactors?.filter(
                    (f: any) => f !== item.value
                );
        }
        dispatch(updateDentalInfoAction(updatedDentalInfo));
    };
    const checkBoxChange = (e: any, item: any) => {
        let updatedDentalInfo = dentalInfo;
        if (e?.target?.checked === true) {
            updatedDentalInfo?.checkIfYouHaveEverHadFollowing?.push(item.value);
        } else {
            updatedDentalInfo.checkIfYouHaveEverHadFollowing =
                dentalInfo.checkIfYouHaveEverHadFollowing?.filter(
                    (f: any) => f !== item.value
                );
        }
        dispatch(updateDentalInfoAction(updatedDentalInfo));
    };
    const clearClickHandler = (id: string) => {
        let updatedDentalInfo = dentalInfo;
        updatedDentalInfo[id] = null;
        dispatch(updateDentalInfoAction(updatedDentalInfo));
    };
    const removeOtherValue = (id: any) => {
        let updatedDentalInfo = dentalInfo;
        updatedDentalInfo[id] = "";
        dispatch(updateDentalInfoAction(updatedDentalInfo));

        // for the update error
        if (errors?.dentalInfo?.[id]) {
            const dentalInfoValid = dentalInfoValidator(updatedDentalInfo) as any;
            dispatch(
                updatePreIntakeValidationsAction({
                    ...errors,
                    dentalInfo: {
                        ...errors.dentalInfo,
                        [id]: errors.dentalInfo[id]
                            ? dentalInfoValid.errors[id]
                            : "",
                    },
                })
            );
        }
    };

    return (
        <CollapsibleBox title={"Dental Information"} id={"dentalInfo"} info={info}>
            <Grid container rowSpacing={1.5} columnSpacing={2.5}>
                <Grid item xs={12}>
                    <Grid container rowSpacing={1.5} columnSpacing={2.5}>
                        <Grid item lg={5.8} md={5.8} sm={11.8} xs={11.8}>
                            <RangeSlider
                                disabled={disabledField}
                                sliderTitle={"Dental Anxiety (Scale From 0-10)"}
                                leftLabel={"None"}
                                rightLabel={"Very Anxious"}
                                sliderProps={{
                                    step: 1,
                                    min: 0,
                                    max: 10,
                                    marks: marks10,
                                    size: "medium",
                                    defaultValue: dentalInfo?.dentalAnxiety,
                                }}
                                onChange={(e: any) => handleChangeVal(e, "dentalAnxiety")}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextBox
                        disabled={disabledField}
                        label={"Reason For Scheduling This Visit"}
                        labelProps={{
                            htmlFor: "reasonForSchedulingThisVisit",
                        }}
                        maxLength={50}
                        resize={true}
                        resizeVerticalScreen={true}
                        inputProps={{
                            id: "reasonForSchedulingThisVisit",
                            name: "reasonForSchedulingThisVisit",
                            onChange: (e: any) => handleChange(e),
                            defaultValue: dentalInfo?.reasonForSchedulingThisVisit || "",
                            error: false,
                            helperText: "",
                            fullWidth: true,
                            InputProps: {
                                classes: {
                                    root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                    input: "textFieldStartEndIconNumbers",
                                },
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={lengthClasses.textLengthCount}
                                        sx={{alignSelf: 'flex-end', paddingBottom: '15px'}}
                                    >
                                        {`${dentalInfo?.reasonForSchedulingThisVisit?.length || 0} / 50`}
                                    </InputAdornment>
                                )
                            },
                            multiline: true,
                            ref: noteRef
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextBox
                        disabled={disabledField}
                        label={"Name Of Previous Office"}
                        labelProps={{
                            htmlFor: "nameOfPreviousOffice",
                        }}
                        maxLength={30}
                        resize={true}
                        resizeVerticalScreen={true}
                        inputProps={{
                            id: "nameOfPreviousOffice",
                            name: "nameOfPreviousOffice",
                            onChange: (e: any) => handleChange(e),
                            defaultValue: dentalInfo?.nameOfPreviousOffice,
                            error: false,
                            helperText: "",
                            fullWidth: true,
                            InputProps: {
                                classes: {
                                    root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                    input: "textFieldStartEndIconNumbers",
                                },
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={lengthClasses.textLengthCount}
                                        sx={{alignSelf: 'flex-end', paddingBottom: '15px'}}
                                    >
                                        {`${dentalInfo?.nameOfPreviousOffice?.length || 0} / 30`}
                                    </InputAdornment>
                                )
                            },
                            multiline: true,
                            ref: noteRef
                        }}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <InputHeading
                        title={" When was your last visit at the dentist office?"}
                    />
                    <DateSelect
                        disabled={disabledField}
                        disableFuture
                        clearClickHandler={() =>
                            clearClickHandler("lastVisitAtDentistOffice")
                        }
                        value={dentalInfo?.lastVisitAtDentistOffice || null}
                        showClearIcon
                        onChange={(newValue: any) => {
                            let newFormatted = moment(new Date(newValue))?.format(
                                usDateFormat
                            );
                            handleChangeVal(newFormatted, "lastVisitAtDentistOffice");
                        }}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <InputHeading
                        title={"When was the last time you had dental x-rays taken?"}
                    />
                    <DateSelect
                        disabled={disabledField}
                        disableFuture
                        clearClickHandler={() =>
                            clearClickHandler("lastTimeYouHadDentalXrayTaken")
                        }
                        value={dentalInfo?.lastTimeYouHadDentalXrayTaken || null}
                        showClearIcon
                        onChange={(newValue: any) => {
                            let newFormatted = moment(new Date(newValue))?.format(
                                usDateFormat
                            );
                            handleChangeVal(newFormatted, "lastTimeYouHadDentalXrayTaken");
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputHeading
                        title={
                            "Does your physician recommend/require you to take antibiotic pre-medication prior to your dental appointment?"
                        }
                        whiteSpace={true}
                    />
                    <RadioSelect
                        disabled={disabledField}
                        options={radioButtonOptions}
                        groupProps={{
                            name: "doesYourPhysicianRecommendAntibiotic",
                            id: "doesYourPhysicianRecommendAntibiotic",
                            defaultValue: dentalInfo?.doesYourPhysicianRecommendAntibiotic,
                            onChange: (e: any) => {
                                handleChange(e);
                                removeOtherValue("antibioticPremeditation");
                            },
                        }}
                    />
                </Grid>
                {dentalInfo?.doesYourPhysicianRecommendAntibiotic === "yes" && (
                    <Grid item xs={12} sm={6}>
                        <TextBox
                            disabled={disabledField}
                            label={"Please specify here"}
                            labelProps={{
                                htmlFor: "antibioticPremeditation",
                            }}
                            maxLength={50}
                            resize={true}
                            resizeVerticalScreen={true}
                            inputProps={{
                                id: "antibioticPremeditation",
                                name: "antibioticPremeditation",
                                onChange: (e: any) => handleChange(e),
                                defaultValue: dentalInfo?.antibioticPremeditation,
                                error: errors.dentalInfo?.antibioticPremeditation,
                                helperText: errors.dentalInfo?.antibioticPremeditation,
                                fullWidth: true,
                                InputProps: {
                                    classes: {
                                        root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                        input: "textFieldStartEndIconNumbers",
                                    },
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            className={lengthClasses.textLengthCount}
                                            sx={{alignSelf: 'flex-end', paddingBottom: '15px'}}
                                        >
                                            {`${dentalInfo?.antibioticPremeditation?.length || 0} / 50`}
                                        </InputAdornment>
                                    )
                                },
                                multiline: true,
                                ref: noteRef
                            }}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={6}>
                    <InputHeading title={"Do you feel your dental health is"}/>
                    <DropDown
                        disabled={disabledField}
                        selectOptions={feelDentalHealth}
                        defaultValue={dentalInfo?.feelDentalHealth}
                        selectProps={{
                            id: "feelDentalHealth",
                            name: "feelDentalHealth",
                            onChange: (e: any) => handleChange(e),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputHeading
                        title={"Please check if you have ever had any of the following:"}
                    />
                    <Grid
                        container
                        border={`1px solid ${theme.palette.common.borderGrey}`}
                        p={"12px"}
                        className="border-radius-8"
                    >
                        {dentalInfoCheckboxItem?.map((res: any, i: any) => (
                            <Grid item xs={12} sm={4} lg={3} key={i}>
                                <CheckboxSelect
                                    disabled={disabledField}
                                    label={res?.label}
                                    checkBoxProps={{
                                        name: "checkIfYouHaveEverHadFollowing",
                                        checked:
                                            dentalInfo?.checkIfYouHaveEverHadFollowing?.includes(
                                                res.value
                                            ),
                                        value: dentalInfo?.checkIfYouHaveEverHadFollowing?.includes(
                                            res.value
                                        ),
                                        onChange: (e: any) => checkBoxChange(e, res),
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container mt={1.5} columnSpacing={2.5}>
                        <Grid item lg={5.8} md={5.8} sm={11.8} xs={11.8}>
                            <RangeSlider
                                disabled={disabledField}
                                sliderTitle={"Rate The Appearance Of Your Mouth (Smile, Lips, Visible Teeth) From 0 To 10"}
                                leftLabel={"Needs Improvement"}
                                rightLabel={"Confident About Smile"}
                                sliderProps={{
                                    step: 1,
                                    min: 0,
                                    max: 10,
                                    marks: marks10,
                                    size: "medium",
                                    defaultValue: dentalInfo?.rateAppearanceOfYourSmile,
                                }}
                                onChange={(e: any) =>
                                    handleChangeVal(e, "rateAppearanceOfYourSmile")
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid mt={1} item xs={12} md={6}>
                    <InputHeading
                        title={
                            "Do you wish you could correct the appearance of your smile?"
                        }
                    />
                    <RadioSelect
                        disabled={disabledField}
                        options={radioButtonOptions}
                        groupProps={{
                            name: "correctAppearanceOfYourSmile",
                            id: "correctAppearanceOfYourSmile",
                            defaultValue: dentalInfo?.correctAppearanceOfYourSmile,
                            onChange: (e: any) => handleChange(e),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputHeading title={"Do you wish you had a whiter smile?"}/>
                    <RadioSelect
                        disabled={disabledField}
                        options={radioButtonOptions}
                        groupProps={{
                            name: "hadWhiterSmile",
                            id: "hadWhiterSmile",
                            defaultValue: dentalInfo?.hadWhiterSmile,
                            onChange: (e: any) => handleChange(e),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputHeading title={"Do you snack more than 3x/day?"}/>
                    <RadioSelect
                        disabled={disabledField}
                        options={radioButtonOptions}
                        groupProps={{
                            name: "doYouSnackMoreThatDay",
                            id: "doYouSnackMoreThatDay",
                            defaultValue: dentalInfo?.doYouSnackMoreThatDay,
                            onChange: (e: any) => handleChange(e),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputHeading title={"Which Brand And Type Of Toothpaste Do You Use?"}/>
                    <TextBox
                        disabled={disabledField}
                        labelProps={{
                            htmlFor: "toothpasteBrand",
                        }}
                        maxLength={50}
                        resize={true}
                        resizeVerticalScreen={true}
                        inputProps={{
                            id: "toothpasteBrand",
                            name: "toothpasteBrand",
                            onChange: (e: any) => handleChange(e),
                            defaultValue: dentalInfo?.toothpasteBrand,
                            error: false,
                            helperText: "",
                            fullWidth: true,
                            InputProps: {
                                classes: {
                                    root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                    input: "textFieldStartEndIconNumbers",
                                },
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={lengthClasses.textLengthCount}
                                        sx={{alignSelf: 'flex-end', paddingBottom: '15px'}}
                                    >
                                        {`${dentalInfo?.toothpasteBrand?.length || 0} / 50`}
                                    </InputAdornment>
                                )
                            },
                            multiline: true,
                            ref: noteRef
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputHeading
                        title={"Have you had a tooth extracted in the last 3 years?"}
                    />
                    <RadioSelect
                        disabled={disabledField}
                        options={radioButtonOptions}
                        groupProps={{
                            name: "toothExtractedInLastThreeYear",
                            id: "toothExtractedInLastThreeYear",
                            defaultValue: dentalInfo?.toothExtractedInLastThreeYear,
                            onChange: (e: any) => handleChange(e),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextBox
                        disabled={disabledField}
                        label={"How Many Fillings Have You Had In The Last 3 Years?"}
                        labelProps={{
                            htmlFor: "fillingsInLastThreeYear",
                        }}
                        maxLength={3}
                        placeholder="Enter Number"
                        inputProps={{
                            id: "fillingsInLastThreeYear",
                            name: "fillingsInLastThreeYear",
                            onWheel: (e: any) => {
                                e.target.blur()
                            },
                            onKeyDown: (e: any) =>
                                exceptThisSymbols.includes(e.key) && e.preventDefault(),
                            onChange: (e: any) => {
                                const onlyNum = e.target.value.replace(/[0-9]+.\w+/g, "");
                                if (onlyNum?.length < 4) {
                                    handleChangeNumberField(e);
                                }
                            },
                            value: dentalInfo?.fillingsInLastThreeYear,
                            error: false,
                            helperText: "",
                            fullWidth: true,
                            ref: fillingsRef && noteRef,
                            InputProps: {
                                classes: {
                                    root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                    input: "textFieldStartEndIcon",
                                },
                                inputComponent: IMaskInput,
                                inputProps: {
                                    ...threeMaskDefinitions,
                                    value: dentalInfo?.fillingsInLastThreeYear,
                                },
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={lengthClasses.textLengthCount}
                                    >
                                        {`${dentalInfo?.fillingsInLastThreeYear?.length || 0} / 3`}
                                    </InputAdornment>
                                ),
                            },
                        }}

                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputHeading title={"Family history of cavities/tooth loss?"}/>
                    <RadioSelect
                        disabled={disabledField}
                        options={radioButtonOptions}
                        groupProps={{
                            name: "familyHistoryOfCavities",
                            id: "familyHistoryOfCavities",
                            defaultValue: dentalInfo?.familyHistoryOfCavities,
                            onChange: (e: any) => handleChange(e),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputHeading title={"Homecare - Protective Factors"}/>
                    <Grid
                        container
                        border={`1px solid ${theme.palette.common.borderGrey}`}
                        p={"12px"}
                        className="border-radius-8"
                    >
                        {homeCareProtectiveFactors?.map((res: any, i: any) => (
                            <Grid item xs={12} sm={6} lg={4} key={i}>
                                <CheckboxSelect
                                    disabled={disabledField}
                                    label={res?.label}
                                    checkBoxProps={{
                                        name: "homeCareProtectiveFactors",
                                        checked: dentalInfo?.homeCareProtectiveFactors?.includes(
                                            res.value
                                        ),
                                        value: dentalInfo?.homeCareProtectiveFactors?.includes(
                                            res.value
                                        ),
                                        onChange: (e: any) => checkBoxChangeHomeCare(e, res),
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </CollapsibleBox>
    );
})

export default DentalInformation;
