import {Box, Button, Card, CardContent, CardHeader, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles';
import React from 'react'
import {useSelector} from "react-redux";
import theme from '../../../../theme';
import ChangeRiskDialsView from '../../../dentist/ChangeRiskDialsView';
import RedCross from '../../../../assets/images/wellnessProfile/redCross.png';
import {RootState} from "../../../../redux/reducers/rootReducer";
import SaveAltIcon from '@mui/icons-material/SaveAlt';

const useStyles = makeStyles((theme: any) => ({
    cardHeader: {
        height: '48px',
        borderRadius: '16px 16px 0px 0px',
        padding: '15px 20px',
    },
    cardContent: {
        padding: '20px !important',
        background: theme.palette.common.white,
        borderRadius: '0px 0px 16px 16px',
        borderLeft: `1px solid ${theme.palette.divider}`,
        borderRight: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,

    },
    headerBox: {
        height: 'fit-content',
        borderBottom: `1px solid ${theme.palette.v2.primary.main}`
    },
    titleFontSize: {
        fontSize: '16px',
        [theme.breakpoints.down(475)]: {
            fontSize: '14px',
        }
    }

}));
type Props = {
    title: string
    children: any
    rightText?: string
    height?: number
    fromImageXray?: boolean
    fromRiskScore?: boolean
    openSpeedometer?: any
    setOpenSpeedometer?: any
    btnClick?: any
    bgColor?: string
    color?: string
    fromMedicalAlerts?: boolean
    openRiskChartPopUp?: any
    downloadPdf?:any
}

const CardComponent = (props: Props) => {
    const {
        title,
        rightText,
        height,
        fromImageXray,
        fromRiskScore,
        openSpeedometer,
        setOpenSpeedometer,
        btnClick,
        bgColor,
        color,
        fromMedicalAlerts,
        openRiskChartPopUp,
        downloadPdf
    } = props
    const classes = useStyles()
    const {palette} = theme
    const {wellnessProfileFilters: {wellnessRiskScoreDataLoader},} = useSelector((state: RootState) => state);

    return (
        <Card>
            <CardHeader
                className={`${classes.cardHeader}`}
                sx={{
                    backgroundColor: bgColor,
                    border: `1px solid ${color}`,
                }}
                title={
                    <Box className={`d-flex align-center justify-content-between`}>
                        <Box display={'flex'} alignItems={'center'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Typography className={`${classes.titleFontSize} f-w-500 fw-medium`}
                                            sx={{color: `${color} !important`}}>{title}</Typography>
                                 {/* Holding off to release  */}
                                 {fromRiskScore ?
                                    <Box display={'flex'} alignItems={'center'}>
                                        {/* Holding off to release  */}
                                        {/* TODO: comment out from Dev */}
                                        {/* <Box ml={1} className={`cursor-pointer`}
                                             sx={{
                                                 border:`1px solid ${theme.palette.v2.primary.main}`,
                                                 borderRadius: '4px',
                                                 padding: '1px 4px',
                                                 backgroundColor: `${palette.v2.primary.main}`
                                             }} onClick={openRiskChartPopUp}>
                                            <FontAwesomeIcon icon={faChartLine} color={palette.common.white}/>
                                        </Box> */}
                                        {wellnessRiskScoreDataLoader ? <Typography ml={1} className={'f-14 fw-regular'} color={palette.error.main}>Currently updating risk scores</Typography> : ''}
                                    </Box> : ''}
                            </Box>
                            {fromMedicalAlerts &&
                                <Box display={'flex'} alignItems={'center'} ml={1}> <img src={RedCross} alt={'+'}
                                                                                         width={'20px'}/></Box>}
                        </Box>
                                
                        <Box>
                            {fromRiskScore ?
                                <ChangeRiskDialsView color={color} openSpeedometer={openSpeedometer}
                                                     setOpenSpeedometer={setOpenSpeedometer}/> : null}
                            <Box display={'flex'} alignItems={'center'} ml={1}>
                            {fromMedicalAlerts &&
                                <Box > 
                                    <Button 
                                        onClick={downloadPdf}
                                        variant='text'
                                        sx={{ color: 'palette.v2.primary.main' }}>
                                        <SaveAltIcon sx={{ color: 'theme.palette.v2.primary.main' }} />
                                        <Typography 
                                            sx={{ color: 'theme.palette.v2.primary.main', fontWeight: 700}} 
                                            variant='body2'
                                            px={1} 
                                            className={`fw-regular f-w-700 f-12`}>Download PDF version</Typography>
                                    </Button>
                                </Box>}
                                <Typography onClick={btnClick} className='f-14 f-w-400 fw-regular cursor-pointer'
                                            sx={{color: `${palette.common.black50} !important`}}>{rightText}</Typography>
                            </Box>
                        </Box>
                    </Box>
                }
            />
            <CardContent
                className={`${classes.cardContent}`}
                sx={{height: `${height}px`, overflowY: fromImageXray ? 'none' : 'auto'}}
            >
                {props?.children}
            </CardContent>
        </Card>
    )
}

export default CardComponent