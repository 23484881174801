import React, {useEffect, useMemo, useRef, useState} from 'react';
import { Grid, TextField} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
    openAdditionalNotesAction,
    putAdditionalNotesRequestAction
} from '../../../../../redux/actions/dentist/clinicalExam/clinicalExamFilterAction';
import {RootState} from '../../../../../redux/reducers/rootReducer';
import theme from '../../../../../theme';
import {disabledField} from '../../../../../utils/constant/clinicalExamConstant';
import {AvoidSpace} from '../../../../../utils/helper';
import PopupComponent from "../../../../common/UIComponents/PopupComponent";

type Props = {
    open: boolean;
};

const AdditionalNotes = (props: Props) => {
    const noteRef = useRef(null) as any
    const {open} = props;
    const dispatch = useDispatch();
    const takeFromUrl = useParams();
    const {appointmentsReducer: {appointmentDetails}} = useSelector((state: RootState) => state);
    const [additionalNotes, setAdditionalNotes] = useState('') as any


    useEffect(() => {
        if (additionalNotes !== appointmentDetails?.additional_notes) {
            setAdditionalNotes(appointmentDetails?.additional_notes)
        }
        // eslint-disable-next-line
    }, [appointmentDetails?.additional_notes, open])

    const handleClose = () => {
        dispatch(openAdditionalNotesAction(false));
    };

    const handleSave = () => {
        dispatch(putAdditionalNotesRequestAction({
            appointment_id: takeFromUrl?.appointmentId,
            additional_notes: additionalNotes
        }))
    };

    const calculateRows = useMemo(() => {
        const defaultLine = 10;
        // Get the number of lines based on content
        const numLines = additionalNotes?.split('\n')?.length || 0;
        // If the content is empty, return the default number of rows
        if (additionalNotes?.trim() === "") {
            return defaultLine;
        }
        // Calculate the number of rows based on the content and the height
        let currentHeight = noteRef?.current?.clientHeight || 0;
        let calculatedHeight = (currentHeight - 30) / 20;
        // Ensure that the rows don't fall below the default value
        return Math.max(calculatedHeight, numLines, defaultLine);
    }, [additionalNotes]);

    return (
        <PopupComponent
            fromAdditionalNotes
            open={open}
            headerAction
            footerAction
            width={'fit-content'}
            heading={'Notes'}
            handleClose={handleClose}
            handleSave={handleSave}
            saveBtnText={'Save'}
            disabledbtn={disabledField(appointmentDetails)}
            cancelBtnText={'Cancel'}>
                <Grid container>
                    <Grid item={true} xs={12} className='d-flex flex-column'>
                        <TextField
                            disabled={disabledField(appointmentDetails)}
                            onKeyPress={(e: any) => {
                                AvoidSpace(e)
                            }}
                            InputProps={{
                                classes: {
                                    root: `border-radius-8 textFieldEndIconPadding textFieldStartIconPadding labelColor bg-white `,
                                    input: 'textFieldMultilineClass textField fw-regular',
                                },
                            }}
                            id='additionalNotes'
                            name='additionalNotes'
                            onChange={(e: any) => setAdditionalNotes(e?.target?.value)}
                            value={additionalNotes}
                            fullWidth={true}
                            multiline={true}
                            rows={calculateRows}
                            ref={noteRef}
                            sx={{
                                input: {
                                    backgroundColor: 'theme?.palette?.common?.white',
                                    color: `${theme.palette.common.black50} !important`,
                                },
                                "& .Mui-disabled": {
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            border: `1px solid ${theme.palette.text.secondary} !important`,
                                        },
                                    },
                                    color: `${theme.palette.text.secondary} !important`,
                                    opacity: 1,
                                },
                                '& .MuiOutlinedInput-input': {
                                    minWidth: '600px',
                                    overflowY: 'auto', // Allow vertical scrolling
                                    whiteSpace: 'pre-wrap',
                                    textOverflow: 'ellipsis',
                                    maxHeight: '700px', // Default maxHeight
                                    '@media (max-width: 740px)': {
                                        minWidth: '470px',
                                    },
                                    '@media (max-width: 600px)': {
                                        minWidth: '370px',
                                    },
                                    '@media (max-width: 510px)': {
                                        minWidth: '270px',
                                    },
                                    '@media (max-height: 950px)': {
                                        maxHeight: '550px',
                                    },
                                    '@media (max-height: 790px)': {
                                        maxHeight: '450px',
                                    },
                                    '@media (max-height: 690px)': {
                                        maxHeight: '350px',
                                    },
                                    '@media (max-height: 590px)': {
                                        maxHeight: '250px',
                                    },
                                },
                                "& .MuiOutlinedInput-root": {
                                    color: `${theme.palette.common.black50} !important`,
                                    padding: "15px 10px 15px 16px",
                                    overflowY: 'hidden !important',
                                    background: "white !important",
                                    "& fieldset": {
                                        borderColor: theme.palette.text.darkGrey,
                                    },
                                    "&:hover fieldset": {
                                        borderColor: disabledField(appointmentDetails) ? "none" : theme.palette.common.black50,
                                    },
                                    "&.Mui-focused fieldset": {
                                        border: `2px solid ${theme.palette.v2.primary.main} !important`,
                                    },
                                },
                            }}
                        />
                    </Grid>
                </Grid>
        </PopupComponent>
    );
};

export default AdditionalNotes;
