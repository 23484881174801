import React from 'react';
import {
  Alert,
  AlertProps,
  Typography,
  Box,
  Button,
  Stack,
  Collapse
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export interface AIConsentAlertProps {
  title: string;
  message: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  onClose?: () => void;
  severity?: AlertProps['severity'];
  icon?: React.ReactNode;
  isActionRequired?: boolean;
  // The open state is now fully controlled by the parent
  open: boolean;
}

/**
 * AIConsentAlert Component
 * 
 * A fully controlled alert component specifically designed for health data mapping notifications.
 * The open state is controlled by the parent component.
 * 
 * @param {AIConsentAlertProps} props - The component props
 * @returns {React.ReactElement} The rendered component
 */
export const AIConsentAlert: React.FC<AIConsentAlertProps> = ({
  title,
  message,
  primaryButtonText = "Map Data",
  secondaryButtonText = "Cancel",
  onPrimaryAction,
  onSecondaryAction,
  isActionRequired = false,
  onClose,
  open,
  severity = 'warning',
  icon = <WarningAmberIcon sx={{ color: "#E76F51" }} />,
}) => {
  const handleClose = () => {
    if (onClose) onClose();
  };

  const handlePrimaryAction = () => {
    if (onPrimaryAction) onPrimaryAction();
  };

  const handleSecondaryAction = () => {
    if (onSecondaryAction) onSecondaryAction();
  };

  return (
    <Collapse in={open} timeout={500}>
      <Alert 
        icon={icon}
        severity={severity}
        sx={{
          backgroundColor: '#FFF9F5',
          color: '#E76F51',
          borderRadius: 2,
          border: '1px solid #FADDCC',
          my: 1,
          p: 2,
          '& .MuiAlert-icon': {
            color: '#E76F51',
            alignItems: 'flex-start',
            mr: 1.5,
            pt: 1.5
          },
        }}
        onClose={isActionRequired ? undefined : handleClose}
      >
        <Box>
          <Typography 
            className='fw-regular'
            variant="h6" 
            fontWeight={500}
            fontSize={18}
            color="#5A6478"
            mb={0.5}
          >
            {title}
          </Typography>
          
          <Typography
            className='fw-regular'
            variant="body2"
            color="#E76F51"
            fontWeight={500}
            mb={2}
          >
            {message}
          </Typography>
          
          {isActionRequired && (
            <Stack direction="row" spacing={2} mt={1}>
              <Button
                className='fw-regular'
                variant="contained"
                onClick={handlePrimaryAction}
                sx={{
                  backgroundColor: '#E76F51',
                  color: 'white',
                  textTransform: 'none',
                  borderRadius: 1,
                  '&:hover': {
                    backgroundColor: '#D15941',
                  }
                }}
              >
                {primaryButtonText}
              </Button>
              
              <Button
                variant="outlined"
                onClick={handleSecondaryAction}
                sx={{
                  color: '#6B7280',
                  borderColor: '#D1D5DB',
                  textTransform: 'none',
                  borderRadius: 1,
                  '&:hover': {
                    borderColor: '#9CA3AF',
                    backgroundColor: 'transparent',
                  }
                }}
              >
                {secondaryButtonText}
              </Button>
            </Stack>
          )}
        </Box>
      </Alert>
    </Collapse>
  );
};

export default AIConsentAlert;