import React, { useState, useEffect } from 'react';
import {
  Alert,
  AlertProps,
  Typography,
  Box,
  SxProps,
  Theme,
  IconButton,
  Collapse,
  useTheme
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';

export interface AIAlertProps {
  message: string | React.ReactNode;
  icon?: React.ReactNode;
  severity?: AlertProps['severity'];
  sx?: SxProps<Theme>;
  className?: string;
  onClose?: () => void;
  isIconClose?: boolean;
}

/**
 * AIAlert Component
 * 
 * A light blue informational alert that indicates data processed by AI.
 * Includes a close button with smooth fade-in and fade-out animations.
 * 
 * @param {AIAlertProps} props - The component props
 * @returns {React.ReactElement} The rendered component
 */
export const AIAlert: React.FC<AIAlertProps> = ({
  message,
  icon = <InfoOutlinedIcon sx={(theme: any) => ({color: theme.palette.error.main})} className='fw-regular f-13' />,
  severity = 'info',
  sx = {},
  isIconClose=false,
  className = '',
  onClose,
}) => {
  const [open, setOpen] = useState(false);
  const [fade, setFade] = useState(false);
  const theme = useTheme();

  // Mount animation
  useEffect(() => {
    // Start with closed state
    setOpen(true);
    
    // Trigger fade-in after a small delay
    const timer = setTimeout(() => {
      setFade(true);
    }, 50);
    
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    // First fade out
    setFade(false);
    
    // Then collapse after fade completes
    setTimeout(() => {
      setOpen(false);
    }, 200);
    
    // Call the optional onClose callback after all animations complete
    setTimeout(() => {
      if (onClose) onClose();
    }, 500); // Allow time for both animations
  };

  return (
    <Collapse in={open} timeout={300}>
      <Alert 
        icon={icon}
        severity={severity}
        action={
          isIconClose && <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" sx={{ color: theme.palette.error.main }} />
          </IconButton>
        }
        sx={{
          marginBottom: 2,
          backgroundColor: '#EFF6FE',
          color: theme.palette.error.main,
          borderRadius: 2,
          border: '2px solid #C1DCFB',
          transition: 'opacity 0.3s ease, transform 0.3s ease',
          opacity: fade ? 1 : 0,
          transform: fade ? 'translateY(0)' : 'translateY(-10px)',
          '& .MuiAlert-icon': {
            color: 'common.black',
            alignItems: 'center',
            mr: 1,
          },
          ...sx
        }}
      >
        <Typography
          className={`fw-regular f-14 ${className}`}
          variant="body1"
          color={theme.palette.error.main}
          sx={{ 
            fontWeight: 400,
            lineHeight: 1.5 
          }}
        >
          {message}
        </Typography>
      </Alert>
    </Collapse>
  );
};

export default AIAlert;