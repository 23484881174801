export interface withoutAccountPatientWellnessProfileInterface {
    withoutAccountGetWellnessProfileLoader: boolean
    withoutAccountGetWellnessProfileData: any
    withoutAccountGetWellnessProfileError: string | null

    withoutAccountUpdateWellnessProfileLoader: boolean

    downloadPdfWithoutAccountLoader: boolean
    downloadPdfWithoutAccountData: any
    downloadPdfWithoutAccountError: string | null
}

export interface WithoutAccountGetWellnessProfileRequestPayload {
    patient_id: string | undefined;
    clinic_id?: string;
}

export interface WithoutAccountGetWellnessProfileSuccessPayload {
    wellnessProfileData?: any;
}

export interface WithoutAccountGetWellnessProfileFailurePayload {
    wellnessProfileError?: any;
}

export interface WithoutAccountGetWellnessProfileRequest {
    type: string;
    payload: WithoutAccountGetWellnessProfileRequestPayload;
}

export interface WithoutAccountGetWellnessProfileSuccess {
    type: string;
    payload: WithoutAccountGetWellnessProfileSuccessPayload;
}

export interface WithoutAccountGetWellnessProfileFailure {
    type: string;
    payload: WithoutAccountGetWellnessProfileFailurePayload;
}

export interface WithoutAccountUpdatePatientWellnessProfileRequestPayload {
    patient_id: string
    treatment_id: string;
    treatment_details_id: string;
    treatment_status?: string;
    alternative_treatment_status?: string
    callback?: any
}


export interface WithoutAccountUpdatePatientWellnessProfileRequest {
    type: string;
    payload: WithoutAccountUpdatePatientWellnessProfileRequestPayload;
}
//Download PDF Without Account
export interface DownloadPdfWithoutAccountRequestPayload {
    patientID?: any;
    clinicID?: any;
    callback?: any
}

export interface DownloadPdfWithoutAccountSuccessPayload {
    downloadPdfData: any;
}

export interface DownloadPdfWithoutAccountFailurePayload {
    downloadPdfError: string;
}

export type DownloadPdfWithoutAccountRequest = {
    type: string;
    payload: DownloadPdfWithoutAccountRequestPayload;
};
export type DownloadPdfWithoutAccountRequestSuccess = {
    type: string;
    payload: DownloadPdfWithoutAccountSuccessPayload;
};
export type DownloadPdfWithoutAccountRequestFailure = {
    type: string;
    payload: DownloadPdfWithoutAccountFailurePayload;
};

export type WithoutAccountPatientWellnessProfileActions =
    | WithoutAccountGetWellnessProfileRequest
    | WithoutAccountGetWellnessProfileSuccess
    | WithoutAccountGetWellnessProfileFailure
    | WithoutAccountUpdatePatientWellnessProfileRequest
    | DownloadPdfWithoutAccountRequest
    | DownloadPdfWithoutAccountRequestSuccess
    | DownloadPdfWithoutAccountRequestFailure

export enum PmsSyncStates {
    STARTED = 1,
    FAILED = 2,
    SUCCESS = 3,
    FE_PENDING = 4,
    FE_CONFIRMED = 5,
    FE_DENIED = 6,
}