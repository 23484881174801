import React, { useRef } from "react";
import { Divider, Grid, InputAdornment, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCommunicationPreferencesAction,
  updatePreIntakeValidationsAction,
} from "../../../../redux/actions/dentist/preIntakeActions";
import { RootState } from "../../../../redux/reducers/rootReducer";
import { textLengthStyles } from "../../../../pages/dentist/PreIntake";
import {
  convertToLowerCase,
  handleChangeWithDebounce,
  marks10,
} from "../../../../utils/helper";
import {
  describeYourselfMore,
  describeYourselfMore1,
  radioButtonOptions,
} from "../../../../utils/constant/preIntakeConstant/preIntakeConstant";
import { communicationPreferencesValidator } from "../../../../validation/preIntakeValidator";
import CollapsibleBox from "../../UIComponents/CollapsibleBox";
import InputHeading from "../../UIComponents/InputHeading";
import RadioSelect from "../../UIComponents/RadioSelect";
import RangeSlider from "../../UIComponents/RangeSlider";
import TextBox from "../../UIComponents/TextBox";

type Props = {
  disabledField?: boolean;
  info?: string
};

const CommunicationPreferences =  React.memo((props: Props) => {
  const { disabledField, info } = props;
  const lengthClasses = textLengthStyles()
  const dispatch = useDispatch();
  const noteRef = useRef(null) as any
  const { communicationPreferences, errors } = useSelector((state: RootState) => state.preIntake) as any;

  const handleChange = handleChangeWithDebounce((e: any) => {
    const { value, name, id } = e.target as any;
    let updatedCommunicationPreferences = communicationPreferences;
    updatedCommunicationPreferences[name || id] = value;
    dispatch(
      updateCommunicationPreferencesAction(updatedCommunicationPreferences)
    );

    // for the update error
    if (errors?.communicationPreferences?.[name || id]) {
      const communicationPreferencesValid = communicationPreferencesValidator(
        updatedCommunicationPreferences
      ) as any;
      dispatch(
        updatePreIntakeValidationsAction({
          ...errors,
          communicationPreferences: {
            ...errors?.communicationPreferences,
            [name || id]: errors?.communicationPreferences[name || id]
              ? communicationPreferencesValid?.errors[name || id]
              : "",
          },
        })
      );
    }
  });

  const removeOtherValue = (id: any) => {
    let updatedCommunicationPreferences = communicationPreferences;

    updatedCommunicationPreferences[id] = "";
    dispatch(
      updateCommunicationPreferencesAction(updatedCommunicationPreferences)
    );

    // for the update error
    if (errors?.communicationPreferences?.[id]) {
      const communicationPreferencesValid = communicationPreferencesValidator(
        updatedCommunicationPreferences
      ) as any;
      dispatch(
        updatePreIntakeValidationsAction({
          ...errors,
          communicationPreferences: {
            ...errors?.communicationPreferences,
            [id]: errors?.communicationPreferences[id]
              ? communicationPreferencesValid?.errors[id]
              : "",
          },
        })
      );
    }
  };

  const handleChangeVal = (val?: any, key?: any) => {
    let updatedCommunicationPreferences = communicationPreferences;
    if (key) {
      updatedCommunicationPreferences[key] = val;
      dispatch(
        updateCommunicationPreferencesAction(updatedCommunicationPreferences)
      );
    }
  };

  return (
    <CollapsibleBox
      info={info}
      title={"Communication Preferences"}
      id={"communicationPreferences"}
      scrollBlock={'end'}
    >
      <Grid container rowSpacing={1.5} columnSpacing={2.5}>
        <Grid item xs={12}>
          <Typography
            mb={"8px"}
            className=" lh-25 f-w-500 f-14 labelColor fw-medium"
          >
            We believe strongly in patient communication. Please answer the
            following questions to help us have a better understanding of your
            dental needs.This information will provide us with better
            clarification to ensure not only are your dental needs met, but to
            provide you with the best experience with us at the dental clinic.
          </Typography>
          <Divider sx={{ margin: "20px 0px 8px 0px" }} />
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={1.5} columnSpacing={2.5}>
            <Grid item lg={6} md={6} sm={11.8} xs={11.8}>
              <RangeSlider
                disabled={disabledField}
                sliderTitle={
                  "How Important Is The Appearance Of Your Teeth To You?"
                }
                leftLabel={"Not Important At All"}
                rightLabel={"Very Important"}
                sliderProps={{
                  step: 1,
                  min: 0,
                  max: 10,
                  marks: marks10,
                  size: "medium",
                  defaultValue: communicationPreferences?.appearanceOfYourTeeth,
                }}
                onChange={(e: any) =>
                  handleChangeVal(e, "appearanceOfYourTeeth")
                }
              />
            </Grid>
            <Grid mt={1} item lg={6} md={6} sm={11.8} xs={11.8}>
              <RangeSlider
                disabled={disabledField}
                sliderTitle={"How Would You Feel If You Were To Lose A Tooth?"}
                leftLabel={"Not Bothered"}
                rightLabel={"Devastated"}
                sliderProps={{
                  step: 1,
                  min: 0,
                  max: 10,
                  marks: marks10,
                  size: "medium",
                  defaultValue:
                    communicationPreferences?.feelIfYouWereToLoseTooth,
                }}
                onChange={(e: any) =>
                  handleChangeVal(e, "feelIfYouWereToLoseTooth")
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={"5px"}>
          <Grid container rowSpacing={1.5} columnSpacing={2.5}>
            <Grid mt={1} item lg={6} md={6} sm={11.8} xs={11.8}>
              <RangeSlider
                disabled={disabledField}
                sliderTitle={
                  "How Important Is It That Your Teeth Function (Chew) Well?"
                }
                leftLabel={"Not Important At All"}
                rightLabel={"Very Important"}
                sliderProps={{
                  step: 1,
                  min: 0,
                  max: 10,
                  marks: marks10,
                  size: "medium",
                  defaultValue: communicationPreferences?.yourTeethFunctionWell,
                }}
                onChange={(e: any) =>
                  handleChangeVal(e, "yourTeethFunctionWell")
                }
              />
            </Grid>
            <Grid mt={1} item lg={6} md={6} sm={11.8} xs={11.8}>
              <RangeSlider
                disabled={disabledField}
                sliderTitle={
                  "How Important Is It For You to Keep Your Teeth For The Rest Of Your Life?"
                }
                leftLabel={"Not Important At All"}
                rightLabel={"Very Important"}
                sliderProps={{
                  step: 1,
                  min: 0,
                  max: 10,
                  marks: marks10,
                  size: "medium",
                  defaultValue:
                    communicationPreferences?.keepYourTeethForRestOfYourLife,
                }}
                onChange={(e: any) =>
                  handleChangeVal(e, "keepYourTeethForRestOfYourLife")
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container px={2} py={3} rowSpacing={1.5} columnSpacing={2.5} >
          <Grid item xs={12} lg={6}>
            <InputHeading title={"Do you have any concerns with the appearance of your face?"} />
            <Grid container className="d-flex">
              <Grid item xs={12} sm={4} md={3}>
                <RadioSelect
                  disabled={disabledField}
                  options={radioButtonOptions}
                  sx={{
                    flexWrap: "nowrap !important",
                    marginRight: "14px !important",
                  }}
                  groupProps={{
                    name: "concernsWithAppearanceOfYourTeeth",
                    id: "concernsWithAppearanceOfYourTeeth",
                    defaultValue:
                      communicationPreferences?.concernsWithAppearanceOfYourTeeth,
                    onChange: (e: any) => {
                      handleChange(e);
                      removeOtherValue("concernsWithAppearanceOfYourTeethReason");
                    },
                  }}
                />
              </Grid>
              {convertToLowerCase(
                communicationPreferences?.concernsWithAppearanceOfYourTeeth
              ) === "yes" && (
                  <Grid item xs={12} sm={8} md={9}>
                    <TextBox
                      disabled={disabledField}
                      label={""}
                      labelProps={{
                        htmlFor: "concernsWithAppearanceOfYourTeethReason",
                      }}
                      maxLength={50}
                      resize={true}
                      resizeVerticalScreen={true}
                      inputProps={{
                        placeholder: "Please describe",
                        id: "concernsWithAppearanceOfYourTeethReason",
                        name: "concernsWithAppearanceOfYourTeethReason",
                        onChange: (e: any) => handleChange(e),
                        defaultValue:
                          communicationPreferences?.concernsWithAppearanceOfYourTeethReason,
                        error:
                          errors?.communicationPreferences
                            ?.concernsWithAppearanceOfYourTeethReason,
                        helperText:
                          errors?.communicationPreferences
                            ?.concernsWithAppearanceOfYourTeethReason,
                        fullWidth: true,
                        InputProps: {
                          classes: {
                            root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                            input: "textFieldStartEndIconNumbers",
                          },
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={lengthClasses.textLengthCount}
                              sx={{ alignSelf: 'flex-end', paddingBottom: '15px' }}
                            >
                              {`${communicationPreferences?.concernsWithAppearanceOfYourTeethReason?.length || 0} / 50`}
                            </InputAdornment>
                          )
                        },
                        ref: noteRef,
                        multiline: true,
                      }}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputHeading title={"Are any of your teeth sensitive or in pain?"} />
            <Grid container className="d-flex">
              <Grid item xs={12} sm={4} md={3}>
                <RadioSelect
                  disabled={disabledField}
                  sx={{
                    flexWrap: "nowrap !important",
                    marginRight: "14px !important",
                  }}
                  options={radioButtonOptions}
                  groupProps={{
                    name: "teethSensitive",
                    id: "teethSensitive",
                    defaultValue: communicationPreferences?.teethSensitive,
                    onChange: (e: any) => {
                      handleChange(e);
                      removeOtherValue("teethSensitiveReason");
                    },
                  }}
                />
              </Grid>

              {convertToLowerCase(communicationPreferences?.teethSensitive) ===
                "yes" && (
                  <Grid item xs={12} sm={8} md={9}>
                    <TextBox
                      disabled={disabledField}
                      label={""}
                      labelProps={{
                        htmlFor: "teethSensitiveReason",
                      }}
                      maxLength={50}
                      resize={true}
                      resizeVerticalScreen={true}
                      inputProps={{
                        placeholder: "Please describe",
                        id: "teethSensitiveReason",
                        name: "teethSensitiveReason",
                        onChange: (e: any) => handleChange(e),
                        defaultValue: communicationPreferences?.teethSensitiveReason,
                        error: errors?.communicationPreferences?.teethSensitiveReason,
                        helperText:
                          errors?.communicationPreferences?.teethSensitiveReason,
                        fullWidth: true,
                        InputProps: {
                          classes: {
                            root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                            input: "textFieldStartEndIconNumbers",
                          },
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={lengthClasses.textLengthCount}
                              sx={{ alignSelf: 'flex-end', paddingBottom: '15px' }}
                            >
                              {`${communicationPreferences?.teethSensitiveReason?.length || 0} / 50`}
                            </InputAdornment>
                          )
                        },
                        ref: noteRef,
                        multiline: true,
                      }}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputHeading title={"Would You Describe Yourself As More:"} />
          <RadioSelect
            disabled={disabledField}
            options={describeYourselfMore}
            groupProps={{
              name: "describeYourselfMore1",
              id: "describeYourselfMore1",
              defaultValue: communicationPreferences?.describeYourselfMore1,
              onChange: (e: any) => handleChange(e),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputHeading title={"Would You Describe Yourself As More:"} />
          <RadioSelect
            disabled={disabledField}
            options={describeYourselfMore1}
            groupProps={{
              name: "describeYourselfMore2",
              id: "describeYourselfMore2",
              defaultValue: communicationPreferences?.describeYourselfMore2,
              onChange: (e: any) => handleChange(e),
            }}
          />
        </Grid>
      </Grid>
    </CollapsibleBox>
  );
})

export default CommunicationPreferences;
