import { APPOINTMENT_ACTIONS } from "../../../actionTypes/nextgen/dentist/dashboard";


export const dentistNewAppointmentRequestSuccess = (payload: any) => ({
    type: APPOINTMENT_ACTIONS.FETCH_DENTIST_APPOINTMENTS_SUCCESS,
    payload
});
  
export const dentistNewAppointmentRequestFailure = (payload: any) => ({
    type: APPOINTMENT_ACTIONS.FETCH_DENTIST_APPOINTMENTS_FAILURE,
    payload
});

export const dentistNewAppointmentRequest = (payload: any): any => ({
    type: APPOINTMENT_ACTIONS.FETCH_DENTIST_APPOINTMENTS_REQUEST,
    payload
});